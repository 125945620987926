/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Card,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { format } from 'date-fns';
import * as yup from 'yup';

import StepButtonBar from '../../steps/StepButtonBar';
import { Task } from '../../../api/TaskApi.d';
import OrganizationSearchForm from '../orgSearch/OrganizationSearchForm';
import { OrganizationSearchResponse } from '../../../api/OrganizationApi.d';
import TaskOrgSelection from './TaskOrgSelection';
import SiraTextField from '../SiraTextField';
import SiraDateField, { dateValidation } from '../SiraDateField';
import SiraSelectField, { SiraSelectItem } from '../SiraSelectField';
import PluralizedString from '../../PluralizedString';
import {
  orgInformationStatus,
  orgSolutionOptions,
} from '../../../app.constants';
import { useGlobalContext } from '../../../auth/useGlobalContext';

import SiraTextEditor from '../SiraTextEditor';
import SiraCheckbox from '../SiraCheckbox';
import { useUser } from '../../../auth/useUser';
import { getUsers } from '../../../api/UserApi';
import RepInfoSelectField from './RepInfoSelectField';
import { errorMessages } from '../../../utils/errorhandling.utils';
import { ConstantsMappingKey } from '../../../api/SharedTextApi.d';
import WobbleCard from '../../animation-components/wobbleCard';

const today = new Date();
today.setHours(0, 0, 0, 0); // Set the time to the start of the day


export const TASK_ADD_INIT: Task = {
  title: '',
  description: '',
  financialOrganizationIds: [],
  actionLink: '',
  dueDate: '',
  category: '',
  assignedToId: '',
  emailRecipientUserIds: [],
  sendEmailWhenTaskCreated: false,
};

export const TASK_ADD_SCHEMA = yup.object({
  title: yup.string().required().max(100).label('Title'),
  description: yup.string().required().label('Description'),
  actionLink: yup.string().label('Action URL'),
  financialOrganizationIds: yup
    .array()
    .min(1)
    .of(yup.string())
    .label('Organization IDs'),
    dueDate: yup
    .date()
    .label('Due Date')
    .required('Due Date is required')
    .min(today,`Date must be after ${format(today, 'MM/dd/yyyy')}`,
  ),
  category: yup.string().required().label('Category'),
});

export interface TaskFormProps {
  initialValues: Task;
  onSubmit?: Function;
  onReset?: Function;
  submitName?: string;
  resetName?: string;
}

const TaskForm = ({
  initialValues,
  onSubmit,
  onReset,
  submitName,
  resetName,
}: TaskFormProps) => {
  const [response, setResponse] = useState({} as OrganizationSearchResponse);
  const [solutionFilter, setSolutionFilter] = useState([] as Array<any>);
  const { query } = response;

  const {
    roles = [],
    addGlobalMessage,
    getAppConstant,
    organization,
  } = useGlobalContext();
  const [draftConversion, setdraftConversion] = useState('' as string);
  const taskRoles = [{ name: 'ALL', displayName: 'All' }, ...roles];
  const { user } = useUser();
  const [finOrgCollections, setFinOrgCollections] = useState([] as any);
  const [finOrgRepObject, setFinOrgRepObject] = useState([] as Array<string>);

  const [showRepSelector, setShowRepSelector] = useState(false); // Show rep selector
  const [accountTypeSelected, setAccountTypeSelected] = useState(
    [] as Array<any>
  );
  const [selectedAccountStatus, setAccountStatus] = useState([] as Array<any>);
  const [isActive, setIsActive] = useState(false as boolean);
  const [selectedNotificationTypes, setSelectedNotificationTypes] = useState(
    [] as Array<any>
  );
  const [filteredData, setFilteredData] = useState([] as Array<any>);
  const [originalData, setOriginalData] = useState([]); // Assuming you have a way to set this

  // Fetch rep info
  const { accountTypesSupported = [] } = organization;

  const accountTypes: Array<SiraSelectItem> = accountTypesSupported.map(
    (value) => ({
      value,
      label: getAppConstant(ConstantsMappingKey.accountType, value),
    })
  );

  const notificationTypes: Array<SiraSelectItem> = [
    {
      value: 'withholdingNotificationsEnabled',
      label: 'Withholding Notifications Enabled',
    },
    { value: 'withholdingNotificationsDisabled', label: 'Withholding Notifications Disabled' },
    {
      value: 'rmdNotificationsEnabled',
      label: 'RMD Notifications Enabled',
    },
    { value: 'rmdNotificationsDisabled', label: 'RMD Notifications Disabled' },
  ];

  // delete selected chip
  const handleSolutionFilterDelete = (valueToExclude): void => {
    const removedfilter = solutionFilter.filter(
      (value) => value !== valueToExclude
    );
    setSolutionFilter(removedfilter);
  };

  const handleNotificationFilterDelete = (valueToExclude): void => {
    const removedfilter = selectedNotificationTypes.filter(
      (value) => value !== valueToExclude
    );
    setSelectedNotificationTypes(removedfilter);
  };

  const handleAccountTypeDelete = (valueToExclude): void => {
    const removedfilter = accountTypeSelected.filter(
      (value) => value !== valueToExclude
    );
    setAccountTypeSelected(removedfilter);
  };

  const handleStatusDelete = (valueToExclude): void => {
    const removedfilter = selectedAccountStatus.filter(
      (value) => value !== valueToExclude
    );
    setAccountStatus(removedfilter);
  };

  const setRepInfo = async (finOrgId): Promise<void> => {
    const orgId = finOrgId[0];
    await getUsers(orgId, user.token)
      .then((res) => {
        setShowRepSelector(true);
        const repinformation = res.data.map((item) => ({
          userId: item.auth0UserId,
          name: `${item.firstName} ${item.lastName}`,
        }));

        setFinOrgCollections(repinformation);
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Error fetching organization users'
        );
      });
  };

  // Unified filtering function
  const applyFilters = () => {
    let data = [...originalData]; // Start with a copy of the original data
    setIsActive(
      solutionFilter.length > 0 ||
        selectedAccountStatus.length > 0 ||
        accountTypeSelected.length > 0 ||
        selectedNotificationTypes.length > 0
    );
    // Apply each filter only if it has criteria selected
    if (solutionFilter.length > 0) {
      data = data.filter((item) => solutionFilter.includes(item.solution));
    }
    if (selectedAccountStatus.length > 0) {
      data = data.filter((item) => selectedAccountStatus.includes(item.status));
    }

    if (accountTypeSelected.length > 0) {
      data = data.filter((item) =>
        accountTypeSelected.some((value) =>
          item.accountTypesSupported.includes(value)
        )
      );
    }

    if (selectedNotificationTypes.length > 0) {
      data = data.filter((item) => {
        if (
          selectedNotificationTypes.includes('withholdingNotificationsDisabled')
        ) {
          return item.withholdingNotificationsEnabled === false;
        } else if (
          selectedNotificationTypes.includes('withholdingNotificationsEnabled')
        ) {
          return item.withholdingNotificationsEnabled;
        }
        if (selectedNotificationTypes.includes('rmdNotificationsDisabled')) {
          return item.rmdNotificationsEnabled === false;
        } else if (
          selectedNotificationTypes.includes('rmdNotificationsEnabled')
        ) {
          return item.rmdNotificationsEnabled;
        }
        return true;
      });
    }

    // Finally, update the filtered data state
    setFilteredData(data);
  };

  // Effect to trigger filtering whenever any filter value changes
  useEffect(() => {
    applyFilters();
  }, [
    solutionFilter,
    selectedAccountStatus,
    accountTypeSelected,
    selectedNotificationTypes,
    originalData,
  ]);

  useEffect(() => {
    const { data: results = [] } = response as OrganizationSearchResponse;

    if (results.length > 0) {
      setOriginalData(results);
      // Optionally, you can call applyFilters here directly if you want to apply filters as soon as new data is set
    }
  }, [response]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            Find and select organizations to assign this task
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <OrganizationSearchForm showViewAll setResponse={setResponse} />
        </Grid>
        <Grid item xs={12}>
          <WobbleCard isSelected={isActive}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography color="text.secondary" gutterBottom>
                  Filter Options
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="org-solution-label">
                      Solution
                  </InputLabel>
                  <Select
                  label="Solution"
                    labelId="org-solution-label"
                    variant="outlined"
                    value={solutionFilter}
                    onChange={(e) => {
                      // add to the solution filter array;
                      setSolutionFilter((prev) => [...prev, e.target.value]);
                    }}
                    renderValue={(selected: Array<any>) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => {
                          const { label = '' } =
                            orgSolutionOptions.find(
                              ({ value: valueTolookup }) =>
                                value === valueTolookup
                            ) || value;
                          return (
                            <Chip
                              key={value}
                              label={label}
                              onDelete={() => {
                                handleSolutionFilterDelete(value);
                              }}
                              onMouseDown={(event) => {
                                event.stopPropagation(); // Prevents clicking the chip from opening the select box
                              }}
                            />
                          );
                        })}
                      </Box>
                    )}
                  >
                    {[
                      ...orgSolutionOptions.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      )),
                    ]}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="account-status-label">
                      Status
                  </InputLabel>
                  <Select
                    label="Status"
                    labelId="account-status-label"
                    variant="outlined"
                    fullWidth
                    value={selectedAccountStatus}
                    onChange={(e) => {
                      setAccountStatus((prev) => [...prev, e.target.value]);
                    }}
                    renderValue={(selected: Array<any>) => (
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: 0.5,
                        }}
                      >
                        {selected.map((value) => {
                          const { label = '' } =
                            orgInformationStatus.find(
                              ({ value: valueTolookup }) =>
                                value === valueTolookup
                            ) || value;
                          return (
                            <Chip
                              key={value}
                              label={label}
                              onDelete={() => {
                                handleStatusDelete(value);
                              }}
                              onMouseDown={(event) => {
                                event.stopPropagation();
                              }}
                            />
                          );
                        })}
                      </Box>
                    )}
                  >
                    {orgInformationStatus.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel
                    id="supported-account-types-label"
                  >
                      Supported Account Types
                  </InputLabel>
                  <Select
                    label="Supported Account Types"
                    labelId="supported-account-types-label"
                    fullWidth
                    value={accountTypeSelected}
                    onChange={(e) => {
                      // add to the solution filter array;
                      setAccountTypeSelected((prev) => [
                        ...prev,
                        e.target.value,
                      ]);
                    }}
                    renderValue={(selected: Array<any>) => (
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: 0.5,
                        }}
                      >
                        {selected.map((value) => {
                          const { label = '' } =
                            accountTypes.find(
                              ({ value: valueTolookup }) =>
                                value === valueTolookup
                            ) || value;
                          return (
                            <Chip
                              key={value}
                              label={label}
                              onDelete={() => {
                                handleAccountTypeDelete(value);
                              }}
                              onMouseDown={(event) => {
                                event.stopPropagation(); // Prevents clicking the chip from opening the select box
                              }}
                            />
                          );
                        })}
                      </Box>
                    )}

                  >
                    {accountTypes.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel
                    id="notification-types-label"
                  >
                      Notification Types
                  </InputLabel>
                  <Select
                    label="Notification Types"
                    labelId="notification-types-label"
                    fullWidth
                    value={selectedNotificationTypes}
                    onChange={(e) => {
                      // add to the solution filter array;
                      setSelectedNotificationTypes((prev) => [
                        ...prev,
                        e.target.value,
                      ]);
                    }}
                    renderValue={(selected: Array<any>) => (
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: 0.5,
                        }}
                      >
                        {selected.map((value) => {
                          const { label = '' } =
                            notificationTypes.find(
                              ({ value: valueTolookup }) =>
                                value === valueTolookup
                            ) || value;
                          return (
                            <Chip
                              key={value}
                              label={label}
                              onDelete={() => {
                                handleNotificationFilterDelete(value);
                              }}
                              onMouseDown={(event) => {
                                event.stopPropagation(); // Prevents clicking the chip from opening the select box
                              }}
                            />
                          );
                        })}
                      </Box>
                    )}

                  >
                    {notificationTypes.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </WobbleCard>
        </Grid>

        <Grid item xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={TASK_ADD_SCHEMA}
            onSubmit={async (values) => {
              await onSubmit(values, draftConversion);
            }}
            onReset={() => {
              if (onReset) onReset();
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => {
              // using this to set the rep info selection
              useEffect(() => {
                const { financialOrganizationIds } = values;
                if (financialOrganizationIds.length === 1) {
                  setRepInfo(financialOrganizationIds);
                } else {
                  setShowRepSelector(false);
                }
              }, [values.financialOrganizationIds]);

              // mapping the rep selection to filed for the list of reps
              useEffect(() => {
                setFieldValue('emailRecipientUserIds', finOrgRepObject);
              }, [finOrgRepObject]);
              return (
                <Form>
                  <Grid container spacing={2}>
                    {Boolean(query) && (
                      <Grid item xs={12}>
                        <TaskOrgSelection
                          data={filteredData}
                          response={response}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    {showRepSelector ? (
                      <Grid item xs={12}>
                        <RepInfoSelectField
                          onChange={setFinOrgRepObject}
                          reps={finOrgRepObject}
                          listOfReps={finOrgCollections}
                        />
                      </Grid>
                    ) : null}
                    <Grid item xs={12} sm={4}>
                      <Field
                        name="sendEmailWhenTaskCreated"
                        label="Send Email Notification"
                        type="checkbox"
                        component={SiraCheckbox}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SiraTextField name="title" label="Subject" />
                    </Grid>
                    <Grid item xs={12}>
                      <SiraTextEditor
                        showToolbar
                        setValue={(value: '') => {
                          setFieldValue('description', value);
                        }}
                        formatToHtml={(value: '') => {
                          setdraftConversion(value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SiraDateField name="dueDate" label="Due Date" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SiraSelectField
                        items={taskRoles.map(
                          ({ name: value, displayName }) => ({
                            value,
                            label: displayName,
                          })
                        )}
                        name="category"
                        label="Category"
                      />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <SiraTextField
                        name="actionLink"
                        label="URL to Complete Task"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={2}>
                      <StepButtonBar
                        isSubmitting={isSubmitting}
                        submitName={submitName}
                        resetName={resetName}
                      />
                    </Grid>
                    {!values.financialOrganizationIds.length ? (
                      <Grid item xs={12} sm={8} m={2}>
                        <Alert severity="info">
                          Select at least one organization above to assign this
                          task
                        </Alert>
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={8} m={2}>
                        <Alert severity="success">
                          {values.financialOrganizationIds.length}{' '}
                          <PluralizedString
                            noun="organization"
                            quantity={values.financialOrganizationIds.length}
                          />{' '}
                          selected
                        </Alert>
                      </Grid>
                    )}
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};

export default TaskForm;

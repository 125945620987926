import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Typography,
  Box,
  Grid,
} from '@mui/material';
import InsightsIcon from '@mui/icons-material/Insights';

import Layout from '../components/Layout';
import PaperlessPage from '../components/PaperlessPage';
import PendingOrgTransactions from '../components/dashboard/PendingOrgTransactions';
import TaskList from '../components/dashboard/TaskList';
import ActionCard from '../components/ActionCard';
import {  UserRole } from '../api/UserApi.d';
import {  useUser } from '../auth/useUser';

import CustomerPortal from './CustomerPortalVerifyUser';
import { useGlobalContext } from '../auth/useGlobalContext';
import SessionTimeoutModal from '../utils/SessionTimeoutModal';

function Dashboard() {
  const { user: userName } = useAuth0();
  const { customerPortalUser } = useGlobalContext();
  const firstName = userName.name.split(' ')[0];
  const { user } = useUser();
  const [isValidUserRole, setisValidUserRole] = React.useState(false);
  const [isAccountOwner, setIsAccountOwner] = useState(customerPortalUser as boolean);

  useEffect(() => {
    if (user && user.roles) {
      setisValidUserRole(
        user.roles.includes(UserRole.orgTransactions) ||
          user.roles.includes(UserRole.orgTransactionsAdmin)
      );
      setIsAccountOwner(user.roles.includes(UserRole.accountOwner) as boolean);
    }
  }, [user.roles]);

  return (
    isAccountOwner ? (
      <CustomerPortal/>
    ) : (
    <Layout>
      <PaperlessPage>
        <>
          <Box mb={5}>
            <Typography variant="overline" gutterBottom>
              Dashboard
            </Typography>
            <Typography variant="h1" color="secondary" gutterBottom>
              Welcome Back
              {firstName ? `, ${firstName}` : ''}!
            </Typography>
          </Box>
          {/* Dashboard Modules */}
          <Grid container spacing={2}>
            <TaskList />

            {isValidUserRole && <PendingOrgTransactions />}

            {/* Action Cards */}
            <Grid item xs={12}>
              <Grid container spacing={3} wrap="wrap">
                {/* Reports and Stats */}
                <ActionCard
                  dataQa="reports-statistics"
                  iconImage={
                    <InsightsIcon color="secondary" style={{ fontSize: 42 }} />
                  }
                  buttonText="Reports and Statistics"
                  route="/reports"
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      </PaperlessPage>
    </Layout>
    )
  );
}

export default Dashboard;

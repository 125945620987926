import React, { useEffect, useState } from 'react';
import { useTheme, Grid, Box, Typography } from '@mui/material';
import { Formik, Form, FormikHelpers, useFormikContext } from 'formik';
import * as yup from 'yup';
import {
  endOfDay,
  parseISO,
  startOfDay,
  startOfYear,
  subYears,
} from 'date-fns';

import moment from 'moment';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import DistributionSummary from './DistributionSummary';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';
import {
  DistributionAmountFormData,
  FilingStatus,
} from './DistributionAmountForm.d';
import StateField from '../StateField';
import StateWithholdingFields from './StateWithholdingFields';
import FederalWithholdingPercentField from './FederalWithholdingPercentField';
import SiraDateField, { distributionDateValidation } from '../SiraDateField';
import StepButtonBar from '../../steps/StepButtonBar';
import DistributionAmountSubForm from './DistributionAmountSubForm';
import DistributionAmountRecurringSubForm from './DistributionAmountRecurringSubForm';
import { DistributionTerm } from './DistributionTermForm';
import {
  DistributionReason,
  NiaOption,
  FederalTaxElectionType,
  StateWithholdingPercentType,
} from '../../../api/DistributionApi.d';
import { Account, AccountType } from '../../../api/AccountApi.d';
import FederalTaxCalculationOption from './FederalTaxCalculationOption';
import {
  RecurringDistribution,
  TermChoiceOption,
} from '../../../api/RecurringDistributionApi.d';
import TransactionSubStepper, {
  TransactionSubStep,
} from '../../../page/TransactionSubStepper';
import SiraSwitchField from '../SiraSwitchField';
import { isRecharOrExcessOrRevoc } from './distribution.utils';
import { determineAgeGroup } from '../../../app.constants';
import SiraCurrencyField from '../SiraCurrencyField';
import { useUser } from '../../../auth/useUser';
import { getDistributionStateWithholding } from '../../../api/DistributionApi';
import InfoPopover from '../../InfoPopover';

export const DISTRIBUTION_AMOUNT_INIT: DistributionAmountFormData = {
  netAmount: '',
  totalAmount: '',
  netAmountEntered: false,
  niaExcessContribution: '',
  niaPreContributionAccountBalance: '',
  niaCurrentAccountBalance: '',
  niaTotalContributionsDuringComputationPeriod: '',
  niaTotalDistributionsDuringComputationPeriod: '',
  closingAccount: false,
  niaOptions: NiaOption.calculate,
  penaltyAmount: '',
  effectiveDate: moment().format('YYYY-MM-DD'),
  filingStatus: FilingStatus.default,
  totalIncome: '',
  federalTaxElectionType: FederalTaxElectionType.current,
  federalWithholdingPercent: '0',
  withholdingState: '',
  stateWithholdingPercent: '',
  additionalStateWithholding: '',
  recurringSelection: '',
  minimumDistribution: { isRequired: false, minimum: 0 },
  canSpecifyPercent: StateWithholdingPercentType.disabled,
  suggestedStateWithholding: '',
  doNotWithholdFederal: false,
  useSuggestedWithholding: false,
  netIncomeAttributable: '',
  allowNoneState: false,
  fairMarketValue: '',
};

function getDistributionAmountSchema(
  accountClosedDate: string,
  accountOpenDate: string,
  dateofBirth,
  showStateWithholding,
  showWithholding,
  recurringDistribution,
  displayFeeAmount,
) {
  const currentYear = new Date().getFullYear();
  const minEffectiveDate =
    accountOpenDate && !recurringDistribution
      ? startOfDay(parseISO(accountOpenDate))
      : subYears(startOfYear(new Date()), 1); // Within the last year
  const maxEffectiveDate = accountClosedDate // if account closed, before or on account close. Else use open date for validation.
    ? endOfDay(parseISO(accountClosedDate))
    : new Date(currentYear, 12, 1);

  const { over73, underHalf59, currently73Years, turning73ThisYear } =
    determineAgeGroup(dateofBirth);

  return yup.object({
    effectiveDate: distributionDateValidation(
      minEffectiveDate,
      maxEffectiveDate,
      accountClosedDate,
    ).label('Distribution Date'),
    federalTaxElectionType: yup.string().required().label('Election Type'),
    federalWithholdingPercent: yup
      .number()
      .typeError('Please enter a number')
      .required()
      .max(100)
      .label('Federal Tax')
      .when('doNotWithholdFederal', (doNotWithholdFederal, schema) => {
        return !doNotWithholdFederal ? schema.min(0) : schema.min(0);
      }),
    withholdingState:
      showWithholding && showStateWithholding
        ? yup
            .string()
            .required(`Select a withholding state or choose 'Do Not Withhold'`)
            .label('Witholding State')
        : yup.string().label('Witholding State'),
    stateWithholdingPercent:
      showWithholding && showStateWithholding
        ? yup
            .number()
            .required()
            .min(0)
            .max(100)
            .when(
              [
                'suggestedStateWithholding',
                'federalWithholdingPercent',
                'canSpecifyPercent',
              ],
              (...args) => {
                // Hacky way around type checking when validating against n fields
                const [
                  suggestedStateWithholding,
                  federalWithholdingPercent,
                  canSpecifyPercent,
                  schema,
                ] = args;

                // Some states: "When fed > 0, state >= recommended state"
                if (
                  canSpecifyPercent ===
                  StateWithholdingPercentType.greaterOrEqual
                ) {
                  return schema
                    .min(suggestedStateWithholding)
                    .max(100 - federalWithholdingPercent)
                    .label('State Tax');
                }

                // Some states: "When fed > 0, state <= recommended state but > 0"
                if (
                  canSpecifyPercent === StateWithholdingPercentType.lessOrEqual
                ) {
                  const max = Math.min(
                    suggestedStateWithholding,
                    100 - federalWithholdingPercent,
                  );

                  // return a yup custom error message
                  return schema.max(
                    max,
                    `State Tax must be less than or equal to ${max}.`,
                  );
                }

                // Some states: "When fed > 0, state <= recommended state but > 0"
                if (
                  canSpecifyPercent === StateWithholdingPercentType.zeroOrEqaul
                ) {
                  const max = suggestedStateWithholding;

                  return schema.oneOf(
                    [0, max],
                    `State Tax must be zero or ${max}.`,
                  );
                }

                // Don't allow combined withholding > 100%
                return federalWithholdingPercent
                  ? schema
                      .max(100 - federalWithholdingPercent)
                      .label('State Tax')
                  : schema;
              },
            )
        : yup.string().label('State Tax'),
    additionalStateWithholding: yup.string().label('Additional State Amount'),
  });
}

export interface DistributionAmountFormProps {
  account: Account;
  accountOwner: AccountOwner;
  initialValues: DistributionAmountFormData;
  parentDistribution?: RecurringDistribution;
  onSubmit?: Function;
  onReset?: Function;
  onCancel?: Function;
  resetName?: string;
  submitName?: string;
  recurringDistribution?: boolean;
  term?: DistributionTerm;
  saveCalculatedInfo?: Function;
  showWithholding?: boolean;
  showStateWithholding?: boolean;
  explodeSteps?: boolean;
  isEditing?: boolean;
}

function DistributionAmountStep2Content(props) {
  const { account, isEditing, initialPercent } = props;

  return (
    <Box mt={5} mb={3} maxWidth={400}>
      <Grid container spacing={3}>
        {!isEditing ? <FederalTaxCalculationOption account={account} /> : null}{' '}
        <Grid item xs={12} md={7}>
          <FederalWithholdingPercentField
            isEditing={isEditing}
            initialPercent={initialPercent}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

function DistributionAmountStep3Content(props) {
  const { accountOwner } = props;
  const { user } = useUser();
  const { organization } = useGlobalContext();
  const { stateTaxIDS } = organization;
  const { values, setFieldValue } = useFormikContext();
  const { allowNoneState, withholdingState, stateWithholdingPercent } =
    values as DistributionAmountFormData;
  const [withholdingLanguage, setWithholdingLanguage] = useState('' as string);
  // Check for which states have withholding enabled based on state tax ID settings
  const validStates = stateTaxIDS
    .filter(({ stateWithholding }) => stateWithholding)
    .map(({ state }) => state);
  const [withholdingValues, setWithholdingValues] = useState(false);

  async function onWithholdingStateChange() {
    await getDistributionStateWithholding(
      values as DistributionAmountFormData,
      user.token,
      user,
    )
      .then((res) => {
        const {
          percent = 0,
          canSpecifyPercent = StateWithholdingPercentType.disabled,
          canSpecifyAmount = false,
        } = res.data;

        setWithholdingLanguage(res.data.withholdingLanguage);

        if (
          canSpecifyAmount &&
          canSpecifyPercent === StateWithholdingPercentType.disabled &&
          percent === 0
        ) {
          setWithholdingValues(true);
        } else {
          setWithholdingValues(false);
        }

        if (
          validStates.includes(withholdingState) &&
          (stateWithholdingPercent === '' || stateWithholdingPercent === '0')
        ) {
          setFieldValue('stateWithholdingPercent', percent);
        }
      })
      .catch(() => {
        setFieldValue('stateWithholdingPercent', '0');
      });
  }

  const checkstateWithholding = (state) => {
    return state !== 'XX' ? state : '';
  };

  useEffect(() => {
    // Check for user-entered value on mount and don't overwrite it

    if (allowNoneState) {
      const stateValue = validStates.includes(accountOwner.state)
        ? accountOwner.state
        : 'XX';
      setFieldValue('withholdingState', stateValue);
      setFieldValue('stateWithholdingPercent', '0');
    } else {
      setFieldValue(
        'withholdingState',
        (withholdingState === accountOwner.state &&
          validStates.includes(withholdingState)) ||
          (withholdingState === 'XX' && validStates.includes(withholdingState))
          ? accountOwner.state
          : checkstateWithholding(withholdingState),
      );

      if (validStates.includes(withholdingState)) {
        onWithholdingStateChange();
      } else {
        setWithholdingLanguage('');
      }
    }
  }, [allowNoneState]);

  useEffect(() => {
    if (validStates.includes(withholdingState) && !allowNoneState) {
      onWithholdingStateChange();
    } else {
      setWithholdingLanguage('');
    }
  }, [withholdingState]);

  return (
    <Box mt={5} mb={3} maxWidth={400}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="div">
            <Grid
              component="label"
              container
              alignItems="center"
              spacing={1}
              wrap="nowrap"
            >
              <Grid item>
                <SiraSwitchField
                  name="allowNoneState"
                  checked={allowNoneState}
                />
              </Grid>
              <Grid item>Do Not Withhold</Grid>
            </Grid>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <StateField
            name="withholdingState"
            label="State"
            // Filter out the state field options based on which ones support withholding
            filterFunction={({ value }) => validStates.includes(value)}
            allowNoneState={allowNoneState}
            disabled={allowNoneState}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            Enter the {withholdingValues ? 'amount' : 'percentage'} you will
            withhold for state taxes.
            {withholdingLanguage && <InfoPopover text={withholdingLanguage} />}
          </Typography>
        </Grid>
        <StateWithholdingFields />
      </Grid>
    </Box>
  );
}

function DistributionWithholdingEditForm({
  account,
  accountOwner = {},
  initialValues = DISTRIBUTION_AMOUNT_INIT,
  onSubmit = () => {},
  onReset = () => {},
  onCancel,
  resetName,
  submitName,
  recurringDistribution = false,
  parentDistribution = {},
  term = {},
  saveCalculatedInfo,
  showWithholding = false,
  showStateWithholding = false,
  explodeSteps = false,
  isEditing = false,
}: DistributionAmountFormProps) {
  const [activeStep, setActiveStep] = useState(0);
  const { closedDate: accountClosedDate, openDate: accountOpenDate } = account;
  const { organization } = useGlobalContext();
  const { stateTaxIDS, displayFeeAmount } = organization;

  const theme = useTheme();
  const classes = {
    root: {
      overflowX: 'auto' as any,
    },
    formContainer: {
      [theme.breakpoints.down('md')]: {
        flexShrink: 0,
      },
      [theme.breakpoints.up('sm')]: {
        flexShrink: 1,
      },
    },
  };
  const { dateOfBirth } = accountOwner as AccountOwner;

  const validStates = stateTaxIDS
    .filter(({ stateWithholding }) => stateWithholding)
    .map(({ state }) => state);

  const DISTRIBUTION_SCHEMA = getDistributionAmountSchema(
    accountClosedDate,
    accountOpenDate,
    dateOfBirth,
    showStateWithholding,
    showWithholding,
    recurringDistribution,
    displayFeeAmount,
  );

  async function validateAmountForm(
    values: DistributionAmountFormData,
    actions: FormikHelpers<DistributionAmountFormData>,
  ): Promise<void> {
    const errors = await actions.validateForm();

    if (Object.keys(errors).length === 0) {
      const result = await onSubmit(values);
      if (result) {
        actions.setErrors(result.errors);
      }
    }
  }

  const distributionAmountSubSteps: Array<TransactionSubStep> = [];

  if (showWithholding) {
    distributionAmountSubSteps.push({
      label: 'Federal Withholding',
      stepContent: (
        <DistributionAmountStep2Content
          account={account}
          isEditing={isEditing}
          initialPercent={{
            federalWithholdingPercent: initialValues.federalWithholdingPercent,
          }}
        />
      ),
      validationFields: [
        'filingStatus',
        'totalIncome',
        'federalWithholdingPercent',
      ],
    });
  }

  if (showWithholding && showStateWithholding) {
    distributionAmountSubSteps.push({
      label: 'State Withholding',
      stepContent: (
        <DistributionAmountStep3Content accountOwner={accountOwner} />
      ),
      validationFields: [
        'withholdingState',
        'stateWithholdingPercent',
        'additionalStateWithholding',
      ],
    });
  }

  if (distributionAmountSubSteps.length > 0) {
    distributionAmountSubSteps.push({
      label: 'Add Effective Date',
      stepContent: (
        <Box width="1" mt={4} mb={4}>
          <SiraDateField name="effectiveDate" label="Effective Date" />
        </Box>
      ),
      validationFields: [],
    });
  }

  const getSupportedWithholdingState = (stateToMatch) => {
    const validWithholdingState =
      showWithholding && showStateWithholding
        ? validStates.find((state) => stateToMatch === state)
        : 'XX';

    return validWithholdingState || 'XX';
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        withholdingState: getSupportedWithholdingState(
          initialValues.withholdingState || accountOwner.state,
        ),
        stateWithholdingPercent:
          showWithholding && showStateWithholding
            ? initialValues.stateWithholdingPercent
            : '0',
        allowNoneState: !validStates.includes(
          initialValues.withholdingState || accountOwner.state,
        ),
      }}
      onSubmit={async (values, actions) => {
        await validateAmountForm(values, actions);
      }}
      validateOnMount
      onReset={() => onReset()}
      validationSchema={DISTRIBUTION_SCHEMA}
      enableReinitialize
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <Grid container wrap="nowrap" sx={classes.root}>
              <Grid item xs={12} sx={classes.formContainer}>
                <TransactionSubStepper
                  steps={distributionAmountSubSteps}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  explodeSteps={explodeSteps}
                />
                <StepButtonBar
                  isSubmitting={isSubmitting}
                  submitName={submitName}
                  resetName={resetName}
                  onCancel={onCancel}
                />
              </Grid>
              <Grid item>
                <DistributionSummary
                  saveCalculatedInfo={saveCalculatedInfo}
                  showWithholding={showWithholding}
                  showStateWithholding={showStateWithholding}
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}

export default DistributionWithholdingEditForm;

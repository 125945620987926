import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { getYear, subYears } from 'date-fns';
import {
  Box,
  Typography,
  Button,
  LinearProgress,
  Grid,
  TextField,
} from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import CheckIcon from '@mui/icons-material/CheckBox';

import { globalPaginationOptions } from '../../../app.constants';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../../auth/useGlobalContext';
import { useUser } from '../../../auth/useUser';
import SiraNoRowsOverlay from '../../SiraNoRowsOverlay';
import DataGridExportToolbar from '../../DataGridExportToolbar';

import Layout from '../../Layout';
import FormPaper from '../../FormPaper';
import { TaxForm, TaxFormType } from '../../../api/TaxFormApi.d';
import TaxReportingSelectField from './TaxReportingSelectField';
import { getTaxForm } from '../../../api/TaxFormApi';
import ObscuredTaxId, { TaxIdFormat } from '../../ObscuredTaxId';
import { AccountDetailAccordionType } from '../../accountOwnerProfile/AccountOwnerAccountDetails';
import { ConstantsMappingKey } from '../../../api/SharedTextApi.d';
import {
  currencyValueFormatter,
  dateValueFormatter,
} from '../../../utils/DataGrid.utils';
import { AccountMemberValue } from '../../../api/OrganizationApi.d';
import { errorMessages } from '../../../utils/errorhandling.utils';

function TaxReport() {
  let isMounted = true;
  const { user } = useUser();
  const navigate = useNavigate();

  const { getAppConstant, addGlobalMessage , organization } = useGlobalContext();
  const { accountNumberValue } = organization;
  const {  setGlobalPageSize } = usePaginationContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [taxFormTypeFilter, setTaxFormTypeFilter] = useState<
    Array<TaxFormType | string>
  >([]);
  const [filteredRows = [], setFilteredRows] = useState<Array<TaxForm>>([]);

  const [rows, setRows] = useState([] as Array<TaxForm>);
  const [disableRColumns, setDisableRColumns] = useState<boolean>(false);
  const [disableQColumns, setDisableQColumns] = useState<boolean>(false);

  const gotoRecordAccount = (row: TaxForm): void => {
    navigate(
      `/accountOwner/${row.accountOwnerId}/account/${row.accountId}/?expanded=${AccountDetailAccordionType.taxForm}`
    );
  };

  const headerAccountName = accountNumberValue === AccountMemberValue.accountNumber ? 'Account Number': 'Member Number';

  const columns = [
    {
      field: 'legalName',
      headerName: 'Owner',
      pinnable: true,
      width: 200,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            onClick={() => {
              gotoRecordAccount(row);
            }}
            sx={{
              justifyContent: 'flex-start' as any,
              width: '100%',
              textDecoration: 'none' as any,
              textTransform: 'none' as any,
            }}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'taxId',
      headerName: 'SSN/ID',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        return getAppConstant(ConstantsMappingKey.accountType, value);
      },
    },
    {
      field: 'accountNumber',
      headerName: headerAccountName,
      width: 156,
    },
    {
      field: 'uniqueId',
      headerName: 'Unique ID',
      width: 156,
    },  
    {
      field: 'distributionReason',
      headerName: 'Distribution Reason',
      width: 156,
      valueFormatter: (params: GridCellParams) => {
        const { value = '' } = params;
        return getAppConstant(ConstantsMappingKey.distributionReason, value);
      },
    },
    {
      field: 'distributionAmount',
      headerName: 'Distribution Amount',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      disableExport: disableRColumns,
      field: 'federalTaxWithheld',
      headerName: 'Federal Tax Withheld',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      disableExport: disableRColumns,
      field: 'stateIncomeTaxWithheld',
      headerName: 'State Tax Withheld',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      disableExport: disableRColumns,
      field: 'withholdingState',
      headerName: 'Withholding State',
      width: 156,
    },
    {
      field: 'taxableAmount',
      headerName: 'Taxable Amount',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'taxableAmountNotDetermined',
      headerName: 'Taxable Amount Not Determined',
      width: 156,
      valueFormatter: (params: GridCellParams) => {
        const { value = false } = params;
        if (value) {
          return 'X';
        }
        return ' ';
      },
      renderCell: (params: GridCellParams) => {
        const { value = false } = params;
        return (
          <>
            <Grid container justifyContent="center">
              {value ? <CheckIcon /> : ' - '}
            </Grid>
          </>
        );
      },
    },
    {
      field: 'totalDistributionIndicator',
      headerName: 'Total Distribution Indicator',
      width: 156,
      valueFormatter: (params: GridCellParams) => {
        const { value = false } = params;
        if (value) {
          return 'X';
        }
        return ' ';
      },
      renderCell: (params: GridCellParams) => {
        const { value = false } = params;
        return (
          <>
            <Grid container justifyContent="center">
              {value ? <CheckIcon /> : ' - '}
            </Grid>
          </>
        );
      },
    },
    {
      disableExport: disableQColumns,
      field: 'trusteeToTrusteeTransfer',
      headerName: 'Trustee to Trustee Transfer',
      width: 156,
      valueFormatter: (params: GridCellParams) => {
        const { value = false } = params;
        if (value) {
          return 'X';
        }
        return ' ';
      },
      renderCell: (params: GridCellParams) => {
        const { value = false } = params;
        return (
          <>
            <Grid container justifyContent="center">
              {value ? <CheckIcon /> : ' - '}
            </Grid>
          </>
        );
      },
    },
    {
      disableExport: disableQColumns,
      field: 'designatedBeneficiary',
      headerName: 'Recipient is not the Designated Beneficiary',
      width: 156,
      valueFormatter: (params: GridCellParams) => {
        const { value = false } = params;
        if (value) {
          return 'X';
        }
        return ' ';
      },
      renderCell: (params: GridCellParams) => {
        const { value = false } = params;
        return (
          <>
            <Grid container justifyContent="center">
              {value ? <CheckIcon /> : ' - '}
            </Grid>
          </>
        );
      },
    },
    {
      field: 'corrected',
      headerName: 'Corrected Indicator',
      width: 124,
      valueFormatter: (params: GridCellParams) => {
        const { value = false } = params;
        if (value) {
          return 'X';
        }
        return ' ';
      },
      renderCell: (params: GridCellParams) => {
        const { value = false } = params;
        return (
          <>
            <Grid container justifyContent="center">
              {value ? <CheckIcon /> : ' - '}
            </Grid>
          </>
        );
      },
    },
    {
      field: 'dateSentToOwner',
      headerName: 'Date Sent to Owner',
      width: 172,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'dateFiledWithIrs',
      headerName: 'Date Filed with IRS',
      width: 172,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'dateStateTaxReported',
      headerName: 'Date Filed with State',
      width: 172,
      valueFormatter: dateValueFormatter,
    },    {
      field: 'state',
      headerName: 'State',
      width: 172,
    },
  ] as GridColDef[];

  // Apply all filters to the supplied rows
  const applyFilters = (rowsToFilter) => {
    const matchingRows =
      taxFormTypeFilter.length > 0
        ? rowsToFilter.filter(({ formType }) =>
            taxFormTypeFilter.includes(formType)
          )
        : rowsToFilter;

    setFilteredRows(matchingRows);
  };

  const fetchSndSetTaxForm = async (values) => {
    setIsLoading(true);
    await getTaxForm(
      String(getYear(values.taxYear)),
      user.organizationId,
      user.token,
      user
    )
      .then(({ data }) => {
        setRows(data);
        applyFilters(data);
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) || 'Error fetching multi-org beneficiary claims'
        );
      });

    if (isMounted) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    applyFilters(rows);
    if (taxFormTypeFilter.length > 0) {
      setDisableRColumns(!taxFormTypeFilter.includes('R'));
      setDisableQColumns(!taxFormTypeFilter.includes('Q'));
    }else{
      setDisableRColumns(false);
      setDisableQColumns(false);
    }

    return () => {
      isMounted = false;
    };
  }, [taxFormTypeFilter]);

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Tax Reporting
          </Typography>

          <Typography color="secondary" variant="h1" gutterBottom>
            1099 Tax Report
          </Typography>

          <Formik
            // Datepicker is only going to accept date in taxYear but we translate it to an integer on submit
            initialValues={{ taxYear: subYears(new Date(), 1), hasFmv: true }}
            onSubmit={fetchSndSetTaxForm}
            validationSchema={yup.object({
              taxYear: yup
                .date()
                .min(new Date('1900-01-01'), 'Choose a later year')
                .max(new Date(), 'Cannot be later than current year')
                .nullable()
                .required()
                .label('Tax Year'),
              hasFmv: yup.boolean(),
            })}
          >
            {({ isSubmitting, values, setFieldValue, errors }) => {
              return (
                <Form>
                  <Grid container spacing={2} alignItems="center" pt={1} pb={2}>
                    <Grid item xs={12} sm={7}>
                      {/* Year-only date viewer */}
                      <DatePicker
                        views={['year']}
                        label="Tax Year"
                        value={values.taxYear}
                        openTo='year'
                        onChange={(newValue) => {
                          setFieldValue('taxYear', newValue);
                        }}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: Boolean(errors.taxYear),
                            helperText: errors.taxYear ? String(errors.taxYear) : '',
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <Button
                        disabled={isSubmitting}
                        variant="contained"
                        color="secondary"
                        startIcon={<SearchOutlinedIcon />}
                        type="submit"
                      >
                        Search
                      </Button>
                    </Grid>

                    <Grid item xs={12} sm={7}>
                      <TaxReportingSelectField
                        onChange={setTaxFormTypeFilter}
                        taxFormTypes={taxFormTypeFilter}
                      />
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>

          {isLoading ? (
            <Box width="1">
              <LinearProgress />
            </Box>
          ) : (
            <DataGrid
              components={{
                NoRowsOverlay: SiraNoRowsOverlay,
                Toolbar: filteredRows.length > 0 &&  DataGridExportToolbar,
              }}
              disableColumnMenu
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
                columns: {
                  columnVisibilityModel: {
                    accountType: false,
                    uniqueId: false,
                    accountNumber: false,
                    distributionReason: false,
                    taxableAmount: false,
                    taxableAmountNotDetermined: false,
                    totalDistributionIndicator: false,
                    trusteeToTrusteeTransfer: false,
                    designatedBeneficiary: false,
                    withholdingState: false,
                    stateIncomeTaxWithheld: false,
                    federalTaxWithheld: false,
                    distributionAmount: false,
                  },
                }
              }}
              pageSizeOptions={globalPaginationOptions}
              onPaginationModelChange={setGlobalPageSize}
              autoHeight
              columns={columns}
              rows={filteredRows.map((row, id) => ({
                ...row,
                id,
              }))}
            />
          )}
        </>
      </FormPaper>
    </Layout>
  );
}

export default TaxReport;

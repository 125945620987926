import React, { useEffect, useState } from 'react';
import { parseISO, getYear } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography, Button, LinearProgress } from '@mui/material';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
import { makeStyles } from '@mui/styles';
import Layout from '../Layout';
import FormPaper from '../FormPaper';

import {
  globalPaginationOptions,
  withholdingNotificationTypeLabels,
} from '../../app.constants';
import { useUser } from '../../auth/useUser';
import { useGlobalContext } from '../../auth/useGlobalContext';
import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import {
  WithholdingNotification,
  WithholdingNotificationResponse,
  WithholdingNotificationStatus,
  WithholdingNotificationType,
} from '../../api/WithholdingNotificationApi.d';
import WithholdingNotificationIcon from '../../icons/WithholdingNotification';
import { getOrgWithholdingNotifications } from '../../api/WithholdingNotificationApi';
import { Account, AccountType } from '../../api/AccountApi.d';
import { AccountOwner } from '../../api/AccountOwnerApi.d';
import ObscuredTaxId, { TaxIdFormat } from '../ObscuredTaxId';
import { ConstantsMappingKey } from '../../api/SharedTextApi.d';
import { AccountDetailAccordionType } from '../accountOwnerProfile/AccountOwnerAccountDetails';
import TaxYearsFilterForm from '../form/TaxYearsFilterForm';
import MultiButton, { MultiButtonAction } from '../MultiButton';
import { dateValueFormatterPro } from '../../utils/DataGrid.utils';
import { AccountMemberValue } from '../../api/OrganizationApi.d';
import { errorMessages } from '../../utils/errorhandling.utils';
import ModalRecurringDistribution from '../../page/ModalRecurringDistribution';
import SiraTaxFormCorrectionModal from '../form/SiraTaxFormCorrectionModal';

interface WithholdingNotificationFilterProps {
  onItemClick: Function;
  typeFilter: Array<WithholdingNotificationType>;
}

interface WithholdingNotificationRow extends WithholdingNotification {
  id: number;
  fullName: string;
  taxpayerIdNumber: string;
  account: Account;
  accountType: AccountType;
  accountNumber: string;
  accountOwner: AccountOwner;
  year: string;
}

// Flatten and map out the withholding notifications for display in the data grid
function WithholdingNotificationRowsFactory(
  notifications: Array<WithholdingNotification>,
): Array<any> {
  return notifications.map((result, id) => ({
    ...result,
    id,
    year: String(
      getYear(parseISO(result.withholdingNotificationRecordCreated)),
    ),
  }));
}

function WithholdingNotificationFilter(
  props: WithholdingNotificationFilterProps,
) {
  const { onItemClick = () => {}, typeFilter } = props;

  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="body2">Show Type:</Typography>
        </Grid>
        <Grid item>
          <Button
            variant={
              typeFilter.includes(WithholdingNotificationType.december)
                ? 'contained'
                : 'outlined'
            }
            color="primary"
            onClick={() => {
              onItemClick([WithholdingNotificationType.december]);
            }}
          >
            {WithholdingNotificationType.december}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={
              typeFilter.includes(WithholdingNotificationType.june)
                ? 'contained'
                : 'outlined'
            }
            color="primary"
            onClick={() => {
              onItemClick([WithholdingNotificationType.june]);
            }}
          >
            {WithholdingNotificationType.june}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

function WithholdingNotificationsList() {
  let isMounted = true;
  const { user } = useUser();
  const {
    setGlobalPageSize,
    getAppConstant,
    addGlobalMessage,
    organization: { accountNumberValue },
  } = useGlobalContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [hasLoaded, setHasLoaded] = useState(false as boolean);
  const [rows, setRows] = useState<Array<WithholdingNotificationRow>>([]);
  const [changedYearFilters, setChangedYearFilters] = useState<Array<string>>(
    [],
  );
  const [rowData, setRowData] = useState<WithholdingNotificationRow>();
  const [filteredRows, setFilteredRows] = useState<
    Array<WithholdingNotificationRow>
  >([]);
  const [typeFilter, setTypeFilter] = useState<
    Array<WithholdingNotificationType>
  >([WithholdingNotificationType.december, WithholdingNotificationType.june]);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      // Hide columns based on user role
      accountId: false,
      financialOrganizationId: false,
      accountOwnerId: false,
      firstName: false,
      lastName: false,
      nonHumanName: false,
      addressLine1: false,
      addressLine2: false,
      city: false,
      state: false,
      foreignStateProvinceRegion: false,
      zip: false,
      country: false,
      federalWithholdingPercent: false,
      withholdingState: false,
      stateWithholdingPercent: false,
      stateWithholdingAdditionalAmount: false,
      recurringDistributionFrequency: false,
      recurringDistributionStartDate: false,
      recurringDistEndDate: false,
      withholdingNotificationId: false,
    });
  const [modalOpen, setmodalOpen] = useState(false);
  const contentStyle = {
    height: '100%',
  };

  const useStyles = makeStyles(() => ({
    toolbarContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '15px',
    },
    exportButton: {
      backgroundColor: 'black', // Set your desired background color
      color: 'white', // Set your desired text color
      '&:hover': {
        backgroundColor: 'black', // Set your desired hover background color
      },
      padding: '5px',
    },
  }));
  const headerAccountName =
    accountNumberValue === AccountMemberValue.accountNumber
      ? 'Account #'
      : 'Member #';

  const classes = useStyles();

  // Allow the filter items to be selected or deselected
  const handleNotificationTypeClick = (
    withholdingNotificationsTypes: Array<WithholdingNotificationType>,
  ) => {
    if (
      withholdingNotificationsTypes.some((type) => typeFilter.includes(type))
    ) {
      setTypeFilter([
        // Deselect the clicked item if already selected
        ...typeFilter.filter(
          (type) => !withholdingNotificationsTypes.includes(type),
        ),
      ]);
    } else {
      // Otherwise add the value to the filter array
      setTypeFilter([...typeFilter, ...withholdingNotificationsTypes]);
    }
  };

  const gotoRecordAccount = (row: WithholdingNotificationRow): void => {
    navigate(
      `/accountOwner/${row.accountOwnerId}/account/${row.accountId}/?expanded=${AccountDetailAccordionType.withholdings}`,
    );
  };

  const goToEditRecord = (row: WithholdingNotificationRow): void => {
    setmodalOpen(true);
    setRowData(row);
    // navigate(
    //   `/transactions/recurringDistributions?editActive=true&accountId=${row.accountId}&accountOwnerId=${row.accountOwnerId}`,
    // );
  };

  const CustomGridToolbarContainer = () => {
    return (
      <GridToolbarContainer className={classes.toolbarContainer}>
        <GridToolbarExport
          csvOptions={{
            allColumns: true, // Ensure all columns, including hidden ones, are included in the export
          }}
          printOptions={{ disableToolbarButton: true }}
          slotProps={{
            button: {
              className: classes.exportButton,
            },
          }}
        />
      </GridToolbarContainer>
    );
  };

  const columns = [
    {
      field: 'financialOrganizationId',
      headerName: 'Financial Organization Id',
      width: 156,
      hide: true,
    },
    {
      field: 'accountOwnerId',
      headerName: 'Account Owner Id',
      width: 156,
      hide: false,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      width: 156,
      hide: false,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      width: 156,
      hide: false,
    },
    {
      field: 'nonHumanName',
      headerName: 'Non-Human Name',
      width: 156,
      hide: false,
    },
    {
      field: 'fullName',
      headerName: 'Owner',
      width: 200,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;
        // Define the download types
        const selectActions: Array<MultiButtonAction> = [
          {
            label: 'View Account',
            handleAction: () => gotoRecordAccount(row),
            icon: <WithholdingNotificationIcon />,
          },
          {
            label: 'Edit Withholding Election',
            handleAction: () => goToEditRecord(row),
            icon: <EditIcon />,
          },
        ];

        return (
          <MultiButton
            variant="text"
            actions={selectActions}
            sx={{
              justifyContent: 'flex-start' as any,
              width: '100%',
              textDecoration: 'none' as any,
              textTransform: 'none' as any,
            }}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </MultiButton>
        );
      },
    },
    {
      field: 'taxpayerIdNumber',
      headerName: 'SSN/ID',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
    {
      field: 'addressLine1',
      headerName: 'Address line 1',
      width: 156,
      hide: false,
    },
    {
      field: 'addressLine2',
      headerName: 'Address line 2',
      width: 156,
      hide: false,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 156,
      hide: false,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 156,
      hide: false,
    },
    {
      field: 'foreignStateProvinceRegion',
      headerName: 'Foreign State Province Region',
      width: 156,
      hide: false,
    },
    {
      field: 'zip',
      headerName: 'Zip Code',
      width: 156,
      hide: false,
    },
    {
      field: 'country',
      headerName: 'Country',
      width: 156,
      hide: false,
    },
    {
      field: 'accountId',
      headerName: 'Account Id',
      width: 156,
      hide: false,
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 156,
      valueFormatter: (value) => {
        return getAppConstant(ConstantsMappingKey.accountType, value);
      },
    },
    {
      field: 'accountNumber',
      headerName: headerAccountName,
      width: 156,
    },
    {
      field: 'federalWithholdingPercent',
      headerName: 'Federal Withholding Percent',
      width: 156,
      hide: false,
    },
    {
      field: 'withholdingState',
      headerName: 'Withholding State',
      width: 156,
      hide: false,
    },
    {
      field: 'stateWithholdingPercent',
      headerName: 'State Withholding Percent',
      width: 156,
      hide: false,
    },
    {
      field: 'stateWithholdingAdditionalAmount',
      headerName: 'State Withholding Additional Amount',
      width: 156,
      hide: false,
    },
    {
      field: 'recurringDistributionFrequency',
      headerName: 'Recurring Distribution Frequency',
      width: 156,
      hide: false,
    },
    {
      field: 'recurringDistributionStartDate',
      headerName: 'Recurring Distribution Start Date',
      width: 156,
      hide: false,
    },
    {
      field: 'recurringDistEndDate',
      headerName: 'Recurring Distribution End Date',
      width: 156,
      hide: false,
    },
    {
      field: 'withholdingNotificationId',
      headerName: 'Withholding Notification Id',
      width: 156,
      hide: false,
    },
    {
      field: 'withholdingNotificationType',
      headerName: 'Notification Type',
      width: 156,
      valueGetter: (value, row) => {
        return withholdingNotificationTypeLabels[
          value as WithholdingNotificationType
        ];
      },
    },
    {
      field: 'withholdingNotificationDateSent',
      headerName: 'Sent Date',
      width: 156,
      valueFormatter: dateValueFormatterPro,
    },
    {
      field: 'withholdingNotificationStatus',
      headerName: 'Status',
      width: 200,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        const statusComponents = {
          [WithholdingNotificationStatus.sent]: (
            <Button color="success" startIcon={<CheckCircleIcon />}>
              Sent
            </Button>
          ),
          [WithholdingNotificationStatus.pending]: (
            <Button color="warning" startIcon={<PendingIcon />}>
              Pending
            </Button>
          ),
          [WithholdingNotificationStatus.draft]: (
            <Button color="info" startIcon={<PendingIcon />}>
              Draft
            </Button>
          ),
        };

        return statusComponents[value as WithholdingNotificationStatus];
      },
    },
  ] as GridColDef[];

  // Apply the type filter separately from tax year filter using a function call
  const applyTypeFilter = (rowsToFilter) => {
    // check if the type filter is empty and the year filter matchs
    const matchingRows = rowsToFilter.filter(
      ({ withholdingNotificationType, year }) =>
        typeFilter.includes(withholdingNotificationType),
    );

    setFilteredRows(matchingRows);
  };

  // Fetch all withholding notifications for the current organization
  async function getAndSetOrgWithholdingNotifications(): Promise<void> {
    setIsLoading(true);

    await getOrgWithholdingNotifications(user.organizationId, user.token, user)
      .then(({ data = [] }) => {
        if (isMounted) {
          const preppedRows = WithholdingNotificationRowsFactory(data);
          setRows(preppedRows);
          applyTypeFilter(preppedRows);
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) ||
            'Error fetching organization withholding notifications',
        );
      });

    if (isMounted) {
      setIsLoading(false);
      setHasLoaded(true);
    }
  }

  // Map out relevant options for the org withholding notifications
  const taxYearFilterOptions = [
    ...new Set(
      rows.map(({ withholdingNotificationRecordCreated }) =>
        getYear(parseISO(withholdingNotificationRecordCreated)).toString(),
      ),
    ),
  ];

  useEffect(() => {
    // filter the rows based on the years selected from the tax year filter
    let filteredRows = [];

    rows.map((item) => {
      if (changedYearFilters.includes(item.year)) {
        filteredRows.push(item);
      }
    });
    applyTypeFilter(filteredRows);

    return () => {
      isMounted = false;
    };
  }, [typeFilter]);

  const handleClose = () => {
    setmodalOpen(false);
  };

  const refreshTableCloseDialog = () => {
    handleClose();
    getAndSetOrgWithholdingNotifications();
  };

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Recurring Distributions
          </Typography>

          <Typography color="secondary" variant="h1" gutterBottom>
            Withholding Notifications
          </Typography>

          <Grid container alignItems="center" spacing={2} mb={2}>
            <Grid item>
              <WithholdingNotificationFilter
                typeFilter={typeFilter}
                onItemClick={handleNotificationTypeClick}
              />
            </Grid>

            {Boolean(taxYearFilterOptions.length) && (
              <Grid item ml={{ sm: 2 }}>
                <TaxYearsFilterForm
                  items={rows}
                  options={taxYearFilterOptions}
                  onChange={applyTypeFilter}
                  componentName="WithholdingNotificationsList"
                  selectedTaxYears={setChangedYearFilters}
                />
              </Grid>
            )}

            {!hasLoaded && (
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<SearchOutlinedIcon />}
                  type="submit"
                  onClick={async () => {
                    if (!hasLoaded) {
                      await getAndSetOrgWithholdingNotifications();
                    }
                  }}
                >
                  Search
                </Button>
              </Grid>
            )}
          </Grid>
          {isLoading ? (
            <Box width="1">
              <LinearProgress />
            </Box>
          ) : (
            <DataGridPro
              slots={{
                noRowsOverlay: SiraNoRowsOverlay,
                toolbar: CustomGridToolbarContainer,
              }}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
                columns: {
                  columnVisibilityModel: columnVisibilityModel,
                },
              }}
              pagination
              pageSizeOptions={globalPaginationOptions}
              onPaginationModelChange={setGlobalPageSize}
              autoHeight
              columns={columns}
              rows={filteredRows}
            />
          )}

          <SiraTaxFormCorrectionModal open={modalOpen} handleClose={handleClose}>
              <Box sx={contentStyle}>
                <ModalRecurringDistribution rowData={rowData}
                  editActive={true}
                  closeDialog={refreshTableCloseDialog}
                />
              </Box>
          </SiraTaxFormCorrectionModal>
        </>
      </FormPaper>
    </Layout>
  );
}

export default WithholdingNotificationsList;

/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import {
  Paper,
  Typography,
  Grid,
  Divider,
  Box,
  Button,
  useTheme,
} from '@mui/material';
import NumberFormat from 'react-number-format';
import AddIcon from '@mui/icons-material/Add';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { Form, Formik } from 'formik';
import SiraSelectField, { SiraSelectItem } from '../SiraSelectField';
import {
  BeneficiaryTypes,
  ClaimsBeneficiary,
} from '../../../api/BeneficiaryClaimsApi.d';
import {
  createBeneficiaryDistributionPDF,
  getBeneficiaryDistributionPDF,
  searchBeneficiariesClaims,
} from '../../../api/BeneficiariesClaimsApi';
import StepButtonBar from '../../steps/StepButtonBar';
import { UserRole } from '../../../api/UserApi.d';
import { useUser } from '../../../auth/useUser';
import ObscuredTaxId, { TaxIdFormat } from '../../ObscuredTaxId';
import { ConstantsMappingKey } from '../../../api/SharedTextApi.d';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import GenerateBeneficiaryPDF, {
  BENEFICIARY_PDF_INITIAL_VALUES,
  BeneficiaryPDF,
} from '../../../page/GenerateBeneficiaryPDF';
import { BeneDistributionElectionTransfers } from '../../../api/DistributionApi.d';
import {
  createFileBlobFromBase64,
  downloadAsFile,
} from '../../../utils/App.utils';
import { errorMessages } from '../../../utils/errorhandling.utils';
import { SiraDrawer } from '../../SiraDrawer';
import { createOrSaveAccountOwner } from '../../../api/AccountOwnerApi';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';
import { createOrSaveAccount } from '../../../api/AccountApi';
import { Account, AccountStatus, AccountType } from '../../../api/AccountApi.d';
import LoadingAnimation from '../../animation-components/loadingWord';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

interface DesignatedBeneficiaryProfileProps {
  beneficiary: ClaimsBeneficiary;
  addDistribution: Function;
  addedDistribution: string;
  account?: any;
  accountOwner?: any;
}

interface AddDistributionOptionProps {
  initialValues: ClaimsBeneficiary;
  onSubmit?: Function;
  onCancel?: Function;
}
export enum BeneficiaryDistributionOption {
  default = '',
  totalDistribution = 'TOTAL_DISTRIBUTION',
  transferToIraAtThisOrg = 'TRANSFER_TO_IRA_AT_THIS_ORG',
  recurringDistriOver10 = 'RECURRING_DISTRIBUTION_OVER_10_YEARS',
  recurringDistributionUsingBene = 'RECURRING_DISTRIBUTIONS_USING_BENEFICIARY_LIFE_EXPECTANCY',
  recurringDistributionReCalc = 'RECURRING_DIST_USING_THE_BENEYS_LIFE_EXPECTANCY_RECALCULATED',
  transferToInheritedDifOrg = 'TRANSFER_TO_INHERITED_IRA_AT_DIFFERENT_ORG',
  transferToMyOwnIraAtDifOrgSpouseOnly = 'TRANSFER_TO_MY_OWN_IRA_AT_DIFFERENT_ORG_SPOUSE_ONLY',
}

export const DISTRIBUTION_INIT: ClaimsBeneficiary = {
  beneficiaryDistributionOption: BeneficiaryDistributionOption.default,
};

export const distributions: Array<SiraSelectItem> = [
  {
    label: '',
    value: BeneficiaryDistributionOption.default,
  },
  {
    label: 'Total Distribution',
    value: BeneficiaryDistributionOption.totalDistribution,
  },
  {
    label: 'Transfer to my own IRA at this organization (Spouse Only)',
    value: BeneficiaryDistributionOption.transferToIraAtThisOrg,
  },
  {
    label: 'Recurring distributions over 10 years',
    value: BeneficiaryDistributionOption.recurringDistriOver10,
  },
  {
    label: 'Recurring distributions using the beneficiary’s life expectancy',
    value: BeneficiaryDistributionOption.recurringDistributionUsingBene,
  },
];

function AddDistributionOption({
  onSubmit = () => {},
  initialValues,
  onCancel = () => {},
}: AddDistributionOptionProps) {
  const theme = useTheme();
  const { getAppConstant } = useGlobalContext();

  const distributionsSelection: Array<SiraSelectItem> = [
    {
      label: '',
      value: BeneficiaryDistributionOption.default,
    },
    {
      label: getAppConstant(
        ConstantsMappingKey.beneficiaryDistributionOption,
        BeneficiaryDistributionOption.totalDistribution,
      ),
      value: BeneficiaryDistributionOption.totalDistribution,
    },
    {
      label: getAppConstant(
        ConstantsMappingKey.beneficiaryDistributionOption,
        BeneficiaryDistributionOption.transferToIraAtThisOrg,
      ),
      value: BeneficiaryDistributionOption.transferToIraAtThisOrg,
    },
    {
      label: getAppConstant(
        ConstantsMappingKey.beneficiaryDistributionOption,
        BeneficiaryDistributionOption.recurringDistriOver10,
      ),
      value: BeneficiaryDistributionOption.recurringDistriOver10,
    },
    {
      label: getAppConstant(
        ConstantsMappingKey.beneficiaryDistributionOption,
        BeneficiaryDistributionOption.recurringDistributionUsingBene,
      ),
      value: BeneficiaryDistributionOption.recurringDistributionUsingBene,
    },
    {
      label: getAppConstant(
        ConstantsMappingKey.beneficiaryDistributionOption,
        BeneficiaryDistributionOption.recurringDistributionReCalc,
      ),
      value: BeneficiaryDistributionOption.recurringDistributionReCalc,
    },
    {
      label: getAppConstant(
        ConstantsMappingKey.beneficiaryDistributionOption,
        BeneficiaryDistributionOption.transferToInheritedDifOrg,
      ),
      value: BeneficiaryDistributionOption.transferToInheritedDifOrg,
    },
    {
      label: getAppConstant(
        ConstantsMappingKey.beneficiaryDistributionOption,
        BeneficiaryDistributionOption.transferToMyOwnIraAtDifOrgSpouseOnly,
      ),
      value: BeneficiaryDistributionOption.transferToMyOwnIraAtDifOrgSpouseOnly,
    },
  ];

  const classes = {
    root: {
      overflowX: 'auto' as any,
      paddingTop: '10px' as any,
    },
    formContainer: {
      [theme.breakpoints.down('md')]: {
        flexShrink: 0,
      },
      [theme.breakpoints.up('sm')]: {
        flexShrink: 1,
      },
    },
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      enableReinitialize
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <Grid container spacing={1} sx={classes.root}>
              <Grid item xs={12}>
                <SiraSelectField
                  name="beneficiaryDistributionOption"
                  items={distributionsSelection}
                  label="Distribution elections"
                />
              </Grid>
              <Grid item xs={12}>
                <StepButtonBar
                  isSubmitting={isSubmitting}
                  submitName="Save"
                  onCancel={onCancel}
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}

function DesignatedBeneficiaryProfile(
  props: DesignatedBeneficiaryProfileProps,
) {
  const navigate = useNavigate();
  const {
    accountOwner,
    addedDistribution,
    beneficiary = {} as ClaimsBeneficiary,
    addDistribution = () => {},
    account,
  } = props;
  const [active, setactive] = useState<boolean>(false);
  const [distributionOption, setdistributionOption] = useState<string>('');
  const { getAppConstant, addGlobalMessage } = useGlobalContext();
  const [modalOpen, setModalOpen] = useState(false);
  const [beneficiaryPDF, setBeneficiaryPDF] = useState<BeneficiaryPDF>(
    {} as BeneficiaryPDF,
  );
  // based on the relationship type set button to view or create inherited
  const [showCreateInherited, setShowCreateInherited] =
    useState<boolean>(false);
  const [showViewInherited, setShowViewInherited] = useState<string>('');
  const [vieworCreate, setVieworCreate] = useState<string>('');
  const { user } = useUser();
  const [checkingInherited, setCheckingInherited] = useState<boolean>(false);
  const [accountOwnerId, setAccountOwnerId] = useState<string>('');
  const [acctId, setAccountId] = useState<string>('');

  const setDistributionOptionReadOnly = (value) => {
    switch (value) {
      case BeneficiaryDistributionOption.totalDistribution:
        setdistributionOption(
          getAppConstant(
            ConstantsMappingKey.beneficiaryDistributionOption,
            BeneficiaryDistributionOption.totalDistribution,
          ),
        );
        break;
      case BeneficiaryDistributionOption.transferToIraAtThisOrg:
        setdistributionOption(
          getAppConstant(
            ConstantsMappingKey.beneficiaryDistributionOption,
            BeneficiaryDistributionOption.transferToIraAtThisOrg,
          ),
        );
        break;
      case BeneficiaryDistributionOption.recurringDistriOver10:
        setdistributionOption(
          getAppConstant(
            ConstantsMappingKey.beneficiaryDistributionOption,
            BeneficiaryDistributionOption.recurringDistriOver10,
          ),
        );
        break;
      case BeneficiaryDistributionOption.recurringDistributionUsingBene:
        setdistributionOption(
          getAppConstant(
            ConstantsMappingKey.beneficiaryDistributionOption,
            BeneficiaryDistributionOption.recurringDistributionUsingBene,
          ),
        );
        break;
      case BeneficiaryDistributionOption.recurringDistributionReCalc:
        setdistributionOption(
          getAppConstant(
            ConstantsMappingKey.beneficiaryDistributionOption,
            BeneficiaryDistributionOption.recurringDistributionReCalc,
          ),
        );
        break;
      case BeneficiaryDistributionOption.transferToInheritedDifOrg:
        setdistributionOption(
          getAppConstant(
            ConstantsMappingKey.beneficiaryDistributionOption,
            BeneficiaryDistributionOption.transferToInheritedDifOrg,
          ),
        );
        break;
      case BeneficiaryDistributionOption.transferToMyOwnIraAtDifOrgSpouseOnly:
        setdistributionOption(
          getAppConstant(
            ConstantsMappingKey.beneficiaryDistributionOption,
            BeneficiaryDistributionOption.transferToMyOwnIraAtDifOrgSpouseOnly,
          ),
        );
        break;
      default:
        setdistributionOption('');
        break;
    }
  };

  const setBeneficiaryPDFReadOnly = (value) => {
    let totalDistribution;
    let transferRBD;
    let transferRBDPayoutSchedule;
    let transferRMDSubText;
    let transferRMDOwnSubText;
    let transferRMDSubTextFinOrg;
    let years;
    let transferToInheritedIraChecked = false;
    let transferRBDChecked = false;
    let transferRBDFinOrgChecked = false;
    let transferRBDOwnChecked = false;
    let transferTotalDistributionChecked = false;
    let transferLifeExpectancyChecked = false;

    value.selectedDistOptions.forEach((option) => {
      switch (option.optionSelected) {
        case BeneDistributionElectionTransfers.transferToInheritedFinOrg:
          transferRMDSubText = option.subOptionSelected;
          transferToInheritedIraChecked = true;
          break;
        case BeneDistributionElectionTransfers.transferToInheritedLifeExpectancyPayoutSchedule:
          transferLifeExpectancyChecked = true;
          break;
        case BeneDistributionElectionTransfers.transferToInheritedIraPayoutSchedule:
          transferRBDChecked = true;
          years = option.years;
          break;
        case BeneDistributionElectionTransfers.totalDistribution:
          totalDistribution = true;
          break;
        case BeneDistributionElectionTransfers.transferToOwn:
          transferRMDOwnSubText = option.subOptionSelected;
          transferRBDOwnChecked = true;
          break;
        case BeneDistributionElectionTransfers.transferToOwnFinOrg:
          transferRMDSubTextFinOrg = option.subOptionSelected;
          transferRBDFinOrgChecked = true;
          break;
        default:
          transferTotalDistributionChecked = true;
          break;
      }
    });

    setBeneficiaryPDF({
      transferToInheritedIraChecked,
      transferRBDChecked,
      transferRBD,
      transferRBDPayoutSchedule,
      transferRMDSubText,
      signatureLabel: value.signatureLabel,
      optionalSignatureLabel: value.optionalSignatureLabel,
      nameLabel: value.nameLabel,
      notary: value.notary,
      disabilityCertification: value.disabilityCertification,
      distributionMethod: value.distributionMethods,
      totalDistribution,
      years,
      transferRBDFinOrgChecked,
      transferRBDOwnChecked,
      transferTotalDistributionChecked,
      transferLifeExpectancyChecked,
      transferRMDOwnSubText,
      transferRMDSubTextFinOrg,
    });
  };

  async function postGenerateBeneficiaryDistPDF(
    values: BeneficiaryPDF,
  ): Promise<void> {
    // we are mapping the selected vallues in the beneficiary pdf to the values that are expected by the api
    const selectedDistOptions = [];

    if (values.transferToInheritedIraChecked) {
      selectedDistOptions.push({
        optionSelected:
          BeneDistributionElectionTransfers.transferToInheritedFinOrg,
        subOptionSelected: values.transferRMDSubText,
      });
    }

    if (values.transferRBDChecked) {
      selectedDistOptions.push({
        optionSelected:
          BeneDistributionElectionTransfers.transferToInheritedIraPayoutSchedule,
        years: values.years,
      });
    }

    if (values.transferLifeExpectancyChecked) {
      selectedDistOptions.push({
        optionSelected:
          BeneDistributionElectionTransfers.transferToInheritedLifeExpectancyPayoutSchedule,
      });
    }

    if (values.transferRBDOwnChecked) {
      selectedDistOptions.push({
        optionSelected: BeneDistributionElectionTransfers.transferToOwn,
        subOptionSelected: values.transferRMDOwnSubText,
      });
    }

    if (values.transferRBDFinOrgChecked) {
      selectedDistOptions.push({
        optionSelected: BeneDistributionElectionTransfers.transferToOwnFinOrg,
        subOptionSelected: values.transferRMDSubTextFinOrg,
      });
    }

    if (values.transferTotalDistributionChecked) {
      selectedDistOptions.push({
        optionSelected: BeneDistributionElectionTransfers.totalDistribution,
      });
    }

    const mergedValues = {
      beneficiaryClaimId: beneficiary.beneficiaryClaimId,
      accountId: account.accountId,
      // signatureLabel: values.signatureLabel,
      // optionalSignatureLabel: values.optionalSignatureLabel,
      notary: values.notary,
      disabilityCertification: values.disabilityCertification,
      distributionMethods: values.distributionMethod,
      selectedDistOptions,
    };

    await createBeneficiaryDistributionPDF(
      mergedValues,
      account.accountId,
      beneficiary.beneficiaryClaimId,
      beneficiary.beneficiaryId,
      user.organizationId,
      user.token,
    )
      .then((response) => {
        setBeneficiaryPDF({} as BeneficiaryPDF);
        const { data } = response;
        const { bytes: base64 = '' } = data;
        const blob =
          createFileBlobFromBase64(base64, 'application/pdf') || data;

        if (data) {
          const winURL = window.URL.createObjectURL(blob);
          window.open(winURL, '_blank');
          downloadAsFile(blob, 'Created Pdf');
        }
        setModalOpen(false);

        addGlobalMessage(
          'beneficiaries distribution election successfully added',
          {
            severity: 'success',
          },
        );
      })
      .catch((err) => {
        setModalOpen(true);
        addGlobalMessage(
          errorMessages(err) ||
            'Could not add beneficiaries distribution election for this account',
        );
      });
  }

  async function getBeneficiaryPDFElection(): Promise<void> {
    await getBeneficiaryDistributionPDF(
      user.organizationId,
      account.accountId,
      beneficiary.beneficiaryClaimId,
      beneficiary.beneficiaryId,
      user.token,
    )
      .then((response) => {
        if (response.data) {
          setBeneficiaryPDFReadOnly(response.data);
        } else {
          setBeneficiaryPDF({} as BeneficiaryPDF);
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) ||
            'Could not get beneficiaries distribution election for this account',
        );
      });
  }

  const handleClose = () => {
    setModalOpen(false);
  };

  const setCreatorViewInherited = () => {
    if (['CHILD', 'SPOUSE', 'OTHER'].includes(beneficiary.relationship)) {
      const {
        firstName,
        lastName,
        dateOfBirth,
        taxpayerIdNumber,
        addressLine1,
        city,
        state,
        country,
        zip,
      } = beneficiary;

      if (
        firstName &&
        lastName &&
        dateOfBirth &&
        taxpayerIdNumber &&
        addressLine1 &&
        city &&
        state &&
        country &&
        zip
      ) {
        // if accountType of beneficiary is ESA/HSA we should not show the create inherited account button
        if ([AccountType.esa, AccountType.hsa].includes(account.accountType)) {
          setShowCreateInherited(false);
        } else {
          setShowCreateInherited(true);
        }
        searchInheritedAccount(beneficiary);
      } else {
        setShowCreateInherited(false);
      }
    }
    if (
      ['TRUST', 'OTHER_NON_HUMAN', 'ESTATE'].includes(beneficiary.relationship)
    ) {
      const {
        name,
        taxpayerIdNumber,
        addressLine1,
        city,
        state,
        country,
        zip,
      } = beneficiary;

      if (
        name &&
        taxpayerIdNumber &&
        addressLine1 &&
        city &&
        state &&
        country &&
        zip
      ) {
        // if accountType of beneficiary is ESA/HSA we should not show the create inherited account button
        if ([AccountType.esa, AccountType.hsa].includes(account.accountType)) {
          setShowCreateInherited(false);
        } else {
          setShowCreateInherited(true);
        }
        searchInheritedAccount(beneficiary);
      } else {
        setShowCreateInherited(false);
      }
    }
  };

  // first we need to search and see if the user has an inherited account
  const searchInheritedAccount = async (
    beneficiary: ClaimsBeneficiary,
  ): Promise<void> => {
    const { taxpayerIdNumber } = beneficiary;
    setCheckingInherited(true);
    await searchBeneficiariesClaims(
      { query: taxpayerIdNumber },
      user.organizationId,
      user.token,
    )
      .then((res) => {
        // if user exist we need to show the view inherited account button
        const { data } = res;

        // multiple accounts can come back find the one with a deceased account that matches the current account
        const deceasedOwner = data.find(
          (claim) =>
            claim.account !== undefined && claim.account.deceasedAccountOwnerId &&
            claim.account.deceasedAccountOwnerId === account.accountOwnerId,
        );

        const deceasedAccount = data.find(
          (claim) =>
            claim.account !== undefined && claim.account.deceasedAccountId &&
            claim.account.deceasedAccountId === account.accountId,
        );

        if (deceasedOwner !== undefined && deceasedAccount !== undefined) {
          if (deceasedOwner && deceasedAccount) {
            setVieworCreate('View');
            setShowViewInherited('View Inherited Account');
            setAccountOwnerId(deceasedOwner.accountOwner.accountOwnerId);
            setAccountId(deceasedAccount.account.accountId);
          } else {
            setVieworCreate('Create');
            if (data.length > 0 && deceasedOwner.accountOwner.accountOwnerId) {
              setAccountOwnerId(deceasedOwner.accountOwner.accountOwnerId);
            } else {
              setAccountOwnerId('');
            }
            setShowViewInherited('Create Inherited Account');
          }
        } else {
          if (data.length > 0 && data[0].accountOwner.accountOwnerId) {
            setAccountOwnerId(data[0].accountOwner.accountOwnerId);
          } else {
            setAccountOwnerId('');
          }
          setVieworCreate('Create');
          setShowViewInherited('Create Inherited Account');
        }
        setCheckingInherited(false);
      })
      .catch(() => {
        addGlobalMessage('Error fetching account owner search results');
      });
  };

  const createAccount = async (ownerId) => {
    // set Accounttype based on the beneficiar
    //         If the deceased owner's account is a Roth IRA or Inherited Roth IRA, create an Inherited Roth IRA.
    // If the deceased owner's account is a Traditional IRA, Inherited Traditional IRA, SEP IRA, or SIMPLE IRA, create an Inherited Traditional IRA
    // If it's an ESA, create an ESA
    // If it's an HSA, create an HSA

    let accountType;

    if (
      [AccountType.rothIra, AccountType.inheritedRoth].includes(
        account.accountType,
      )
    )
      accountType = AccountType.inheritedRoth;
    else if (
      [
        AccountType.traditionalIra,
        AccountType.inheritedTraditional,
        AccountType.traditionalSep,
        AccountType.simpleIra,
      ].includes(account.accountType)
    )
      accountType = AccountType.inheritedTraditional;

    // create inherited account after creating account owner
    const accountData: Account = {
      accountStatus: AccountStatus.open,
      accountOwnerId: ownerId,
      accountType,
      deceasedAccountId: account.accountId,
      eligibleDesignatedBeneficiary: true,
      relationship: beneficiary.relationship,
      openDate:format(new Date(), 'yyyy-MM-dd'),
    };

    createOrSaveAccount(
      accountData,
      user.organizationId,
      ownerId,
      user.token,
      user,
    )
      .then((res) => {
        setAccountId(res.data.accountId);
        setVieworCreate('View');
        setShowViewInherited('View Inherited Account');
        addGlobalMessage('Inherited Account Created', {
          severity: 'success',
        });
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Error creating account details',
        );
      });
  };

  const createInheritedAccount = async () => {
    // so now we will check if the user exist

    if (vieworCreate === 'Create') {
      // we need to create an account owner
      const {
        firstName,
        lastName,
        dateOfBirth,
        taxpayerIdNumber,
        addressLine1,
        city,
        state,
        country,
        zip,
        name,
        fullName,
      } = beneficiary;

      const owner: AccountOwner = {
        firstName,
        lastName,
        name,
        fullName,
        dateOfBirth,
        taxpayerIdNumber,
        addressLine1,
        city,
        state,
        country,
        zip,
      };

      if (
        accountOwnerId === '' ||
        accountOwnerId === undefined ||
        accountOwnerId === null
      ) {
        createOrSaveAccountOwner(owner, user.organizationId, user.token, user)
          .then(({ data }) => {
            createAccount(data.accountOwnerId);
          })
          .catch(() => {
            addGlobalMessage('Unable to Update Date of Owner Death');
          });
      } else {
        createAccount(accountOwnerId);
      }
    } else if (vieworCreate === 'View') {
      navigate(`/accountOwner/${accountOwnerId}/account/${acctId}`);
    }
  };

  useEffect(() => {
    if (beneficiary) {
      if (beneficiary.beneficiaryDistributionOption) {
        setDistributionOptionReadOnly(
          beneficiary.beneficiaryDistributionOption,
        );
      }
      setCreatorViewInherited();
      getBeneficiaryPDFElection();
    }
  }, [beneficiary]);

  useEffect(() => {
    if (addedDistribution === 'success') {
      setactive(false);
    }
  }, [addedDistribution]);

  return (
    <Paper elevation={3}>
      {/* Designated Bene Info */}
      <Grid container sx={{ p: 3, mt: 2, height: '100%' }}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={7} sm={7}>
              <Typography variant="h4" gutterBottom>
                Beneficiary Information
              </Typography>
            </Grid>
            <Grid item xs={5} sm={5} sx={{ textAlign: 'right' }}>
              {/* new button to create inherited Account for user */}

              {showCreateInherited ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    createInheritedAccount();
                  }}
                >
                  {checkingInherited ? (
                    <LoadingAnimation loadingText="Checking" />
                  ) : (
                    showViewInherited
                  )}
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ mb: 2 }} />

        <Grid item xs={6}>
          <Typography variant="body1" color="primary">
            Full Name: {beneficiary.name || beneficiary.fullName}
          </Typography>
          {beneficiary.representativeFirstName && (
            <Typography variant="body1">
              Representative: {beneficiary.representativeFirstName}{' '}
              {beneficiary.representativeLastName}
            </Typography>
          )}
          {beneficiary.relationship !== 'OTHER' && !beneficiary.name && (
            <Typography variant="body1" color="primary" pt={0.5}>
              Date of Birth: {beneficiary.dateOfBirth}
            </Typography>
          )}
          {beneficiary.taxpayerIdNumber && (
            <Typography variant="body1" color="primary" pt={0.5}>
              SSN/ID:{' '}
              <ObscuredTaxId
                taxId={beneficiary.taxpayerIdNumber.toString()}
                format={TaxIdFormat.ssn}
              />
            </Typography>
          )}
          {beneficiary.addressLine1 && (
            <>
              <Typography variant="body1" color="primary" pt={0.5}>
                Address Line 1: {beneficiary.addressLine1}
              </Typography>
              {beneficiary.addressLine2 && (
                <Typography variant="body1" color="primary" pt={0.5}>
                  Address Line 2: {beneficiary.addressLine2}
                </Typography>
              )}
              {beneficiary.addressLine3 && (
                <Typography variant="body1" color="primary" pt={0.5}>
                  Address Line 3: {beneficiary.addressLine3}
                </Typography>
              )}
              <Typography variant="body1" color="primary" pt={0.5}>
                City: {beneficiary.city}
              </Typography>
              <Typography variant="body1" color="primary" pt={0.5}>
                State: {beneficiary.state}
              </Typography>
              <Typography variant="body1" color="primary" pt={0.5}>
                Country: {beneficiary.country}
              </Typography>
              <Typography variant="body1" color="primary" pt={0.5}>
                Zip: {beneficiary.zip}
              </Typography>
            </>
          )}
          {user.roles.includes(UserRole.multi) ? (
            <Button
              sx={{ mt: 2 }}
              variant="contained"
              color="primary"
              startIcon={<PictureAsPdfIcon />}
              onClick={() => {
                setModalOpen(true);
              }}
            >
              Generate Beneficiary PDF
            </Button>
          ) : null}
        </Grid>
        <Grid item xs={6}>
          {beneficiary.emailAddress && (
            <Typography variant="body1" color="primary" pt={0.5}>
              Email Address: {beneficiary.emailAddress}
            </Typography>
          )}
          {beneficiary.phoneNumber && (
            <Typography variant="body1" color="primary" pt={0.5}>
              Phone Number: {beneficiary.phoneNumber}
            </Typography>
          )}
          <Typography variant="body1" color="primary" pt={0.5}>
            Relationship to Deceased: {beneficiary.relationship}
          </Typography>
          <Box>
            <Box pt={0.5} display="inline-block">
              <Typography variant="body2">Designated</Typography>
              <Typography variant="h4">
                <NumberFormat
                  value={beneficiary.percent}
                  suffix="%"
                  displayType="text"
                  decimalScale={2}
                />
              </Typography>
            </Box>
          </Box>
          {beneficiary.beneficiaryDistributionOption && (
            <Typography variant="body1" color="primary" pt={0.5}>
              Distribution option: {distributionOption}
            </Typography>
          )}
          {active && user.roles.includes(UserRole.multi) && (
            <Grid item xs={12}>
              <AddDistributionOption
                initialValues={{ ...DISTRIBUTION_INIT, ...beneficiary }}
                onSubmit={(values) => {
                  addDistribution(values);
                  setDistributionOptionReadOnly(
                    values.beneficiaryDistributionOption,
                  );
                }}
                onCancel={() => {
                  setactive(false);
                }}
              />
            </Grid>
          )}{' '}
          {!active && (
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'flex-end' }}>
              {user.roles.includes(UserRole.multi) ? (
                <Button
                  sx={{ mt: 2 }}
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setactive(true);
                  }}
                >
                  Add Distribution Election
                </Button>
              ) : null}
            </Grid>
          )}
        </Grid>
      </Grid>
      <SiraDrawer open={modalOpen} onClose={handleClose}>
        <>
          <Typography variant="h5" color="secondary" gutterBottom>
            Create Beneficiary Distribution Election PDF
          </Typography>
          <GenerateBeneficiaryPDF
            initialValues={{
              ...BENEFICIARY_PDF_INITIAL_VALUES,
              ...beneficiaryPDF,
            }}
            account={account}
            onSubmit={async (values) => {
              await postGenerateBeneficiaryDistPDF(values);
            }}
            onCancel={handleClose}
          />
        </>
      </SiraDrawer>
    </Paper>
  );
}

export default DesignatedBeneficiaryProfile;

import React, { useEffect, useState } from 'react';
import { Alert, Box, Grid, LinearProgress, Typography } from '@mui/material';

import { useUser } from '../../../auth/useUser';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import { getRecurringDistributions } from '../../../api/RecurringDistributionApi';
import { RecurringDistribution } from '../../../api/RecurringDistributionApi.d';
import RecurringDistributionSummaryCard from './RecurringDistributionSummaryCard';
import { DistributionStatus } from '../../../api/DistributionApi.d';
import { Account } from '../../../api/AccountApi.d';

export interface SelectDistributionFormProps {
  account?: Account;
  onSelect?: Function;
}

function SelectDistributionForm({
  account,
  onSelect,
}: SelectDistributionFormProps) {
  let isMounted = true;
  const { user } = useUser();
  const [loading, setLoading] = useState(true);
  const { addGlobalMessage } = useGlobalContext();
  const [activeDistributions, setActiveDistributions] = useState(
    [] as Array<RecurringDistribution>
  );
  const { accountId, accountOwnerId } = account;

  // Get and set all of the active recurring distributions for this owner
  async function fetchAndSetRecurringDistributions() {
    setLoading(true);

    await getRecurringDistributions(
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
      DistributionStatus.active
    )
      .then((res) => {
        if (isMounted) {
          // only set the active distributions if the component is still mounted
          const activeDistributions = res.data.filter(
            (distribution) => distribution.distributionStatus === DistributionStatus.active
          );
          
          setActiveDistributions(activeDistributions);

          if (activeDistributions.length === 1) {
            onSelect(res.data[0], true);
          }
        }
      })
      .catch(() => {
        if (isMounted) {
          addGlobalMessage(
            `Error retrieving Distributions for account ${accountId}`
          );
        }
      });

    setLoading(false);
  }

  useEffect(() => {
    if (accountId && user.token) {
      fetchAndSetRecurringDistributions();
    }

    return () => {
      isMounted = false;
    };
  }, [accountId, user.token]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {loading && (
          <Box width="1">
            <LinearProgress color="secondary" />
          </Box>
        )}

        {activeDistributions.length === 0 && !loading && (
          <Alert severity="warning">
            This account has no active recurring distributions to modify.
          </Alert>
        )}

        {activeDistributions.length > 1 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>Click on Distribution to Modify</Typography>
            </Grid>

            {activeDistributions.map((recurringDistribution) => (
              <RecurringDistributionSummaryCard
                distribution={recurringDistribution}
                onClick={() => onSelect(recurringDistribution, true)}
              />
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default SelectDistributionForm;

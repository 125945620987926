import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { getYear, subYears } from 'date-fns';
import {
  Box,
  Typography,
  Button,
  LinearProgress,
  Grid,
  TextField,
} from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import CheckIcon from '@mui/icons-material/CheckBox';

import { globalPaginationOptions } from '../../../app.constants';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../../auth/useGlobalContext';
import { useUser } from '../../../auth/useUser';
import SiraNoRowsOverlay from '../../SiraNoRowsOverlay';
import DataGridExportToolbar from '../../DataGridExportToolbar';

import Layout from '../../Layout';
import FormPaper from '../../FormPaper';
import {
  Tax5498FormType,
  TaxForm,
  TaxForm5498,
} from '../../../api/TaxFormApi.d';
import { getTax5498Form } from '../../../api/TaxFormApi';
import ObscuredTaxId, { TaxIdFormat } from '../../ObscuredTaxId';
import { AccountDetailAccordionType } from '../../accountOwnerProfile/AccountOwnerAccountDetails';
import { ConstantsMappingKey } from '../../../api/SharedTextApi.d';
import {
  currencyValueFormatter,
  dateValueFormatter,
} from '../../../utils/DataGrid.utils';
import TaxReportingSelectField5498 from './TaxReportingSelectField5498';
import { AccountMemberValue } from '../../../api/OrganizationApi.d';
import { errorMessages } from '../../../utils/errorhandling.utils';

function TaxReport5498() {
  let isMounted = true;
  const { user } = useUser();
  const navigate = useNavigate();

  const { getAppConstant, addGlobalMessage , organization } = useGlobalContext();
  const { accountNumberValue } = organization;
  const {  setGlobalPageSize } = usePaginationContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [taxFormTypeFilter, setTaxFormTypeFilter] = useState<
    Array<Tax5498FormType | string>
  >([]);
  const [filteredRows = [], setFilteredRows] = useState<Array<TaxForm>>([]);

  const [rows, setRows] = useState([] as Array<TaxForm5498>);
  const [disableESACols, setDisableESACols] = useState<boolean>(false);
  const [disableHSACols, setDisableHSACols] = useState<boolean>(false);
  const [disableIRACols, setDisableIRACols] = useState<boolean>(false);
  const [disableFMVCols, setDisableFMVCols] = useState<boolean>(false);

  const gotoRecordAccount = (row: TaxForm5498): void => {
    navigate(
      `/accountOwner/${row.accountOwnerId}/account/${row.accountId}/?expanded=${AccountDetailAccordionType.taxForm}`
    );
  };

  const headerAccountName = accountNumberValue === AccountMemberValue.accountNumber ? 'Account Number': 'Member Number';


  const columns = [
    {
      field: 'legalName',
      headerName: 'Owner',
      width: 200,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            onClick={() => {
              gotoRecordAccount(row);
            }}
            sx={{
              justifyContent: 'flex-start' as any,
              width: '100%',
              textDecoration: 'none' as any,
              textTransform: 'none' as any,
            }}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'taxId',
      headerName: 'SSN/ID',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        return getAppConstant(ConstantsMappingKey.accountType, value);
      },
    },
    {
      field: 'accountNumber',
      headerName: headerAccountName,
      width: 156,
    },
    {
      field: 'uniqueId',
      headerName: 'Unique ID',
      width: 156,
    },  
    {
      disableExport: disableIRACols,
      field: 'iraContributionAmount',
      headerName: 'Traditional Regular Contributions',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      disableExport: disableIRACols,
      field: 'iraRothContributionAmount',
      headerName: 'Roth Regular Contributions',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      disableExport: disableIRACols,
      field: 'iraRolloverAmount',
      headerName: 'Rollover',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      disableExport: disableIRACols,
      field: 'iraRothConversionAmount',
      headerName: 'Roth Conversions',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      disableExport: disableIRACols,
      field: 'iraRecharacterizedAmount',
      headerName: 'Recharacterized Contributions',
      width: 156,
    },
    {
      disableExport: disableFMVCols,
      field: 'fmvAmount',
      headerName: 'FMV',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      disableExport: disableIRACols,
      field: 'iraSepAmount',
      headerName: 'SEP Contributions',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      disableExport: disableIRACols,
      field: 'iraSimpleAmount',
      headerName: 'SIMPLE Contributions',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      disableExport: disableIRACols,
      field: 'rmdDate',
      headerName: 'RMD date',
      width: 85,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        return value !== '' || value !== undefined ? '-' : value;
      },
    },
    {
      disableExport: disableIRACols,
      field: 'rmdAmount',
      headerName: 'RMD amount',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      disableExport: disableIRACols,
      field: 'postponedContributions',
      headerName: 'Postponed/late Contributions',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      disableExport: disableIRACols,
      field: 'postponedYear',
      headerName: 'Postponed Tax Year',
      width: 85,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        return value !== '' || value !== undefined ? value : '-';
      },
    },
    {
      disableExport: disableIRACols,
      field: 'postponedCode',
      headerName: 'Postponed Code',
      width: 156,
    },
    {
      disableExport: disableIRACols,
      field: 'repayments',
      headerName: 'Repayments',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      disableExport: disableIRACols,
      field: 'repaymentCode',
      headerName: 'Repayment Code',
      width: 156,
    },
    {
      disableExport: disableESACols,
      field: 'esaContributionAmount',
      headerName: 'ESA Regular Contributions',
      width: 200,
      valueFormatter: currencyValueFormatter,
    },
    {
      disableExport: disableESACols,
      field: 'esaRolloverAmount',
      headerName: 'ESA Rollover',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      disableExport: disableHSACols,
      field: 'hsaContributionAmount',
      headerName: 'HSA Regular Contributions',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      disableExport: disableHSACols,
      field: 'hsaPriorYearContributionAmount',
      headerName: 'HSA Prior Year Contributions',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      disableExport: disableHSACols,
      field: 'hsaRolloverAmount',
      headerName: 'HSA Rollover',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'corrected',
      headerName: 'Corrected Indicator',
      width: 124,
      valueFormatter: (params: GridCellParams) => {
        const { value = false } = params;
        if (value) {
          return 'X';
        }
        return ' ';
      },
      renderCell: (params: GridCellParams) => {
        const { value = false } = params;
        return (
          <>
            <Grid container justifyContent="center">
              {value ? <CheckIcon /> : ' - '}
            </Grid>
          </>
        );
      },
    },
    {
      field: 'dateSentToOwner',
      headerName: 'Date Sent to Owner',
      width: 172,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'dateFiledWithIrs',
      headerName: 'Date Filed with IRS',
      width: 172,
      valueFormatter: dateValueFormatter,
    },
  ] as GridColDef[];

  // Apply all filters to the supplied rows
  const applyFilters = (rowsToFilter) => {
    const matchingRows =
      taxFormTypeFilter.length > 0
        ? rowsToFilter.filter(({ formType }) =>
            taxFormTypeFilter.includes(formType)
          )
        : rowsToFilter;

    setFilteredRows(matchingRows);
  };

  const fetchSndSetTaxForm = async (values) => {
    setIsLoading(true);
    await getTax5498Form(
      String(getYear(values.taxYear)),
      user.organizationId,
      user.token,
      user
    )
      .then(({ data }) => {
        setRows(data);
        applyFilters(data);
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) || 'Error fetching multi-org beneficiary claims'
        );
      });

    if (isMounted) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    applyFilters(rows);
    if (taxFormTypeFilter.length > 0) {
      setDisableESACols(!taxFormTypeFilter.includes('ESA'));
      setDisableHSACols(!taxFormTypeFilter.includes('HSA'));
      setDisableIRACols(!taxFormTypeFilter.includes('IRA'));
      if (
        taxFormTypeFilter.includes('IRA') ||
        taxFormTypeFilter.includes('HSA')
      ) {
        setDisableFMVCols(false);
      }else{
        setDisableFMVCols(true);
      }
    }else{
      setDisableESACols(false);
      setDisableHSACols(false);
      setDisableIRACols(false);
      setDisableFMVCols(false);
    }
    return () => {
      isMounted = false;
    };
  }, [taxFormTypeFilter]);

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Tax Reporting
          </Typography>

          <Typography color="secondary" variant="h1" gutterBottom>
            5498 Tax Report
          </Typography>

          <Formik
            // Datepicker is only going to accept date in taxYear but we translate it to an integer on submit
            initialValues={{ taxYear: subYears(new Date(), 1), hasFmv: true }}
            onSubmit={fetchSndSetTaxForm}
            validationSchema={yup.object({
              taxYear: yup
                .date()
                .min(new Date('1900-01-01'), 'Choose a later year')
                .max(new Date(), 'Cannot be later than current year')
                .nullable()
                .required()
                .label('Tax Year'),
              hasFmv: yup.boolean(),
            })}
          >
            {({ isSubmitting, values, setFieldValue, errors }) => {
              return (
                <Form>
                  <Grid container spacing={2} alignItems="center" pt={1} pb={2}>
                    <Grid item xs={12} sm={7}>
                      {/* Year-only date viewer */}
                      <DatePicker
                        views={['year']}
                        label="Tax Year"
                        value={values.taxYear}
                        openTo='year'
                        onChange={(newValue) => {
                          setFieldValue('taxYear', newValue);
                        }}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: Boolean(errors.taxYear),
                            helperText: errors.taxYear ? String(errors.taxYear) : '',

                          }
                        }}            
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <Button
                        disabled={isSubmitting}
                        variant="contained"
                        color="secondary"
                        startIcon={<SearchOutlinedIcon />}
                        type="submit"
                      >
                        Search
                      </Button>
                    </Grid>

                    <Grid item xs={12} sm={7}>
                      <TaxReportingSelectField5498
                        onChange={setTaxFormTypeFilter}
                        taxFormTypes={taxFormTypeFilter}
                      />
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>

          {isLoading ? (
            <Box width="1">
              <LinearProgress />
            </Box>
          ) : (
            <DataGrid
              components={{
                NoRowsOverlay: SiraNoRowsOverlay,
                Toolbar: filteredRows.length > 0 &&  DataGridExportToolbar,
              }}
              disableColumnMenu
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
                columns: {
                  columnVisibilityModel: {
                    // Hide columns based on user role
                    hsaRolloverAmount: false,
                    hsaPriorYearContributionAmount: false,
                    hsaContributionAmount: false,
                    esaRolloverAmount: false,
                    esaContributionAmount: false,
                    repaymentCode: false,
                    repayments: false,
                    postponedCode: false,
                    postponedYear: false,
                    postponedContributions: false,
                    rmdDate: false,
                    rmdAmount: false,
                    iraSimpleAmount: false,
                    iraSepAmount: false,
                    fmvAmount: false,
                    iraRecharacterizedAmount: false,
                    iraRothConversionAmount: false,
                    iraRolloverAmount: false,
                    iraRothContributionAmount: false,
                    iraContributionAmount: false,
                    accountNumber: false,
                    uniqueId: false,
                    accountType: false,
                  },
                },
              }}
              pageSizeOptions={globalPaginationOptions}
              onPaginationModelChange={setGlobalPageSize}
              autoHeight
              columns={columns}
              rows={filteredRows.map((row, id) => ({
                ...row,
                id,
              }))}
            />
          )}
        </>
      </FormPaper>
    </Layout>
  );
}

export default TaxReport5498;

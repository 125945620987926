/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState } from 'react';
import { useTheme, Box, Paper, Button } from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColumnVisibilityModel,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { AccountOwnerSearchResult } from '../api/AccountOwnerApi.d';
import { useGlobalContext } from '../auth/useGlobalContext';
import SiraNoRowsOverlay from '../components/SiraNoRowsOverlay';

import ObscuredTaxId from '../components/ObscuredTaxId';
import { ConstantsMappingKey } from '../api/SharedTextApi.d';
import { useUser } from '../auth/useUser';
import { dateValueFormatter } from '../utils/DataGrid.utils';
import { getDashboardAccounts } from '../api/OrganizationApi';
import { AccountMemberValue } from '../api/OrganizationApi.d';

export enum TaxIdFormat {
  ssn = 'SSN',
  ein = 'EIN',
}

interface StoredSessionsData {
  userEmail: string;
  organization: string;
  accountIds: Array<string>;
}

interface AccountSavedSearchProps {
  onResultClick: Function;
  storedSession: Array<StoredSessionsData>;
}

interface SearchResultRow extends AccountOwnerSearchResult {
  id: number;
}

function resultRowsFactory(results): Array<SearchResultRow> {
  return results.map(({ account, accountOwner }, id) => ({
    ...account,
    ...accountOwner,
    id,
  }));
}

export function AccountSavedSearch(props: AccountSavedSearchProps) {
  const { storedSession, onResultClick = () => {} } = props;
  const theme = useTheme();
  const { user } = useUser();
  const { getAppConstant ,
    organization: { accountNumberValue,planNumberEnabled },
  } = useGlobalContext();
  const headerAccountName = accountNumberValue === AccountMemberValue.accountNumber ? 'Account #': 'Member #';
  const [columnVisibilityModel, setColumnVisibilityModel] =
  useState<GridColumnVisibilityModel>({
    // Hide columns based on user role
    planNumber: true,

  });
  const [rows, setRows] = useState([] as Array<SearchResultRow>);

  const classes = {
    accountLink: {
      justifyContent: 'flex-start' as any,
      width: '100%',
      textDecoration: 'none' as any,
      textTransform: 'none' as any,
    },
    buttonBar: {
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
    },
  };

  // Get the accountIds that were recently selected during the user's last saved session within the current org ID
  const { accountIds = [] } =
    (storedSession || []).find(
      ({ organization, userEmail }) =>
        organization === user.organizationId && user.email === userEmail
    ) || {};

  // Fetch the full account/owner info for each session account ID to show in the table
  const fetchAndSetAccountResultsData = async () => {
    if (accountIds.length) {
      await getDashboardAccounts(
        user.organizationId,
        [],
        user.token,
        accountIds
      ).then(({ data = [] }) => {
        setRows(resultRowsFactory(data));
      });
    }
  };

  const columns = [
    {
      field: 'fullName',
      headerName: 'Name',
      width: 175,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            data-qa="account-search-result-link"
            onClick={() => {
              onResultClick(row);
            }}
            sx={classes.accountLink}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'taxpayerIdNumber',
      headerName: 'SSN/ID',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
    {
      field: 'dateOfBirth',
      headerName: 'Birth Date',
      width: 126,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 156,
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        const typeId = value.toString();

        return getAppConstant(ConstantsMappingKey.accountType, typeId);
      },
    },
    {
      field: 'accountNumber',
      headerName: headerAccountName,
      width: 100,
      sortable: false,
    },
    {
      field: 'planNumber',
      headerName: 'Plan Number',
      width: 100,
      hide:planNumberEnabled
    }
  ];


  useEffect(() => {
    if (user.token && user.organizationId) {
      fetchAndSetAccountResultsData();
    }
  }, [user.token, user.organizationId]);

  return (
    <>
      <Paper elevation={3}>
        <Box p={2}>
          <Box mt={4}>
            <DataGrid
              components={{
                NoRowsOverlay: SiraNoRowsOverlay,
              }}
              columnVisibilityModel={{
                planNumber: planNumberEnabled
              }}
              disableColumnMenu
              autoHeight
              columns={columns}
              rows={rows}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[10]}
            />
          </Box>
        </Box>
      </Paper>
    </>
  );
}

export default AccountSavedSearch;

import React, { useState, useEffect } from 'react';
import { Alert, Box, Typography, Grid, Button, Paper } from '@mui/material';
import {
  formatISO,
  getYear,
  parseISO,
} from 'date-fns';
import FormPaper from '../components/FormPaper';
import { AccountOwner } from '../api/AccountOwnerApi.d';
import {
  addTransactionData,
  setSelectedAccount,
  useTransactionReducer,
} from './TransactionReducer';
import { useUser } from '../auth/useUser';
import {
  DISTRIBUTION_AMOUNT_INIT,
} from '../components/form/distribution/DistributionAmountForm';
import { getAccountOwner } from '../api/AccountOwnerApi';
import {
  createOrSaveRecurringDistribution,
} from '../api/RecurringDistributionApi';
import {
  RecurringDistribution,
  TermChoiceOption,
} from '../api/RecurringDistributionApi.d';
import {
  DistributionStatus,
  Distribution,
} from '../api/DistributionApi.d';
import { getAccount } from '../api/AccountApi';
import { useGlobalContext } from '../auth/useGlobalContext';
import SelectDistributionForm from '../components/form/distribution/SelectDistributionForm';
import TransactionStepper, { TransactionStep } from './TransactionStepper';
import { errorMessages } from '../utils/errorhandling.utils';
import { getAccountDistributions } from '../api/DistributionApi';
import { getLifeExpectancyTerms } from '../api/LifeExpectancyApi';
import DistributionWithholdingEditForm from '../components/form/distribution/DistributionEditWithholding';


function ModalRecurringDistribution(props) {
  let isMounted = true;
  const { rowData, editActive, closeDialog } = props;
  const { accountId, accountOwnerId } = rowData;
  const { user } = useUser();
  const [accountOwner, setAccountOwner] = useState({} as AccountOwner);
  const [isLoading, setIsLoading] = useState(false);
  const [parentRecurringDistribution, setParentRecurringDistribution] =
    useState({} as RecurringDistribution);
  const [pageState] = useTransactionReducer();

  const [activeStep, setActiveStep] = useState<number>(0);

  const { organization, addGlobalMessage } = useGlobalContext();

  const [activeDistribution, setActiveDistribution] = useState({} as any);

  const [initialTermInfo, setInitialTermInfo] = useState({});

  const [scheduledDists, setScheduledDists] = useState(
    [] as Array<Distribution>,
  );

  // Only show state withholding when an org has at least one state that supports it
  const showStateWithholding =
    organization.stateTaxIDS &&
    organization.stateTaxIDS.some(
      ({ stateWithholding = false }) => stateWithholding,
    );

  const saveDistributionInfo = (data: any) => {
   // Map data to the sample fields
   const distributionInformation = {
    federalWithholdingPercent: data.federalWithholdingPercent ,
    signedDate: data.effectiveDate ,
    startDate: data.effectiveDate ,
    effectiveDate: data.effectiveDate ,
    withholdingState: data.withholdingState ,
    distributionStatus: DistributionStatus.active,
    stateWithholdingPercent: data.stateWithholdingPercent,
    toAccountNumber: data.toAccountNumber,
    lifeExpectancyTable: data.lifeExpectancyTable,
    distributionAmountType: data.distributionAmountType,
    distributionMethod: data.distributionMethod ,
    distributionReason: data.distributionReason,
    toAccountType: data.toAccountType,
    activeDate: data.effectiveDate,
    totalAmount: data.totalAmount,
    frequency: data.frequency,
    parentRecurringDistributionId: data.recurringDistributionId,
    ownerResponsible: data.ownerResponsible,
  };


    mergeAndSaveDistribution(distributionInformation);
  };

  const mergeAndSaveDistribution = async (
    data: any
  ): Promise<void> => {
    setIsLoading(true);


    await createOrSaveRecurringDistribution(
      data, // Here we use a factory to produce the distribution
      pageState.selectedAccount.accountId,
      pageState.selectedAccount.accountOwnerId,
      user.organizationId,
      user.token,
      user
    )
      .then((res) => {
        if (isMounted) {
          setIsLoading(false);
          // close the modal
          closeDialog();
          // Might need to save more info here
          addGlobalMessage('Withholding Changes saved successfully', 'success');
        }

      })
      .catch((err) => {

        setIsLoading(false);
        addGlobalMessage(errorMessages(err));
      });
  };

  const copyParentDistribution = (
    distributionInformation: RecurringDistribution,
    advanceStep: boolean,
  ) => {
    const newRecurringDist = {
      ...distributionInformation,
      termChoice: TermChoiceOption.empty,
      startDate: formatISO(new Date()).slice(0, 10),
      distributionStatus: DistributionStatus.pending,
      recurringDistributionId: distributionInformation.recurringDistributionId,
    };


    setParentRecurringDistribution(distributionInformation);
    fetchAndSetScheduledDistributions();
    setActiveDistribution(distributionInformation);
    if (advanceStep) {
      setActiveStep(1);
    }
  };

  // Get the accountOwner for the account loaded
  async function updateAccountOwner(): Promise<void> {
    setIsLoading(true);

    await getAccountOwner(accountOwnerId, user.organizationId, user.token, user)
      .then((res) => {
        if (isMounted) {
          setIsLoading(false);
          setAccountOwner(res.data);
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsLoading(false);
        }
      });
  }

  // Get life expectancy for current owner
  async function fetchLifeExpectancy() {
    if (accountId) {
      await getLifeExpectancyTerms(
        user.organizationId,
        accountId,
        accountOwnerId,
        '',
        '',
        user.token,
        user,
      )
        .then((response) => {
          const { primaryBeneficiaries = [], fairMarketValue } = response.data;

          if (isMounted) {
            if (fairMarketValue) {
              setInitialTermInfo({
                fairMarketValue: fairMarketValue.fairMarketValue,
                fmvInDb: true,
              });
            }
          }
        })
        .catch((err) => {
          addGlobalMessage(
            errorMessages(err) ||
              'Unexpected problem loading Amortization Calculation',
          );
        });
    }
  }

  // Look up account and set it selected with query params passed
  async function fetchAndSetAccount(): Promise<void> {
    await getAccount(
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
      user,
    )
      .then((res) => {
        if (isMounted) {
          setSelectedAccount(res.data);
          updateAccountOwner();
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
          addGlobalMessage(
            errorMessages(err) || 'Could not fetch the preselected account',
          );
        }
      });
  }

  const saveCalculatedInfo = (taxCalculatedInfo) => {
    addTransactionData({ taxAmounts: taxCalculatedInfo }, false);
  };

  // Get and set the account's scheduled distributions for the year
  async function fetchAndSetScheduledDistributions() {
    await getAccountDistributions(
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
      user,
    )
      .then(({ data }) => {
        if (isMounted) {
          const currentYearScheduledDistributions = data.filter(
            ({ scheduledDistributionId, effectiveDate }) => {
              return (
                Boolean(scheduledDistributionId) &&
                getYear(new Date()) === getYear(parseISO(effectiveDate))
              );
            },
          );

          setScheduledDists(currentYearScheduledDistributions);
          fetchLifeExpectancy();
        }
      })
      .catch(() => {
        if (isMounted) {
          setScheduledDists([]);
        }
      });
  }

  const RecurringDistributionSteps: Array<TransactionStep> = [
    {
      label: 'Select Distribution',
      stepContent: (
        <Box width="1" mt={4} mb={4}>
          <SelectDistributionForm
            account={pageState.selectedAccount}
            onSelect={copyParentDistribution}
          />
        </Box>
      ),
    },
    {
      label: 'Provide Distribution Amounts',
      stepContent: (
        <Box mt={5} mb={3}>
          <DistributionWithholdingEditForm
            account={pageState.selectedAccount}
            accountOwner={accountOwner}
            onSubmit={(values) => saveDistributionInfo(values)}
            initialValues={{
              ...DISTRIBUTION_AMOUNT_INIT,
              ...initialTermInfo,
              ...rowData,
              ...activeDistribution
            }}
            parentDistribution={parentRecurringDistribution}
            term={pageState.distributionTermInformation}
            saveCalculatedInfo={saveCalculatedInfo}
            recurringDistribution
            showWithholding
            showStateWithholding={showStateWithholding}
          />
        </Box>
      ),
    }
  ];

  useEffect(() => {
    if (user.token || user.organizationId) {
      fetchAndSetAccount();
    }

    return () => {
      isMounted = false;
    };
  }, [user.token, user.organizationId]);

  return (
    <FormPaper>
      <>
        <Typography
          data-qa="recurringDistribution_header"
          color="secondary"
          variant="h1"
          gutterBottom
        >
          Edit Recurring Distribution
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Find the account owner and select the correct account for which to{' '}
          {editActive ? 'edit' : 'add'} a recurring distribution (to establish a
          schedule for required minimum distributions, substantially equal
          periodic payments, beneficiary distributions, etc.).
        </Typography>
        <Box mt={5}>
          <TransactionStepper
            steps={RecurringDistributionSteps}
            activeStep={activeStep}
            isLoading={isLoading}
            onStepClick={(index) => {
              setActiveStep(index);
            }}
          />
        </Box>
      </>
    </FormPaper>
  );
}

export default ModalRecurringDistribution;

import React from 'react';
import {
  Box,
  Divider,
  Modal,
  ModalProps,
  Paper,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

interface SiraModalProps extends ModalProps {
  handleClose: Function;
  title?: string;
}
  const useStyles: any = makeStyles(() => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    resizableModal: {
      width: '50%',
      height: '50%',
      minWidth: '200px',
      minHeight: '200px',
      resize: 'both',
      overflow: 'auto',
      outline: 'none',    
      bgcolor: 'white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: 2,
    padding: 8,
    },
  }));
function SiraTaxFormCorrectionModal(props: SiraModalProps) {
  const classes = useStyles();
  const { children, open, handleClose = () => {}, title = '' } = props;

  const contentStyle = {
    height: '100%',
    padding: '16px',
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modal}
      >
        <Paper className={classes.resizableModal}>
            <Box sx={contentStyle}>
              {title && (
                <>
                  <Typography variant="h6" component="h2">
                    {title}
                  </Typography>
                  <Box pt={2} pb={2}>
                    <Divider />
                  </Box>
                </>
              )}
              {children}
            </Box>
        </Paper>
      </Modal>
    </>
  );
}

export default SiraTaxFormCorrectionModal;

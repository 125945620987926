import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import * as yup from 'yup';
import { Formik, Form, useFormikContext, Field } from 'formik';
import { add, startOfDay } from 'date-fns';

import {
  TransferRequest,
  TransferRequestOccur,
  TransferRequestRelationship,
} from '../../../api/TransferApi.d';
import StepButtonBar from '../../steps/StepButtonBar';
import SiraSwitchField from '../SiraSwitchField';
import SiraCurrencyField from '../SiraCurrencyField';
import SiraDateField, { dateValidation } from '../SiraDateField';
import SiraRadioField from '../SiraRadioField';
import SiraTextField from '../SiraTextField';
import { AccountType } from '../../../api/AccountApi.d';
import { determineAgeGroup } from '../../../app.constants';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';

export const TRANSFER_INSTRUCTIONS_INIT: TransferRequest = {
  entireAccount: true,
  transferAmount: '',
  rolloverTransferOccurValue: '' as TransferRequestOccur,
  transferDate: '',
  transferDateImmediately: false,
  transferDateInvestmentMaturity: false,
  additionalDetails: '',
};

export const TRANSFER_INSTRUCTIONS_SCHEMA = yup.object({
  entireAccount: yup.boolean(),
  transferAmount: yup
    .string()
    .label('Amount')
    .when('entireAccount', (entireAccount, schema) => {
      return entireAccount ? schema : schema.required();
    }),
  transferDate: yup
    .string()
    .label('Specific Date')
    .when(
      'rolloverTransferOccurValue',
      (rolloverTransferOccurValue, schema) => {
        return [TransferRequestOccur.specificDate].includes(
          rolloverTransferOccurValue,
        )
          ? dateValidation(
              startOfDay(new Date()),
              add(new Date(), { years: 1 }),
            ).required()
          : schema;
      },
    ),
  transferDateImmediately: yup.boolean(),
  transferDateInvestmentMaturity: yup.boolean(),
});

export interface TransferSourceFormProps {
  initialValues: TransferRequest;
  setAllocations?: Function;
  onSubmit?: Function;
  onReset?: Function;
  submitName?: string;
  resetName?: string;
  accountOwner?: AccountOwner;
}

// Self-clearing option to drain accoutn on transfer and close
function TransferEntireAccountOption(props) {
  const { setAllocations } = props;
  const { values, setValues } = useFormikContext();
  const { entireAccount } = values as TransferRequest;

  useEffect(() => {
    setAllocations([]);
    if (entireAccount) {
      setValues({
        ...(values as TransferRequest),
        transferAmount: '',
      });
    }
  }, [entireAccount]);

  return (
    <>
      {!entireAccount && (
        <>
          <Grid item xs={12} md={6}>
            <SiraCurrencyField name="transferAmount" label="Specify Amount" />
          </Grid>
        </>
      )}
    </>
  );
}

// Options to select when transfer will occur
function TransferOccurField() {
  const { values, setValues } = useFormikContext<TransferRequest>();
  const { rolloverTransferOccurValue } = values;

  const rollOverTransferOccurOptions = [
    {
      value: TransferRequestOccur.immediately,
      label: 'Immediately',
    },
    {
      value: TransferRequestOccur.specificDate,
      label: 'On a specific date',
      afterContent: <SiraDateField name="transferDate" label="Specific Date" />,
    },
    {
      value: TransferRequestOccur.investmentMaturity,
      label: 'Upon investment maturity',
    },
  ];

  // This would map better as an enum, but we assign the booleans here based on selection
  function handleOccurOptionSelect(): void {
    setValues({
      ...values,
      transferDate:
        rolloverTransferOccurValue !== TransferRequestOccur.specificDate && '',
      transferDateImmediately:
        rolloverTransferOccurValue === TransferRequestOccur.immediately,
      transferDateInvestmentMaturity:
        rolloverTransferOccurValue === TransferRequestOccur.investmentMaturity,
    });
  }

  useEffect(() => {
    handleOccurOptionSelect();
  }, [rolloverTransferOccurValue]);

  return (
    <Field
      name="rolloverTransferOccurValue"
      options={rollOverTransferOccurOptions}
      component={SiraRadioField}
    />
  );
}

function TransferInstructionsForm({
  setAllocations = () => {},
  initialValues,
  onSubmit = () => {},
  onReset = () => {},
  submitName,
  resetName,
  accountOwner,
}: TransferSourceFormProps) {
  const { dateOfBirth } = accountOwner;
  const { turning73ThisYear, currentlyOrOver73 } =
    determineAgeGroup(dateOfBirth);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={TRANSFER_INSTRUCTIONS_SCHEMA}
      onSubmit={async (data) => {
        await onSubmit(data);
      }}
      onReset={() => {
        onReset();
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => {
        useEffect(() => {
          if (values.sourceAccountType === AccountType.employer) {
            if (
              values.relationshipToCurrentOwner ===
                TransferRequestRelationship.currentOwner &&
              (turning73ThisYear || currentlyOrOver73)
            ) {
              setFieldValue(
                'additionalDetails',
                'Distribute any remaining RMD from this employer-sponsored retirement plan prior to this direct rollover.',
              );
            }

            if (
              values.relationshipToCurrentOwner !==
              TransferRequestRelationship.currentOwner
            ) {
              setFieldValue(
                'additionalDetails',
                'Distribute any remaining RMD from this employer-sponsored retirement plan prior to this direct rollover.',
              );
            }
          }
        }, [values.sourceAccountType]);
        return (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  Are you withdrawing the entire balance from the current
                  account?
                </Typography>
                <Typography component="div">
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                    wrap="nowrap"
                  >
                    <Grid item>No</Grid>
                    <Grid item>
                      <SiraSwitchField
                        checked={values.entireAccount}
                        name="entireAccount"
                      />
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  When should the rollover/transfer occur?
                </Typography>
                <Typography component="div">
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                    wrap="nowrap"
                  >
                    <Grid item>
                      <TransferOccurField />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <SiraTextField
                  name="additionalDetails"
                  label="Additional Details"
                  multiline
                  rows={4}
                  fullWidth
                />
              </Grid>
              <TransferEntireAccountOption setAllocations={setAllocations} />
            </Grid>
            <StepButtonBar
              isSubmitting={isSubmitting}
              submitName={submitName}
              resetName={resetName}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default TransferInstructionsForm;

import React, { useEffect, useState } from 'react';
import {
  useTheme,
  Box,
  Paper,
  Typography,
  Button,
  Grid,
  Popover,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BlockIcon from '@mui/icons-material/Block';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGridPro, GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';
import AccountNewIcon from '../../../icons/AccountNew';

import {
  AccountOwner,
  AccountOwnerSearchResponse,
  AccountOwnerSearchResult,
} from '../../../api/AccountOwnerApi.d';
import { Account, AccountStatus } from '../../../api/AccountApi.d';
import { useUser } from '../../../auth/useUser';
import ObscuredTaxId, { TaxIdFormat } from '../../ObscuredTaxId';
import PluralizedString from '../../PluralizedString';
import TransactionSignatureIcon from '../../../icons/TransactionSignature';
import TransactionPendingIcon from '../../../icons/TransactionPending';
import TransactionReviewIcon from '../../../icons/TransactionReview';

import SiraNoRowsOverlay from '../../SiraNoRowsOverlay';
import { exportAccounts } from '../../../api/AccountApi';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../../auth/useGlobalContext';
import { ConstantsMappingKey } from '../../../api/SharedTextApi.d';
import { globalPaginationOptions } from '../../../app.constants';
import MultiButton, { MultiButtonAction } from '../../MultiButton';
import { AccountMemberValue } from '../../../api/OrganizationApi.d';
import { GridColumnVisibilityModel } from '@mui/x-data-grid';
import { use } from 'chai';

interface AccountSearchResultsProps {
  onResultClick: Function;
  response: AccountOwnerSearchResponse;
  showImportAccounts?: boolean;
  filterFunction?: (value: AccountOwnerSearchResult) => void;
}

interface SearchResultRow extends Account, AccountOwner {
  id: number;
  communityPropertyState: boolean;
}

export function AccountSearchResults(props: AccountSearchResultsProps) {
  const {
    response = {},
    onResultClick = () => {},
    showImportAccounts = false,
    filterFunction,
  } = props;
  const { data = [], query = '' } = response as AccountOwnerSearchResponse;
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useUser();
  const { getAppConstant, addGlobalMessage, organization } = useGlobalContext();
  const { accountNumberValue } = organization;
  const headerAccountName =
    accountNumberValue === AccountMemberValue.accountNumber
      ? 'Account #'
      : 'Member #';
  const { setGlobalPageSize, globalPageSize } = usePaginationContext();
  const [isLoading, setIsLoading] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      // Hide columns based on user role
      planNumber: true,
    });
  const classes = {
    accountLink: {
      justifyContent: 'flex-start' as any,
      width: '100%',
      textDecoration: 'none' as any,
      textTransform: 'none' as any,
    },
    buttonBar: {
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
    },
  };

  const results = filterFunction
    ? data.filter((value: AccountOwnerSearchResult) => filterFunction(value))
    : data;

  const goToAddAccounts = (): void => {
    navigate(`/dataImport/newAccounts`);
  };

  const goToAddNewAccount = (): void => {
    navigate(`/transactions/newAccount`);
  };

  const exportOrgAccounts = async (fileType): Promise<any> => {
    setIsLoading(true);
    await exportAccounts(user.organizationId, user.token, fileType)
      .then(() => {
        addGlobalMessage(
          'Your file will be available shortly under Notifications, located at the top of the page.',
          {
            severity: 'success',
          },
        );
      })
      .catch(() => {
        addGlobalMessage('Error exporting organization accounts');
      });
    setIsLoading(false);
  };

  // Define the download types
  const downloadActions: Array<MultiButtonAction> = [
    {
      label: 'Download *.tsv',
      handleAction: () => exportOrgAccounts('tsv'),
      icon: <DownloadIcon />,
    },
    {
      label: 'Download *.xlsx',
      handleAction: () => exportOrgAccounts('xlsx'),
      icon: <DownloadIcon />,
    },
  ];


  const columns: GridColDef[] = [
    {
      field: 'accountStatus',
      headerName: ' ',
      width: 0,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        const statusIcons = {
          [AccountStatus.open]: <AssignmentTurnedInIcon />,
          [AccountStatus.closed]: <BlockIcon />,
          [AccountStatus.pending]: <TransactionPendingIcon />,
          [AccountStatus.signature]: <TransactionSignatureIcon />,
          [AccountStatus.review]: <TransactionReviewIcon />,
        };
        const [rolesAnchorEl, setRolesAnchorEl] =
          useState<HTMLButtonElement | null>(null);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          setRolesAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
          setRolesAnchorEl(null);
        };

        return (
          <>
            <Grid container alignItems="center" justifyContent="center">
              <IconButton
                color="primary"
                onClick={handleClick}
                aria-label={`account status ${value}`}
                size="large"
              >
                {statusIcons[value.toString()]}
              </IconButton>
            </Grid>
            <Popover
              elevation={3}
              open={Boolean(rolesAnchorEl)}
              onClose={handleClose}
              anchorEl={rolesAnchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
            >
              <Box p={2}>
                <Typography variant="body2">
                  Status: {value.toString()}
                </Typography>
              </Box>
            </Popover>
          </>
        );
      },
    },
    {
      field: 'fullName',
      headerName: 'Name',
      width: 175,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            data-qa="account-search-result-link"
            onClick={() => {
              onResultClick(row);
            }}
            sx={classes.accountLink}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'taxpayerIdNumber',
      headerName: 'SSN/ID',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
    {
      field: 'dateOfBirth',
      headerName: 'Birth Date',
      width: 100,
      valueGetter: (value) => {
        const time = value as string;
        if (time && !Number.isNaN(Date.parse(time))) {
          return format(parseISO(time), 'MM/dd/yyyy');
        }
        return '';
      },
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 156,
      valueGetter: (value) => {
        const typeId = value;

        return getAppConstant(ConstantsMappingKey.accountType, typeId);
      },
    },
    {
      field: 'accountNumber',
      headerName: headerAccountName,
      width: 100,
      sortable: false,
    },
    {
      field: 'planNumber',
      headerName: 'Plan Number',
      width: 100,
    },
    {
      field: 'dateOfDeath',
      headerName: 'Date of Death',
      width: 126,
      valueGetter: (value) => {
        const time = value as string;
        if (time && !Number.isNaN(Date.parse(time))) {
          return format(parseISO(time), 'MM/dd/yyyy');
        }
        return '';
      },
    },
  ];

  function resultRowFactory(
    resultRow: AccountOwnerSearchResult,
    index: number,
  ): SearchResultRow {
    return {
      ...resultRow.account,
      ...resultRow.accountOwner,
      id: index,
      communityPropertyState: false,
    };
  }

  const rows = results.map((resultRow, index) =>
    resultRowFactory(resultRow, index),
  );

  return (
    <>
      <Box mb={4}>
        <Typography variant="body1">Showing results for</Typography>
        <Typography variant="h5">&quot;{query}&quot;</Typography>
      </Box>

      <Paper elevation={3}>
        <Box p={2}>
          <Grid container wrap="nowrap" spacing={2} sx={classes.buttonBar}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {results.length}{' '}
                <PluralizedString noun="account" quantity={results.length} />{' '}
                found
              </Typography>
            </Grid>
            {showImportAccounts && (
              <>
                <Grid item>
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="contained"
                    color="primary"
                    startIcon={<AccountNewIcon />}
                    onClick={goToAddNewAccount}
                  >
                    Add New Account
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="contained"
                    color="primary"
                    startIcon={<FileUploadIcon />}
                    onClick={goToAddAccounts}
                  >
                    Import Accounts
                  </Button>
                </Grid>
                <Grid item>
                  <MultiButton
                    actions={downloadActions}
                    sx={{ whiteSpace: 'nowrap' }}
                    disabled={isLoading}
                    variant="contained"
                    color="primary"
                    startIcon={<DownloadIcon />}
                    endIcon={<ExpandMoreIcon />}
                  >
                    Export Accounts
                  </MultiButton>
                </Grid>
              </>
            )}
          </Grid>

          <Box mt={4}>
            <DataGridPro
              columnVisibilityModel={{
                planNumber: organization.planNumberEnabled,
              }}
              slots={{
                noRowsOverlay: SiraNoRowsOverlay,
              }}
              initialState={{
                pagination: { paginationModel: { pageSize: globalPageSize } },
              }}
              pagination
              pageSizeOptions={globalPaginationOptions}
              onPaginationModelChange={setGlobalPageSize}
              autoHeight
              columns={columns}
              rows={rows}
            />
          </Box>
        </Box>
      </Paper>
    </>
  );
}

export default AccountSearchResults;

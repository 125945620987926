import {
  DepositCheckPayee,
  DepositMethod,
} from '../../../api/ContributionApi.d';
import { AccountType } from '../../../api/AccountApi.d';
import { RadioGroupOption } from '../SiraRadioField';
import { ConstantsMappingKey } from '../../../api/SharedTextApi.d';

const getFromAccountTypes = (
  getAppConstant: Function,
  toAccountType: AccountType,
  depositMethod: DepositMethod,
  payeeOption: DepositCheckPayee,
  esaDepositorIsResponsibleIndividual: boolean,
): Array<RadioGroupOption> => {
  let accountTypeOptions = [] as Array<RadioGroupOption>;

  const traditionalIraOption: RadioGroupOption = {
    value: AccountType.traditionalIra,
    label: getAppConstant(
      ConstantsMappingKey.accountType,
      AccountType.traditionalIra,
    ),
  };

  const inheritedTraditionalOption: RadioGroupOption = {
    value: AccountType.inheritedTraditional,
    label: getAppConstant(
      ConstantsMappingKey.accountType,
      AccountType.inheritedTraditional,
    ),
  };

  const sepIraOption: RadioGroupOption = {
    value: AccountType.traditionalSep,
    label: getAppConstant(
      ConstantsMappingKey.accountType,
      AccountType.traditionalSep,
    ),
  };

  const simpleIraOption: RadioGroupOption = {
    value: AccountType.simpleIra,
    label: getAppConstant(
      ConstantsMappingKey.accountType,
      AccountType.simpleIra,
    ),
  };

  const rothIraOption: RadioGroupOption = {
    value: AccountType.rothIra,
    label: getAppConstant(ConstantsMappingKey.accountType, AccountType.rothIra),
  };

  const otherNonRetirementOption: RadioGroupOption = {
    value: AccountType.other,
    label:'Any non-retirement account (e.g., checking, savings, money market)',
  };

  const employerSponsoredOption: RadioGroupOption = {
    value: AccountType.employer,
    label: 'Employer-sponsored retirement plan',
    info: 'Any qualified retirement plan (e.g., 401(k), profit sharing plan, governmental 457(b) plan), SEP, or SIMPLE plan.',
    infoAria: 'Info Button for Employer Sponsored',
  };

  function getAccountTypesEsa(): Array<RadioGroupOption> {
    if (
      [
        DepositMethod.check,
        DepositMethod.transfer,
        DepositMethod.direct,
      ].includes(depositMethod)
    ) {
      accountTypeOptions.push({
        value: AccountType.other,
        label: 'Any non-education account (e.g., checking, savings, money market)',
      });

      if (esaDepositorIsResponsibleIndividual) {
        accountTypeOptions.push({
          value: AccountType.esa,
          label: getAppConstant(
            ConstantsMappingKey.accountType,
            AccountType.esa,
          ),
        });
      }
    }
    return accountTypeOptions;
  }

  function getAccountTypesHsa(): Array<RadioGroupOption> {
    const isCheckToOwner =
      depositMethod === DepositMethod.check &&
      payeeOption === DepositCheckPayee.owner;

    accountTypeOptions.push(
      {
        value: AccountType.other,
        label: 'Any non-health account (e.g., checking, savings, money market)',
      },
      {
        value: AccountType.hsa,
        label: getAppConstant(ConstantsMappingKey.accountType, AccountType.hsa),
      },
    );

    if (!isCheckToOwner) {
      accountTypeOptions.push(
        {
          value: AccountType.traditionalIra,
          label: 'IRA',
        },
        {
          value: AccountType.msa,
          label: 'Archer MSA',
        },
      );
    }

    return accountTypeOptions;
  }

  if ([AccountType.inheritedTraditional].includes(toAccountType)) {
    accountTypeOptions.push(
      traditionalIraOption,
      sepIraOption,
      simpleIraOption,
      inheritedTraditionalOption,
      employerSponsoredOption,
    );
  }

  if ([AccountType.inheritedRoth].includes(toAccountType)) {
    accountTypeOptions.push(
      rothIraOption,
      traditionalIraOption,
      employerSponsoredOption,
    );
  }

  if ([AccountType.esa].includes(toAccountType)) {
    return getAccountTypesEsa();
  }

  if ([AccountType.hsa].includes(toAccountType)) {
    return getAccountTypesHsa();
  }

  if ([DepositMethod.check].includes(depositMethod)) {
    if ([AccountType.traditionalIra].includes(toAccountType)) {
      if (payeeOption === DepositCheckPayee.owner) {
        accountTypeOptions.push(
          otherNonRetirementOption,
          traditionalIraOption,
          employerSponsoredOption,
        );
      } else if (payeeOption === DepositCheckPayee.org) {
        accountTypeOptions.push(
          otherNonRetirementOption,
          traditionalIraOption,
          rothIraOption,
          employerSponsoredOption,
        );
      }
    }

    if ([AccountType.rothIra].includes(toAccountType)) {
      accountTypeOptions.push(
        otherNonRetirementOption,
        traditionalIraOption,
        rothIraOption,
        employerSponsoredOption,
      );
    }

    if ([AccountType.simpleIra].includes(toAccountType)) {
      accountTypeOptions.push(
        otherNonRetirementOption,
        traditionalIraOption,
        simpleIraOption,
        employerSponsoredOption,
      );
    }

    if (
      toAccountType === AccountType.traditionalSep &&
      depositMethod === DepositMethod.transfer
    ) {
      accountTypeOptions.push(sepIraOption, employerSponsoredOption);
    }

    if ([AccountType.traditionalSep].includes(toAccountType)) {
      if (payeeOption === DepositCheckPayee.owner) {
        accountTypeOptions.push(
          otherNonRetirementOption,
          traditionalIraOption,
          employerSponsoredOption,
        );
      } else if (payeeOption === DepositCheckPayee.org) {
        accountTypeOptions.push(
          otherNonRetirementOption,
          sepIraOption,
          rothIraOption,
          employerSponsoredOption,
        );
      }
    }
  } else if (
    ![AccountType.inheritedRoth, AccountType.inheritedTraditional].includes(
      toAccountType,
    )
  ) {
    accountTypeOptions = [];
    // If the deposit method is not check, then we can assume it is either transfer or direct
    // and we can show all account types except inherited roth and inherited traditional
    accountTypeOptions.push(traditionalIraOption);

    const accountTypeOptionsMap = {
      [DepositMethod.transfer]: {
        [AccountType.simpleIra]: [employerSponsoredOption, simpleIraOption],
        [AccountType.traditionalSep]: [employerSponsoredOption, sepIraOption],
        [AccountType.traditionalIra]: [employerSponsoredOption],
        [AccountType.rothIra]: [employerSponsoredOption],
      },
      [DepositMethod.direct]: {
        [AccountType.simpleIra]: [simpleIraOption],
        [AccountType.traditionalSep]: [sepIraOption],
      },
    };

    // if it is a simp or sep add rothirs
    if (
      ![AccountType.simpleIra, AccountType.traditionalSep].includes(
        toAccountType,
      )
    ) {
      accountTypeOptions.push(rothIraOption);
    }

    const options = accountTypeOptionsMap[depositMethod]?.[toAccountType] || [];
    accountTypeOptions.push(otherNonRetirementOption);
    accountTypeOptions.push(...options);
  }
  // if the accounttypeoptions has a lable:other in it, move it to the position one 

  const otherIndex = accountTypeOptions.findIndex(
    (option) => option.value === AccountType.other,
  );
  if (otherIndex > -1) {
    const otherOption = accountTypeOptions.splice(otherIndex, 1);
    accountTypeOptions.unshift(otherOption[0]);
  }
  
  return accountTypeOptions;
};

export default getFromAccountTypes;

import React, { useEffect, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import {
  addYears,
  endOfYear,
  formatISO,
  isValid,
  parseISO,
  startOfYear,
  format,
} from 'date-fns';
import { Cell, Pie, PieChart } from 'recharts';
import {
  Grid,
  Box,
  Typography,
  Divider,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  DataGrid,
  GridCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';

import { useUser } from '../auth/useUser';
import {
  useGlobalContext,
  usePaginationContext,
} from '../auth/useGlobalContext';
import {
  Account,
  AccountStatus,
  AccountSupportedTypestring,
  AccountType,
} from '../api/AccountApi.d';
import { ConstantsMappingKey } from '../api/SharedTextApi.d';
import { AccountOwner } from '../api/AccountOwnerApi.d';
import { globalPaginationOptions } from '../app.constants';
import { dateValidation } from '../components/form/SiraDateField';
import SiraDateRangeField from '../components/form/SiraDateRangeField';
import Layout from '../components/Layout';
import FormPaper from '../components/FormPaper';
import { dateValueFormatter } from '../utils/DataGrid.utils';
import SiraNoRowsOverlay from '../components/SiraNoRowsOverlay';
import DataGridExportToolbar from '../components/DataGridExportToolbar';
import ObscuredTaxId, { TaxIdFormat } from '../components/ObscuredTaxId';
import ResponsivePercentBar, {
  ResponsivePercentBarProps,
} from '../components/reports/ResponsivePercentBar';
import { AccountMemberValue } from '../api/OrganizationApi.d';
import {
  getAccountSummaryDashboards,
  getDashboardAccountContents,
} from '../api/OrganizationApi';
import { errorMessages } from '../utils/errorhandling.utils';


interface AccountSummaryDataArray {
  accountsOpened: Array<Account & AccountOwner>;
  accountsClosed: Array<Account & AccountOwner>;
  totalOpenAccounts: Array<Account & AccountOwner>;
  tradIrasUnder73: Array<Account & AccountOwner>;
  tradIras73OrOlder: Array<Account & AccountOwner>;
  rothFiveYearTestIsTrue: Array<Account & AccountOwner>;
  rothFiveYearTestIsFalse: Array<Account & AccountOwner>;
  sepUnder73: Array<Account & AccountOwner>;
  sep73OrOlder: Array<Account & AccountOwner>;
  simpleUnder73: Array<Account & AccountOwner>;
  simple73OrOlder: Array<Account & AccountOwner>;
  inheritedRoth5YearTrue: Array<Account & AccountOwner>;
  inheritedRoth5YearFalse: Array<Account & AccountOwner>;
  coverdalUnder18: Array<Account & AccountOwner>;
  coverdal18OrOver: Array<Account & AccountOwner>;
  coverdal30OrOver: Array<Account & AccountOwner>;
  openHsas: Array<Account & AccountOwner>;
  openInherited: Array<Account & AccountOwner>;
}
export enum AccountSummaryTypes {
  accountsOpened = 'accountsOpened',
  accountsClosed = 'accountsClosed',
  totalOpenAccounts = 'totalOpenAccounts',
  tradIrasUnder73 = 'tradIrasUnder73',
  tradIras73OrOlder = 'tradIras73OrOlder',
  rothFiveYearTestIsTrue = 'rothFiveYearTestIsTrue',
  rothFiveYearTestIsFalse = 'rothFiveYearTestIsFalse',
  sepUnder73 = 'sepUnder73',
  sep73OrOlder = 'sep73OrOlder',
  simpleUnder73 = 'simpleUnder73',
  simple73OrOlder = 'simple73OrOlder',
  inheritedRoth5YearTrue = 'inheritedRoth5YearTrue',
  inheritedRoth5YearFalse = 'inheritedRoth5YearFalse',
  coverdalUnder18 = 'coverdalUnder18',
  coverdal18OrOver = 'coverdal18OrOver',
  coverdal30OrOver = 'coverdal30OrOver',
  openHsas = 'openHsas',
  openInherited = 'openInherited',
}

interface AccountSummaryCounts {
  accountsOpenedCount: number;
  accountsClosedCount: number;
  totalOpenAccountsCount: number;
  tradIrasUnder73Count: number;
  tradIras73OrOlderCount: number;
  rothFiveYearTestIsTrueCount: number;
  rothFiveYearTestIsFalseCount: number;
  sepUnder73Count: number;
  sep73OrOlderCount: number;
  simpleUnder73Count: number;
  simple73OrOlderCount: number;
  inheritedRoth5YearTrueCount: number;
  inheritedRoth5YearFalseCount: number;
  coverdalUnder18Count: number;
  coverdal18OrOverCount: number;
  coverdal30OrOverCount: number;
  openHsasCount: number;
  openInheritedCount: number;
}

interface AdditionalStatsProps {
  accountType: AccountType; // Account type for which to look up the stats component
  matchingOpenAccounts: Array<Account & AccountOwner>;
  summaryCounts: AccountSummaryCounts;
}

interface AccountSummaryForm {
  startDate: string;
  endDate: string;
}

interface AdditionalStatsChartProps {
  stats?: Array<ResponsivePercentBarProps>;
  accountType: AccountType | string;
  isOver18?: number;
  isOver30?: number;
  isUnder18?: number;
  isUnder73?: number;
  isOver73?: number;
  fiveYearMet?: number;
  fiveYearUnmet?: number;
  allAccounts?: number;
  expandData?: any;
  previousExpandedData?: any;
}

enum AgeDeterminationLegend {
  isOver18 = 'Owners 18 or over',
  isUnder18 = 'Owners under 18',
  isUnder73 = 'Owners under 73',
  isOver73 = 'Owners 73 or over',
  isOver30 = 'Owners 30 or over',
  fiveYearMet = 'Five year rule met',
  fiveYearUnmet = 'Five year rule not met',
}

const ACCOUNT_SUMMARY_INIT: AccountSummaryForm = {
  startDate: formatISO(startOfYear(new Date()), { representation: 'date' }),
  endDate: formatISO(endOfYear(new Date()), { representation: 'date' }),
};

const ACCOUNT_SUMMARY_SCHEMA = yup.object().shape(
  {
    startDate: yup
      .string()

      .when('endDate', (endDate, schema) =>
        endDate
          ? dateValidation(undefined, parseISO(endDate)).label('Start Date')
          : schema,
      ),
    endDate: yup
      .string()

      .when('startDate', (startDate, schema) =>
        startDate
          ? dateValidation(parseISO(startDate), addYears(new Date(), 10)).label(
              'End Date',
            )
          : schema,
      ),
  },
  [['startDate', 'endDate']],
);

function AdditionalStatsChart(props: AdditionalStatsChartProps) {
  const {
    stats = [],
    accountType = '',
    expandData,
    previousExpandedData = [],
  } = props;
  const { setGlobalPageSize } = usePaginationContext();
  const navigate = useNavigate();
  const {
    getAppConstant,
    addGlobalMessage,
    organization: { accountNumberValue },
  } = useGlobalContext();
  const { user } = useUser();
  const headerAccountName =
    accountNumberValue === AccountMemberValue.accountNumber
      ? 'Account #'
      : 'Member #';
  // grab expanded data from session storage
  const expandedData = sessionStorage.getItem('expandedData');
  const [accountSummaryTypeData, setAccountSummaryTypeData] = useState(
    JSON.parse(expandedData),
  );
  // grab session storage to see if we have expanded data
  const expandedSessionObject = sessionStorage.getItem('expandedAccordion');
  const [expandedValue, setExpandedValue] = useState(
    JSON.parse(expandedSessionObject),
  );

  const [typeValue, setType] = useState('');

  const setAccordionKey = (legend, accountType) => {
    let type = '';
    switch (accountType) {
      case AccountType.traditionalIra:
        switch (legend) {
          case AgeDeterminationLegend.isUnder73:
            type = AccountSummaryTypes.tradIrasUnder73;
            break;
          case AgeDeterminationLegend.isOver73:
            type = AccountSummaryTypes.tradIras73OrOlder;
            break;
          default:
            break;
        }
        break;
      case AccountType.rothIra:
        switch (legend) {
          case AgeDeterminationLegend.fiveYearMet:
            type = AccountSummaryTypes.rothFiveYearTestIsTrue;
            break;
          case AgeDeterminationLegend.fiveYearUnmet:
            type = AccountSummaryTypes.rothFiveYearTestIsFalse;
            break;
          default:
            break;
        }
        break;
      case AccountType.traditionalSep:
        switch (legend) {
          case AgeDeterminationLegend.isUnder73:
            type = AccountSummaryTypes.sepUnder73;
            break;
          case AgeDeterminationLegend.isOver73:
            type = AccountSummaryTypes.sep73OrOlder;
            break;
          default:
            break;
        }
        break;
      case AccountType.simpleIra:
        switch (legend) {
          case AgeDeterminationLegend.isUnder73:
            type = AccountSummaryTypes.simpleUnder73;
            break;
          case AgeDeterminationLegend.isOver73:
            type = AccountSummaryTypes.simple73OrOlder;
            break;
          default:
            break;
        }
        break;
      case AccountType.inheritedRoth:
        switch (legend) {
          case AgeDeterminationLegend.fiveYearMet:
            type = AccountSummaryTypes.inheritedRoth5YearTrue;
            break;
          case AgeDeterminationLegend.fiveYearUnmet:
            type = AccountSummaryTypes.inheritedRoth5YearFalse;
            break;
          default:
            break;
        }
        break;
      case AccountType.hsa:
        type = AccountSummaryTypes.openHsas;
        break;
      case AccountType.esa:
        switch (legend) {
          case AgeDeterminationLegend.isUnder18:
            type = AccountSummaryTypes.coverdalUnder18;
            break;
          case AgeDeterminationLegend.isOver18:
            type = AccountSummaryTypes.coverdal18OrOver;
            break;
          case AgeDeterminationLegend.isOver30:
            type = AccountSummaryTypes.coverdal30OrOver;
            break;
          default:
            break;
        }
        break;
      case AccountType.inheritedTraditional:
        type = AccountSummaryTypes.openInherited;
        break;
      default:
        type = '';
        break;
    }
    return type;
  };

  const expandAccordion = (legend, accountType, isExpanded) => {
    let type = '';
    switch (accountType) {
      case AccountType.traditionalIra:
        switch (legend) {
          case AgeDeterminationLegend.isUnder73:
            type = AccountSummaryTypes.tradIrasUnder73;
            break;
          case AgeDeterminationLegend.isOver73:
            type = AccountSummaryTypes.tradIras73OrOlder;
            break;
          default:
            break;
        }
        break;
      case AccountType.rothIra:
        switch (legend) {
          case AgeDeterminationLegend.fiveYearMet:
            type = AccountSummaryTypes.rothFiveYearTestIsTrue;
            break;
          case AgeDeterminationLegend.fiveYearUnmet:
            type = AccountSummaryTypes.rothFiveYearTestIsFalse;
            break;
          default:
            break;
        }
        break;
      case AccountType.traditionalSep:
        switch (legend) {
          case AgeDeterminationLegend.isUnder73:
            type = AccountSummaryTypes.sepUnder73;
            break;
          case AgeDeterminationLegend.isOver73:
            type = AccountSummaryTypes.sep73OrOlder;
            break;
          default:
            break;
        }
        break;
      case AccountType.simpleIra:
        switch (legend) {
          case AgeDeterminationLegend.isUnder73:
            type = AccountSummaryTypes.simpleUnder73;
            break;
          case AgeDeterminationLegend.isOver73:
            type = AccountSummaryTypes.simple73OrOlder;
            break;
          default:
            break;
        }
        break;
      case AccountType.inheritedRoth:
        switch (legend) {
          case AgeDeterminationLegend.fiveYearMet:
            type = AccountSummaryTypes.inheritedRoth5YearTrue;
            break;
          case AgeDeterminationLegend.fiveYearUnmet:
            type = AccountSummaryTypes.inheritedRoth5YearFalse;
            break;
          default:
            break;
        }
        break;
      case AccountType.hsa:
        type = AccountSummaryTypes.openHsas;
        break;
      case AccountType.esa:
        switch (legend) {
          case AgeDeterminationLegend.isUnder18:
            type = AccountSummaryTypes.coverdalUnder18;
            break;
          case AgeDeterminationLegend.isOver18:
            type = AccountSummaryTypes.coverdal18OrOver;
            break;
          case AgeDeterminationLegend.isOver30:
            type = AccountSummaryTypes.coverdal30OrOver;
            break;
          default:
            break;
        }
        break;
      case AccountType.inheritedTraditional:
        type = AccountSummaryTypes.openInherited;
        break;
      default:
        type = '';
        break;
    }

    // fetch the accounts based on the accordion expansion
    //  everytime an accordion is expanded, we are creating a session storage of the type and what was expanded
    if (expandedSessionObject) {
      const expandedData = JSON.parse(expandedSessionObject);
      sessionStorage.setItem(
        'expandedAccordion',
        JSON.stringify({ ...expandedData, [type]: isExpanded }),
      );
      setExpandedValue({ ...expandedData, [type]: isExpanded });
    } else {
      sessionStorage.setItem(
        'expandedAccordion',
        JSON.stringify({ [type]: isExpanded }),
      );
      setExpandedValue({ [type]: isExpanded });
    }

    if (!accountSummaryTypeData[type]) {
      setType(type);
      fetchAccounts(type);
    } else {
      // if we already have the data, we will just set the type
      setType(type);
    }
  };

  useEffect(() => {
    if (expandedSessionObject) {
      const expandedData = JSON.parse(expandedSessionObject);
      // set everything to false if the page has been refreshed
      Object.keys(expandedData).forEach((key) => {
        expandedData[key] = false;
      });
      sessionStorage.setItem('expandedAccordion', JSON.stringify(expandedData));
    }
  }, []);

  useEffect(() => {
    let type = '';
    switch (accountType) {
      case AccountType.hsa:
        type = AccountSummaryTypes.openHsas;
        setType(type);
        fetchAccounts(type);
        break;
      case AccountType.inheritedTraditional:
        type = AccountSummaryTypes.openInherited;
        setType(type);
        fetchAccounts(type);
        break;
      default:
        break;
    }
  }, [accountType]);

  async function fetchAccounts(type) {
    await getDashboardAccountContents(type, {}, user.organizationId, user.token)
      .then(({ data }) => {
        // if there is data out in the session storage, we will add that data to what is coming back store it to sesion storage
        if (expandedData) {
          const dataParsed = JSON.parse(expandedData);
          sessionStorage.setItem(
            'expandedData',
            JSON.stringify({ ...dataParsed, [type]: data }),
          );
          setAccountSummaryTypeData({ ...dataParsed, [type]: data });
        } else {
          sessionStorage.setItem(
            'expandedData',
            JSON.stringify({ [type]: data }),
          );
          setAccountSummaryTypeData({ [type]: data });
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Error fetching organization accounts',
        );
      });
  }

  const goToAccountProfile = (result: Account & AccountOwner): void => {
    const { accountOwnerId = '' } = result || {};

    if (accountOwnerId) {
      navigate(`/accountOwner/${accountOwnerId}`);
    }
  };

  const openDateColumn = {
    field: 'openDate',
    headerName: 'Date Opened',
    width: 126,
    valueFormatter: dateValueFormatter,
  };

  const columns = [
    {
      field: 'fullName',
      headerName: 'Name',
      width: 175,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            data-qa="account-search-result-link"
            onClick={() => {
              goToAccountProfile(row);
            }}
            sx={{
              justifyContent: 'flex-start' as any,
              width: '100%',
              textDecoration: 'none' as any,
              textTransform: 'none' as any,
            }}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'taxpayerIdNumber',
      headerName: 'SSN/ID',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
    {
      field: 'dateOfBirth',
      headerName: 'Birth Date',
      width: 126,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 156,
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        const typeId = value.toString();

        return getAppConstant(ConstantsMappingKey.accountType, typeId);
      },
    },
    {
      field: 'accountNumber',
      headerName: headerAccountName,
      width: 156,
      sortable: false,
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      {!stats.length ? (
        <Grid item xs={12}>
          <DataGrid
            components={{
              NoRowsOverlay: SiraNoRowsOverlay,
              Toolbar: DataGridExportToolbar,
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={globalPaginationOptions}
            onPaginationModelChange={setGlobalPageSize}
            disableColumnMenu
            autoHeight
            columns={[openDateColumn, ...columns]}
            rows={
              accountSummaryTypeData && accountSummaryTypeData[typeValue]
                ? accountSummaryTypeData[typeValue].map((account, idx) => ({
                    ...account,
                    id: idx,
                  }))
                : []
            }
          />
        </Grid>
      ) : (
        stats.map((row) => (
          <Grid item xs={12} key={row.legend}>
            <Accordion
              key={setAccordionKey(row.legend, accountType)}
              elevation={2}
              onChange={(e, expanded) => {
                // pass in the legend and account type to figure out the correct level of data to display
                expandAccordion(row.legend, accountType, expanded);
              }}
              slotProps={{
                transition: {
                  mountOnEnter: true,
                  unmountOnExit: true,
                },
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid key={row.legend} item xs={12}>
                  <ResponsivePercentBar {...row} isNested />
                </Grid>
              </AccordionSummary>

              <AccordionDetails>
                {/* we show loading progress in accordion if loading data for that data grid */}

                <DataGrid
                  key={accountType}
                  components={{
                    NoRowsOverlay: SiraNoRowsOverlay,
                    Toolbar: DataGridExportToolbar,
                  }}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={globalPaginationOptions}
                  onPaginationModelChange={setGlobalPageSize}
                  disableColumnMenu
                  autoHeight
                  columns={[openDateColumn, ...columns]}
                  rows={
                    accountSummaryTypeData &&
                    accountSummaryTypeData[
                      setAccordionKey(row.legend, accountType)
                    ]
                      ? accountSummaryTypeData[
                          setAccordionKey(row.legend, accountType)
                        ].map((account, idx) => ({
                          ...account,
                          id: idx,
                        }))
                      : []
                  }
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))
      )}
    </Grid>
  );
}

// Just add stat components here and they'll show up in the accordion when they match the account type
function AdditionalStats(props: AdditionalStatsProps) {
  const { accountType = '', matchingOpenAccounts = [], summaryCounts } = props;

  const EsaStats = () => {
    const isUnder18 = summaryCounts.coverdalUnder18Count;
    const isOver18 = summaryCounts.coverdal18OrOverCount;

    const isOver30 = summaryCounts.coverdal30OrOverCount;

    const openAccounts =
      summaryCounts.coverdalUnder18Count +
      summaryCounts.coverdal18OrOverCount +
      summaryCounts.coverdal30OrOverCount;

    const esaStats = [
      {
        legend: 'Owners under 18',
        quantity: isUnder18,
        percent: (isUnder18 / openAccounts) * 100,
      },
      {
        legend: 'Owners 18 or over',
        quantity: isOver18,
        percent: (isOver18 / openAccounts) * 100,
      },
      {
        legend: 'Owners 30 or over',
        quantity: isOver30,
        percent: (isOver30 / openAccounts) * 100,
      },
    ];

    return (
      <AdditionalStatsChart
        stats={esaStats}
        accountType={accountType}
        isOver18={isOver18}
        isUnder18={isUnder18}
        isOver30={isOver30}
      />
    );
  };

  const IraStats = () => {
    let isUnder73;
    let isOver73;
    let iraStats = [];
    const openAccounts =
      summaryCounts.tradIras73OrOlderCount + summaryCounts.tradIrasUnder73Count;

    switch (accountType) {
      case AccountType.traditionalIra:
        isUnder73 = summaryCounts.tradIrasUnder73Count;
        isOver73 = summaryCounts.tradIras73OrOlderCount;

        iraStats = [
          {
            legend: 'Owners under 73',
            quantity: summaryCounts.tradIrasUnder73Count,
            percent: (isUnder73 / openAccounts) * 100,
          },
          {
            legend: 'Owners 73 or over',
            quantity: summaryCounts.tradIras73OrOlderCount,
            percent: (isOver73 / openAccounts) * 100,
          },
        ];
        break;
      case AccountType.traditionalSep:
        isUnder73 = summaryCounts.sepUnder73Count;
        isOver73 = summaryCounts.sep73OrOlderCount;
        iraStats = [
          {
            legend: 'Owners under 73',
            quantity: summaryCounts.sepUnder73Count,
            percent: (isUnder73 / openAccounts) * 100,
          },
          {
            legend: 'Owners 73 or over',
            quantity: summaryCounts.sep73OrOlderCount,
            percent: (isOver73 / openAccounts) * 100,
          },
        ];
        break;
      case AccountType.simpleIra:
        isUnder73 = summaryCounts.simpleUnder73Count;
        isOver73 = summaryCounts.simple73OrOlderCount;
        iraStats = [
          {
            legend: 'Owners under 73',
            quantity: summaryCounts.simpleUnder73Count,
            percent: (isUnder73 / openAccounts) * 100,
          },
          {
            legend: 'Owners 73 or over',
            quantity: summaryCounts.simple73OrOlderCount,
            percent: (isOver73 / openAccounts) * 100,
          },
        ];
        break;
      default:
        break;
    }
    return (
      <AdditionalStatsChart
        stats={iraStats}
        accountType={accountType}
        isUnder73={isUnder73}
        isOver73={isOver73}
      />
    );
  };

  const RothStats = () => {
    let fiveYearRuleMet;
    let fiveYearRuleUnmet;
    let rothStats = [];
    const openAccounts =
      summaryCounts.rothFiveYearTestIsFalseCount +
      summaryCounts.rothFiveYearTestIsTrueCount;

    switch (accountType) {
      case AccountType.rothIra:
        fiveYearRuleMet = summaryCounts.rothFiveYearTestIsTrueCount;
        fiveYearRuleUnmet = summaryCounts.rothFiveYearTestIsFalseCount;
        rothStats = [
          {
            legend: 'Five year rule met',
            quantity: fiveYearRuleMet,
            percent: (fiveYearRuleMet / openAccounts) * 100,
          },
          {
            legend: 'Five year rule not met',
            quantity: fiveYearRuleUnmet,
            percent: (fiveYearRuleUnmet / openAccounts) * 100,
          },
        ];
        break;
      case AccountType.inheritedRoth:
        fiveYearRuleMet = summaryCounts.inheritedRoth5YearTrueCount;
        fiveYearRuleUnmet = summaryCounts.inheritedRoth5YearFalseCount;
        rothStats = [
          {
            legend: 'Five year rule met',
            quantity: fiveYearRuleMet,
            percent: (fiveYearRuleMet / openAccounts) * 100,
          },
          {
            legend: 'Five year rule not met',
            quantity: fiveYearRuleUnmet,
            percent: (fiveYearRuleUnmet / openAccounts) * 100,
          },
        ];
        break;
      default:
        break;
    }
    return (
      <AdditionalStatsChart
        stats={rothStats}
        accountType={accountType}
        fiveYearMet={fiveYearRuleMet}
        fiveYearUnmet={fiveYearRuleUnmet}
      />
    );
  };

  const AccountList = () => {
    return (
      <AdditionalStatsChart
        accountType={accountType}
        allAccounts={summaryCounts.totalOpenAccountsCount}
      />
    );
  };

  const componentMap: { [key: string]: Function } = {
    [AccountType.esa]: EsaStats,
    [AccountType.traditionalIra]: IraStats,
    [AccountType.traditionalSep]: IraStats,
    [AccountType.simpleIra]: IraStats,
    [AccountType.rothIra]: RothStats,
    [AccountType.inheritedRoth]: RothStats,
    [AccountType.inheritedTraditional]: AccountList,
    [AccountType.hsa]: AccountList,
  };

  return componentMap[accountType] ? componentMap[accountType]() : null;
}

function ReportsAccountsSummary() {
  let isMounted = true;
  const theme = useTheme();
  const navigate = useNavigate();
  const { setGlobalPageSize } = usePaginationContext();
  const {
    getAppConstant,
    organization: { legalName = '', accountNumberValue },
    addGlobalMessage,
  } = useGlobalContext();
  const headerAccountName =
    accountNumberValue === AccountMemberValue.accountNumber
      ? 'Account #'
      : 'Member #';

  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [dateFilters, setDateFilters] = useState({
    startDate: new Date(),
    endDate: new Date(),
  } as any);
  const [orgAccounts, setOrgAccounts] = useState(
    [] as Array<Account & AccountOwner>,
  );
  const accountTypesSupported =
    AccountSupportedTypestring as Array<AccountType>;
  const [pieData, setPieData] = useState([] as any);
  const [accountSummaryCounts, setAccountSummaryCounts] = useState(
    {} as AccountSummaryCounts,
  );
  const [accountSummaryTypeData, setAccountSummaryTypeData] = useState(
    {} as any,
  );

  const goToAccountProfile = (result: Account & AccountOwner): void => {
    const { accountOwnerId = '' } = result || {};

    if (accountOwnerId) {
      navigate(`/accountOwner/${accountOwnerId}`);
    }
  };

  // All open accounts regardless of date filters
  const openAccounts = useMemo(
    () =>
      orgAccounts.filter(
        ({ accountStatus }) => accountStatus === AccountStatus.open,
      ),
    [orgAccounts],
  );

  async function fetchSummaryAccounts() {
    setIsLoading(true);
    const { startDate, endDate } = dateFilters;

    const formattedStartDate = format(startDate, 'yyyy-MM-dd');
    const formattedEndDate = format(endDate, 'yyyy-MM-dd');

    await getAccountSummaryDashboards(
      {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      },
      user.organizationId,
      user.token,
    )
      .then(({ data }) => {
        if (isMounted) {
          // Pie chart data
          setPieData([
            {
              name: 'Opened',
              value: data.accountsOpenedCount,
              color: theme.palette.secondary.light,
            },
            {
              name: 'Closed',
              value: data.accountsClosedCount,
              color: theme.palette.grey[600],
            },
          ]);

          setAccountSummaryCounts(data);
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Error fetching organization accounts',
        );
      });

    if (isMounted) {
      setIsLoading(false);
    }
  }

  async function fetchAccounts(type) {
    setIsLoading(true);
    const { startDate, endDate } = dateFilters;

    const formattedStartDate = format(startDate, 'yyyy-MM-dd');
    const formattedEndDate = format(endDate, 'yyyy-MM-dd');

    await getDashboardAccountContents(
      type,
      {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      },
      user.organizationId,
      user.token,
    )
      .then(({ data }) => {
        if (isMounted) {
          setAccountSummaryTypeData((prevData) => ({
            ...prevData,
            [type]: data,
          }));
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Error fetching organization accounts',
        );
      });

    if (isMounted) {
      setIsLoading(false);
    }
  }

  const openDateColumn = {
    field: 'openDate',
    headerName: 'Date Opened',
    width: 126,
    valueFormatter: dateValueFormatter,
  };

  const closedDateColumn = {
    field: 'closedDate',
    headerName: 'Date Closed',
    width: 126,
    valueFormatter: dateValueFormatter,
  };

  const columns = [
    {
      field: 'fullName',
      headerName: 'Name',
      width: 175,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            data-qa="account-search-result-link"
            onClick={() => {
              goToAccountProfile(row);
            }}
            sx={{
              justifyContent: 'flex-start' as any,
              width: '100%',
              textDecoration: 'none' as any,
              textTransform: 'none' as any,
            }}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'taxpayerIdNumber',
      headerName: 'SSN/ID',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
    {
      field: 'dateOfBirth',
      headerName: 'Birth Date',
      width: 126,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 156,
      valueFormatter: (params: GridValueFormatterParams) => {
        const { value = '' } = params;
        const typeId = value.toString();

        return getAppConstant(ConstantsMappingKey.accountType, typeId);
      },
    },
    {
      field: 'accountNumber',
      headerName: headerAccountName,
      width: 156,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (user.token && user.organizationId) {
      fetchSummaryAccounts();
    }

    return () => {
      isMounted = false;
    };
  }, [user.organizationId, user.token, dateFilters]);

  // we will return the total of accounts based on the type
  const returnTotals = (accountType: AccountType) => {
    switch (accountType) {
      case AccountType.traditionalIra:
        const totalTradIras =
          accountSummaryCounts.tradIrasUnder73Count +
          accountSummaryCounts.tradIras73OrOlderCount;
        return totalTradIras;
      case AccountType.rothIra:
        const totalRoths =
          accountSummaryCounts.rothFiveYearTestIsTrueCount +
          accountSummaryCounts.rothFiveYearTestIsFalseCount;
        return totalRoths;
      case AccountType.traditionalSep:
        const totalSep =
          accountSummaryCounts.sepUnder73Count +
          accountSummaryCounts.sep73OrOlderCount;
        return totalSep;
      case AccountType.simpleIra:
        const totalSimple =
          accountSummaryCounts.simpleUnder73Count +
          accountSummaryCounts.simple73OrOlderCount;
        return totalSimple;
      case AccountType.inheritedRoth:
        const totalInheritedRoth =
          accountSummaryCounts.inheritedRoth5YearTrueCount +
          accountSummaryCounts.inheritedRoth5YearFalseCount;
        return totalInheritedRoth;
      case AccountType.hsa:
        return accountSummaryCounts.openHsasCount;
      case AccountType.esa:
        const totalCoverdal =
          accountSummaryCounts.coverdalUnder18Count +
          accountSummaryCounts.coverdal18OrOverCount +
          accountSummaryCounts.coverdal30OrOverCount;
        return totalCoverdal;
      case AccountType.inheritedTraditional:
        return accountSummaryCounts.openInheritedCount;
      default:
    }
  };

  // we will return the percentage of accounts based on the type divided by open accounts * 100
  const returnPercent = (accountType: AccountType) => {
    const openAccounts = accountSummaryCounts.totalOpenAccountsCount;
    switch (accountType) {
      case AccountType.traditionalIra:
        const totalTradIras =
          accountSummaryCounts.tradIrasUnder73Count +
          accountSummaryCounts.tradIras73OrOlderCount;
        return (totalTradIras / openAccounts) * 100;
      case AccountType.rothIra:
        const totalRoths =
          accountSummaryCounts.rothFiveYearTestIsTrueCount +
          accountSummaryCounts.rothFiveYearTestIsFalseCount;
        return (totalRoths / openAccounts) * 100;
      case AccountType.traditionalSep:
        const totalSep =
          accountSummaryCounts.sepUnder73Count +
          accountSummaryCounts.sep73OrOlderCount;
        return (totalSep / openAccounts) * 100;
      case AccountType.simpleIra:
        const totalSimple =
          accountSummaryCounts.simpleUnder73Count +
          accountSummaryCounts.simple73OrOlderCount;
        return (totalSimple / openAccounts) * 100;
      case AccountType.inheritedRoth:
        const totalInheritedRoth =
          accountSummaryCounts.inheritedRoth5YearTrueCount +
          accountSummaryCounts.inheritedRoth5YearFalseCount;
        return (totalInheritedRoth / openAccounts) * 100;
      case AccountType.hsa:
        return (accountSummaryCounts.openHsasCount / openAccounts) * 100;
      case AccountType.esa:
        const totalCoverdal =
          accountSummaryCounts.coverdalUnder18Count +
          accountSummaryCounts.coverdal18OrOverCount +
          accountSummaryCounts.coverdal30OrOverCount;
        return (totalCoverdal / openAccounts) * 100;
      case AccountType.inheritedTraditional:
        return (accountSummaryCounts.openInheritedCount / openAccounts) * 100;
      default:
    }
  };

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Reports and Statistics
          </Typography>

          <Typography color="secondary" variant="h1">
            Accounts Summary
          </Typography>

          <Typography gutterBottom>{legalName}</Typography>

          <Grid item xs={12} mt={5}>
            {!user.organizationId ? (
              <Box p={2} pt={5} pb={5}>
                <Alert severity="warning">
                  Select an organization to see account summary
                </Alert>
              </Box>
            ) : (
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <Typography variant="h5" gutterBottom>
                        Accounts Activity
                      </Typography>
                    </Grid>

                    {/* Date Range */}
                    <Grid item xs={12} md={7}>
                      <Formik
                        initialValues={ACCOUNT_SUMMARY_INIT}
                        onSubmit={(values: any) => {
                          const startDate = parseISO(values.startDate);
                          const endDate = parseISO(values.endDate);

                          if (isValid(startDate) && isValid(endDate)) {
                            setDateFilters({
                              startDate,
                              endDate,
                            });
                          }
                        }}
                        validationSchema={ACCOUNT_SUMMARY_SCHEMA}
                        enableReinitialize
                      >
                        {({ values, submitForm }) => {
                          // Submit the form whenever the values change
                          useEffect(() => {
                            submitForm();
                          }, [values]);

                          return (
                            <Form>
                              <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                pt={1}
                                pb={2}
                              >
                                <SiraDateRangeField />
                              </Grid>
                            </Form>
                          );
                        }}
                      </Formik>
                    </Grid>

                    {/* Pie Chart */}
                    <Grid
                      item
                      xs={12}
                      md={5}
                      sx={{
                        filter: isLoading ? 'blur(5px)' : '',
                        transition: 'filter 0.33s ease-in-out',
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        wrap="nowrap"
                      >
                        <Grid item>
                          <PieChart width={140} height={140}>
                            <Pie
                              dataKey="value"
                              isAnimationActive={false}
                              data={pieData}
                              cx="50%"
                              cy="50%"
                              outerRadius={70}
                            >
                              {pieData &&
                                pieData.map(({ value, color }) => (
                                  <Cell key={value} fill={color} />
                                ))}
                            </Pie>
                          </PieChart>
                        </Grid>

                        <Grid item xs={12}>
                          {pieData.map(({ name, value, color }) => (
                            <Typography key={name} variant="body2" gutterBottom>
                              <Box
                                component="span"
                                mr={1}
                                display="inline-block"
                                width={10}
                                height={10}
                                bgcolor={color}
                              />
                              {name}: {value}
                            </Typography>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Open Account Details */}
                <Grid
                  item
                  xs={12}
                  sx={{
                    filter: isLoading ? 'blur(5px)' : '',
                    transition: 'filter 0.33s ease-in-out',
                  }}
                >
                  <Accordion
                    elevation={2}
                    disabled={isLoading}
                    onChange={(
                      event: React.SyntheticEvent,
                      isExpanded: boolean,
                    ) => {
                      // if the accordion is expanded fetch the accounts
                      if (isExpanded) {
                        fetchAccounts('accountsOpened');
                      }
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant="body2" fontWeight="bold">
                            {accountSummaryCounts.accountsOpenedCount} Accounts
                            were Opened
                          </Typography>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <Typography variant="caption">View all</Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <DataGrid
                        components={{
                          NoRowsOverlay: SiraNoRowsOverlay,
                          Toolbar: DataGridExportToolbar,
                        }}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        pageSizeOptions={globalPaginationOptions}
                        onPaginationModelChange={setGlobalPageSize}
                        disableColumnMenu
                        autoHeight
                        columns={[openDateColumn, ...columns]}
                        rows={
                          accountSummaryTypeData &&
                          accountSummaryTypeData.accountsOpened
                            ? accountSummaryTypeData.accountsOpened.map(
                                (account, idx) => ({
                                  ...account,
                                  id: idx,
                                }),
                              )
                            : []
                        }
                      />
                    </AccordionDetails>
                  </Accordion>

                  {/* Closed Account Details */}
                  <Accordion
                    elevation={2}
                    disabled={isLoading}
                    onChange={(
                      event: React.SyntheticEvent,
                      isExpanded: boolean,
                    ) => {
                      // if the accordion is expanded fetch the accounts
                      if (isExpanded) {
                        fetchAccounts('accountsClosed');
                      }
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant="body2" fontWeight="bold">
                            {accountSummaryCounts.accountsClosedCount} Accounts
                            were Closed
                          </Typography>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <Typography variant="caption">View all</Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <DataGrid
                        components={{
                          NoRowsOverlay: SiraNoRowsOverlay,
                          Toolbar: DataGridExportToolbar,
                        }}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        pageSizeOptions={globalPaginationOptions}
                        onPaginationModelChange={setGlobalPageSize}
                        disableColumnMenu
                        autoHeight
                        columns={[closedDateColumn, ...columns]}
                        rows={
                          accountSummaryTypeData &&
                          accountSummaryTypeData.accountsClosed
                            ? accountSummaryTypeData.accountsClosed.map(
                                (account, idx) => ({
                                  ...account,
                                  id: idx,
                                }),
                              )
                            : []
                        }
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                {/* Open Accounts By Type */}
                <Grid
                  item
                  xs={12}
                  sx={{
                    filter: isLoading ? 'blur(5px)' : '',
                    transition: 'filter 0.33s ease-in-out',
                  }}
                >
                  <Typography variant="h5">
                    {accountSummaryCounts.totalOpenAccountsCount} Current Open
                    Accounts
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    filter: isLoading ? 'blur(5px)' : '',
                    transition: 'filter 0.33s ease-in-out',
                  }}
                  key={accountSummaryCounts.totalOpenAccountsCount}
                >
                  {accountTypesSupported.map((accountTypeToMatch) => {
                    // Open accounts that match the current datum's type
                    const matchingOpenAccounts =
                      returnTotals(accountTypeToMatch);
                    // Percent to display and use for the bar chart width
                    const matchingPercent = returnPercent(accountTypeToMatch);

                    return (
                      <Accordion elevation={2} disabled={isLoading}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <ResponsivePercentBar
                            legend={getAppConstant(
                              ConstantsMappingKey.accountType,
                              accountTypeToMatch,
                            )}
                            quantity={matchingOpenAccounts}
                            percent={matchingPercent}
                          />
                        </AccordionSummary>

                        <AccordionDetails>
                          <AdditionalStats
                            key={accountTypeToMatch}
                            accountType={accountTypeToMatch}
                            matchingOpenAccounts={[]}
                            summaryCounts={accountSummaryCounts}
                          />
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </Grid>
              </Grid>
            )}
          </Grid>
        </>
      </FormPaper>
    </Layout>
  );
}

export default ReportsAccountsSummary;

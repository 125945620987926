import axios from 'axios';
import format from 'string-format';
import { removeEmptyStrings, setBaseUrl } from './api.utils';

import {
  Import5498StatusResponse,
  TaxForm5498InfoResponse,
  TaxForm5498Response,
  TaxFormInfoResponse,
  TaxFormResponse,
  TaxFormType,
  Totals1099PageResponse,
} from './TaxFormApi.d';
import { sq } from 'date-fns/locale';
import axiosInstance from '../utils/axiosInterceptor';
import { trigger } from 'swr';

const urlBase = process.env.REACT_APP_API_BASE_URL;

const accountOwnerTaxForm =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerID}/account/{0.accountID}/taxForm1099';
const taxFormInfo =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerID}/account/{0.accountID}/taxForm1099/{0.id}';
const taxForm = '/org/{0.orgId}/taxForm1099/{0.taxYear}';
const taxStateFile = '/org/{0.orgId}/taxFile/1220/state/{0.stateId}';
const taxStateFiles = '/org/{0.orgId}/taxFile/1220/state/all';
const taxFormSummary = '/org/{0.orgId}/taxFile/1099/summary';
const taxFormDocumentUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerID}/account/{0.accountID}/taxForm1099/{0.taxFormID}/document';
const tax1099totalspage = '/api/org/{0.orgId}/taxFile/1099/summary';
const tax5498ImportStatus = '/api/org/{0.orgId}/upload/taxFile/results';
const tax1099ImportStatus = '/api/org/{0.orgId}/upload/taxFile/results';
const taxImportStatus =
  '/api/org/{0.orgId}/upload/taxFile/results/pageable/{0.irsTaxFileUploadId}';

const accountOwnerTaxForm5498 =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerID}/account/{0.accountID}/taxForm5498';
const taxFormInfo5498 =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerID}/account/{0.accountID}/taxForm5498/{0.id}';
const taxForm5498 = '/org/{0.orgId}/taxForm5498/{0.taxYear}';
const taxForm5498DocumentUrl =
  '/org/{0.orgId}/accountOwner/{0.accountOwnerID}/account/{0.accountID}/taxForm5498/{0.taxFormID}/document';
const taxReports5498ImportUrl = '/api/org/{0.orgId}/upload/flat/taxFile';
const taxReportingCorrection =
  '/api/org/{0.orgId}/taxfile/upload/{0.uploadId}/correction';
const taxReports5498ZipFileURL = '/org/{0.orgId}/taxForm5498/zip/client';
const taxReports1099ZipFileURL = '/org/{0.orgId}/taxForm1099/zip/client';
const taxformDateSentToOwner =
  '/api/org/{0.orgId}/accountOwner/{0.accountOwnerID}/account/{0.accountID}/taxForm5498/{0.formId}/deliver';
const taxformDateSentToOwner1099 =
  '/api/org/{0.orgId}/accountOwner/{0.accountOwnerID}/account/{0.accountID}/taxForm1099/{0.formId}/deliver';
const taxFormGetFileExport = '/api/org/{0.orgId}/settings/taxFileExport';
const taxEntryCorrection =
  '/api/org/{0.orgId}/upload/taxFile/results/{0.uploadId}/detail/{0.sequenceNumber}';
const saveindexUrl = '/org/{0.orgId}/settings/taxFileExport';

export async function getaccountOwnerTaxForm(
  accountID: string,
  accountOwnerID: string,
  orgId: string,
  token: string,
  user: any,
): Promise<TaxFormResponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${accountOwnerTaxForm}`, {
    orgId,
    accountOwnerID,
    accountID,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getTaxFormInfo(
  accountID: string,
  accountOwnerID: string,
  id: string,
  orgId: string,
  token: string,
  user: any,
): Promise<TaxFormInfoResponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${taxFormInfo}`, {
    orgId,
    accountOwnerID,
    accountID,
    id,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getTax5498Form(
  taxYear: string,
  orgId: string,
  token: string,
  user: any,
): Promise<TaxForm5498Response> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${taxForm5498}`, {
    orgId,
    taxYear,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getaccountOwnerTaxForm5498(
  accountID: string,
  accountOwnerID: string,
  orgId: string,
  token: string,
  user: any,
): Promise<TaxForm5498Response> {
  const url = `${urlBase}${format(
    `${setBaseUrl(user)}${accountOwnerTaxForm5498}`,
    {
      orgId,
      accountOwnerID,
      accountID,
    },
  )}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getTaxFormInfo5498(
  accountID: string,
  accountOwnerID: string,
  id: string,
  orgId: string,
  token: string,
  user: any,
): Promise<TaxForm5498InfoResponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${taxFormInfo5498}`, {
    orgId,
    accountOwnerID,
    accountID,
    id,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function deleteTaxFormInfo5498(
  accountID: string,
  accountOwnerID: string,
  id: any,
  orgId: string,
  token: string,
  user: any,
): Promise<TaxForm5498InfoResponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${taxFormInfo5498}`, {
    orgId,
    accountOwnerID,
    accountID,
    id,
  })}`;

  return axiosInstance.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getTaxForm(
  taxYear: string,
  orgId: string,
  token: string,
  user: any,
): Promise<TaxFormResponse> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${taxForm}`, {
    orgId,
    taxYear,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getTaxForm1099Summary(
  taxYear: string,
  orgId: string,
  token: string,
  user: any,
): Promise<any> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${taxFormSummary}`, {
    orgId,
  })}`;

  const params = removeEmptyStrings({ taxYear });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getTaxForm1099StateFiles(
  taxYear: string,
  orgId: string,
  token: string,
  user: any,
): Promise<any> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${taxStateFiles}`, {
    orgId,
  })}`;

  const params = removeEmptyStrings({ taxYear });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createTaxForm1099Summary(
  taxYear: string,
  stateId: string,
  excludeZeroWithholding: boolean,
  orgId: string,
  token: string,
  user: any,
): Promise<any> {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${taxStateFile}`, {
    orgId,
    stateId,
  })}`;

  const params = removeEmptyStrings({
    taxYear,
    excludeZeroWithholding,
    exportFile: true,
  });

  return axiosInstance.post(
    url,
    {},
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function createTaxFormZipFile5498(
  data,
  taxYear: string,
  taxFormType: string,
  orgId: string,
  token: string,
  user: any,
  trigger: any,
): Promise<any> {
  const url = `${urlBase}${format(
    `${setBaseUrl(user)}${taxReports5498ZipFileURL}`,
    {
      orgId,
    },
  )}`;

  const saveurl = `${urlBase}${format(`${setBaseUrl(user)}${saveindexUrl}`, {
    orgId,
  })}`;

  const params = removeEmptyStrings({ taxYear, taxFormType });

  if (trigger === 'post') {
    return axiosInstance.post(url, data, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    return axiosInstance.put(saveurl, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export async function createTaxFormZipFile1099(
  data,
  taxYear: string,
  taxFormType: string,
  orgId: string,
  token: string,
  user: any,
  trigger: any,
): Promise<any> {
  const url = `${urlBase}${format(
    `${setBaseUrl(user)}${taxReports1099ZipFileURL}`,
    {
      orgId,
    },
  )}`;

  const saveurl = `${urlBase}${format(`${setBaseUrl(user)}${saveindexUrl}`, {
    orgId,
  })}`;

  const params = removeEmptyStrings({ taxYear, taxFormType });

  if (trigger === 'post') {
    return axiosInstance.post(url, data, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    return axiosInstance.put(saveurl, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export async function getTaxFormDocument(
  accountID: string,
  accountOwnerID: string,
  taxFormID: number,
  orgId: string,
  token: string,
  user: any,
) {
  const url = `${urlBase}${format(`${setBaseUrl(user)}${taxFormDocumentUrl}`, {
    orgId,
    accountOwnerID,
    accountID,
    taxFormID,
  })}`;

  const params = removeEmptyStrings({ taxFormID });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getTaxForm5498Document(
  accountID: string,
  accountOwnerID: string,
  taxFormID: number,
  orgId: string,
  token: string,
  user: any,
) {
  const url = `${urlBase}${format(
    `${setBaseUrl(user)}${taxForm5498DocumentUrl}`,
    {
      orgId,
      accountOwnerID,
      accountID,
      taxFormID,
    },
  )}`;

  const params = removeEmptyStrings({ taxFormID });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getTax1099totalspage(
  taxYear: string,
  orgId: string,
  token: string,
): Promise<Totals1099PageResponse> {
  const url = `${urlBase}${format(tax1099totalspage, {
    orgId,
  })}`;

  const params = removeEmptyStrings({ taxYear });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function import5498TaxReports(
  files: Array<File>,
  orgId: string,
  token: string,
  taxYear: number,
  taxFormFamily: string,
): Promise<any> {
  const url = `${urlBase}${format(taxReports5498ImportUrl, {
    orgId,
  })}`;

  const formData = new FormData();
  const params = removeEmptyStrings({ taxYear, taxFormFamily });

  formData.append('file', files[0]);

  return axiosInstance.post(url, formData, {
    params,
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function import1099TaxReports(
  files: Array<File>,
  orgId: string,
  token: string,
  taxYear: number,
  taxFormFamily: string,
): Promise<any> {
  const url = `${urlBase}${format(taxReports5498ImportUrl, {
    orgId,
  })}`;

  const formData = new FormData();
  const params = removeEmptyStrings({ taxYear, taxFormFamily });

  formData.append('file', files[0]);

  return axiosInstance.post(url, formData, {
    params,
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function import5498TaxReportStatus(
  orgId: string,
  token: string,
  taxYear: number,
  taxFormFamily: string,
): Promise<Import5498StatusResponse> {
  const url = `${urlBase}${format(tax5498ImportStatus, {
    orgId,
  })}`;

  const params = removeEmptyStrings({ taxYear, taxFormFamily });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function import1099TaxReportStatus(
  orgId: string,
  token: string,
  taxYear: number,
  taxFormFamily: string,
): Promise<Import5498StatusResponse> {
  const url = `${urlBase}${format(tax1099ImportStatus, {
    orgId,
  })}`;

  const params = removeEmptyStrings({ taxYear, taxFormFamily });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function taxReportUploadFileResult(
  orgId: string,
  token: string,
  irsTaxFileUploadId: number,
): Promise<Import5498StatusResponse> {
  const url = `${urlBase}${format(taxImportStatus, {
    orgId,
    irsTaxFileUploadId,
  })}`;

  return axiosInstance.put(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function taxReportUploadFileResultPagable(
  orgId: string,
  token: string,
  irsTaxFileUploadId: number,
  pageSize: number = 10,
  field: string = '',
  sort: string = '',
  page: number = 0,
  filter: any = {},
  filterObject: any = {},
): Promise<any> {
  const url = `${urlBase}${format(taxImportStatus, {
    orgId,
    irsTaxFileUploadId,
  })}`;

  const fmvOnly = filter.fmvAmount;
  const matchingOnly = filter.matching;
  const params = removeEmptyStrings({
    pageSize,
    field,
    sort,
    pageNumber: page,
    matchingOnly,
    fmvOnly,
  });

  return axiosInstance.put(url, filterObject, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function taxReportFileExport(
  orgId: string,
  token: string,
): Promise<any> {
  const url = `${urlBase}${format(taxFormGetFileExport, {
    orgId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function taxReportingCorrectionRequest(
  data,
  orgId: string,
  token: string,
  uploadId: number,
): Promise<any> {
  const url = `${urlBase}${format(taxReportingCorrection, {
    orgId,
    uploadId,
  })}`;

  const params = removeEmptyStrings(data);

  return axiosInstance.put(url, params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getCorrectionData(
  orgId: string,
  token: string,
  uploadId: number,
  sequenceNumber: number,
): Promise<any> {
  const url = `${urlBase}${format(taxEntryCorrection, {
    orgId,
    uploadId,
    sequenceNumber,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function taxformDateSentToOwnerRequest(
  data,
  orgId: string,
  token: string,
  accountOwnerID: string,
  accountID: string,
  formId: number,
  formType: TaxFormType,
): Promise<any> {
  const url = `${urlBase}${format(
    ['Q', 'R', 'SA'].includes(formType)
      ? taxformDateSentToOwner1099
      : taxformDateSentToOwner,
    {
      orgId,
      accountOwnerID,
      accountID,
      formId,
    },
  )}`;

  const params = removeEmptyStrings(data);

  return axiosInstance.put(
    url,
    {},
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

import React, { ReactNode, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  TextField,
  ListItemSecondaryAction,
  IconButton,
  ListItem,
} from '@mui/material/';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableElementProps,
} from 'react-sortable-hoc';

function not(a: any[], b: any[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: any[], bValue: any[]) {
  // stripping b to be just a list of enumValue
  const b = bValue.map((value) => value.enumValue);
  const result = a.filter((value) => b.indexOf(value) !== -1);
  return result;
}

function union(a: any[], bValue: any[]) {
  // stripping b to be just a list of enumValue
  const b = bValue.map((value) => value.enumValue);
  return [...a, ...not(b, a)];
}

interface SortProps {
  value: {
    enumValue: string;
    displayName: string;
    customDisplayName: string;
  };
  index: number;
  toggle: (value: any) => () => void;
}

interface SortableListProps {
  tableData: any[];
  onSortEnd: ({ oldIndex, newIndex }: { oldIndex: any; newIndex: any }) => void;
}

export default function SiraTransferList({
  choose,
  choosen,
  fieldIndexSorting,
}: {
  choose: any[];
  choosen: any[];
  fieldIndexSorting: Function;
}) {
  const [checked, setChecked] = useState<any[]>([]);
  const [left, setLeft] = useState<any[]>(choose);
  const [right, setRight] = useState<any[]>(choosen);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const [functionRan, setFunctionRan] = useState<boolean>(false);
  // In your component

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setFunctionRan(true);
    setRight(arrayMove(right, oldIndex, newIndex));
    fieldIndexSorting(arrayMove(right, oldIndex, newIndex));
  };

  const handleToggle = (value: any) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: any[]) => {
    return intersection(checked, items).length;
  };

  const handleToggleAll = (items: any[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked([]);
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    // filter the enumValue of the leftChecked in left and concat it to right
    const findCheckedValue = left.filter(
      (value) => leftChecked.indexOf(value.enumValue) !== -1
    );

    // remove checked values from left
    const leftValues = left.filter(
      (value) => leftChecked.indexOf(value.enumValue) === -1
    );

    setRight(right.concat(findCheckedValue));
    fieldIndexSorting(right.concat(findCheckedValue));
    setLeft(leftValues);
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    // filter the enumValue of the rightChecked in right and concat it to left
    const findCheckedValue = right.filter(
      (value) => rightChecked.indexOf(value.enumValue) !== -1
    );

    // remove checked values from right
    const rightValues = right.filter(
      (value) => rightChecked.indexOf(value.enumValue) === -1
    );

    setLeft(
      left
        .concat(findCheckedValue)
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
    );
    setRight(rightValues);
    fieldIndexSorting(rightValues);
    setChecked(not(checked, rightChecked));
  };

  // USE THIS TO SET THE INITIAL VALUES
  useEffect(() => {
    if (choose.length > 0) {
      setLeft(choose);
    }

  }, [choose]);

  useEffect(() => {
    if (choosen.length > 0) {
      setRight(choosen);
    }
  }, [choosen]);

  const [editingItem, setEditingItem] = useState(null);
  const [customNames, setCustomNames] = useState<any>({});
  // using this to get the value of the textfield
  const textFieldRefs = useRef({}) as any;

  const handleEditClick = (event, enumValue) => {
    event.stopPropagation();
    setEditingItem(enumValue);
  };

  const handleSaveClick = (event, enumValue) => {
    event.stopPropagation();
    const textFieldValue = textFieldRefs.current[enumValue].value;
    if (textFieldRefs.current[enumValue]) {
      textFieldRefs.current[enumValue].blur();
    }
    // change the displame to the custom name
    const newRight = right.map((item) => {
      if (item.enumValue === enumValue) {
        return {
          ...item,
          displayName: textFieldValue,
          customDisplayName: textFieldValue,
        };
      }
      return item;
    });

    // make state right changes available to the fieldIndexSorting function
    fieldIndexSorting(newRight);
    setRight(newRight);
    setEditingItem(null);
  };

  const handleCancelClick = (event) => {
    event.stopPropagation();
    setEditingItem(null);
  };

  // Wrap ListItemButton in SortableElement
  const SortableItem = SortableElement<SortProps & SortableElementProps>(({ value={
    enumValue: '',
    displayName: '',
    customDisplayName: '',
  
  }, index, toggle }) => {
    const labelId = `transfer-list-all-item-${value.enumValue}-label`;

    return (
      <ListItem key={`${value.enumValue}_${index}`} role="listitem">
        {/* 
      TODO: add grid container and insert all the child elments into a grid
      TODO: change field name to the new jon fields
      TODO: Handle the custom name change
      TODO: Add the custom name to the right array
      TODO: Change the display name after save
        */}
        <Grid container spacing={1}>
          <Grid item mt={editingItem === value.enumValue ? 2 : 0}>
            <ListItemIcon>
              <Checkbox
                checked={checked.indexOf(value.enumValue) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{
                  'aria-labelledby': labelId,
                }}
                onClick={toggle(value.enumValue)} // Moved the onClick handler here
              />
            </ListItemIcon>
          </Grid>
          <Grid item xs={8} md={8} mt={1} pb={1}>
            {editingItem === value.enumValue ? (
              <TextField
                key={value.enumValue} // differentiate the different textfields
                inputRef={(ref) => {
                  if (ref) {
                    textFieldRefs.current[value.enumValue] = ref;
                  }
                }} // using this ref to pull in the user entered value if onblur isn't working
                name="customName"
                defaultValue={customNames[value.enumValue] || ''}
                label={`${value.displayName} Custom Name`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onBlur={(e) => {
                  // passing in the previous state to avoid asynchrounous issues
                  setCustomNames((prevCustomNames) => {
                    return {
                      ...prevCustomNames,
                      [value.enumValue]: e.target.value,
                    };
                  });
                }}
              />
            ) : (
              <ListItemText id={value.enumValue} primary={value.displayName} />
            )}
          </Grid>
          <Grid item xs={4} md={2}>
            <ListItemSecondaryAction>
              {editingItem === value.enumValue ? (
                <>
                  {' '}
                  <IconButton
                    onMouseDown={(event) =>
                      handleSaveClick(event, value.enumValue)
                    }
                  >
                    <SaveOutlinedIcon />
                  </IconButton>
                  <IconButton onMouseDown={(event) => handleCancelClick(event)}>
                    <CancelOutlinedIcon />
                  </IconButton>
                </>
              ) : (
                <IconButton
                  onMouseDown={(event) =>
                    handleEditClick(event, value.enumValue)
                  }
                >
                  <EditOutlinedIcon />
                </IconButton>
              )}
            </ListItemSecondaryAction>
          </Grid>
        </Grid>
      </ListItem>
    );
  });

  const SortableList = SortableContainer<SortableListProps>(({ tableData }) => {
    const items = tableData.length > 0 ? tableData : [];
    return (
      <Card
        elevation={2}
        onMouseLeave={() => {
          if (!functionRan) {
            fieldIndexSorting(right);
          }
        }}
      >
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items.length &&
                numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{
                'aria-label': 'all items selected',
              }}
            />
          }
          title="Selected"
          subheader={`${numberOfChecked(items)}/${items.length} selected`}
        />
        <Divider />
        <List
          sx={{
            width: 400,
            height: 330,
            bgcolor: 'background.paper',
            overflow: 'auto',
          }}
          dense
          component="div"
          role="list"
        >
          {items.map((value: any, idx) => {
            return (
              <SortableItem
                // eslint-disable-next-line react/no-array-index-key
                key={`item-${idx}_${value.enumValue}`}
                index={idx}
                value={value}
                toggle={handleToggle}
              />
            );
          })}
        </List>
      </Card>
    );
  });

  const customList = (title: ReactNode, tableData: any[]) => {
    const items = tableData.length > 0 ? tableData : [];
    return (
      <Card elevation={2}>
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items.length &&
                numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{
                'aria-label': 'all items selected',
              }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items.length} selected`}
        />
        <Divider />
        <List
          sx={{
            width: 300,
            height: 330,
            bgcolor: 'background.paper',
            overflow: 'auto',
          }}
          dense
          component="div"
          role="list"
        >
          {items.map((value: any, idx) => {
            const labelId = `transfer-list-all-item-${value.enumValue}-label`;
            return (
              <ListItemButton
                // eslint-disable-next-line react/no-array-index-key
                key={`${value.enumValue}_${idx}`}
                role="listitem"
                onClick={handleToggle(value.enumValue)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value.enumValue) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={value.enumValue}
                  primary={value.displayName}
                />
              </ListItemButton>
            );
          })}
        </List>
      </Card>
    );
  };

  return (
    <Grid container spacing={2} justifyContent="start" alignItems="center">
      <Grid item>{customList('Options', left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
            color="primary"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
            color="primary"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <SortableList onSortEnd={onSortEnd} tableData={right} />
      </Grid>
    </Grid>
  );
}

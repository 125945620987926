import React, { useState, MouseEvent, useEffect } from 'react';
import {
  useTheme,
  Paper,
  Typography,
  Box,
  Grid,
  IconButton,
  Badge,
  CardContent,
  CardHeader,
  Card,
  Menu,
  MenuItem,
  Button,
  TextField,
  LinearProgress,
} from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import { MoreHoriz } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import { formatDistance, parseISO } from 'date-fns';
import { User } from '@auth0/auth0-react';
import { AccountOwnerAccount } from '../../api/AccountOwnerApi.d';
import { accountStatusDescriptions } from '../../app.constants';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { ConstantsMappingKey } from '../../api/SharedTextApi.d';

import SiraModal from '../SiraModal';
import { AccountComment } from '../../api/AccountApi.d';
import { useUser } from '../../auth/useUser';
import {
  createAccountComment,
  deleteAccountComment,
  retrieveAccountComment,
  updateAccountComment,
} from '../../api/AccountCommentApi';
import { getUsers } from '../../api/UserApi';
import { UserRole } from '../../api/UserApi.d';
import { AccountMemberValue } from '../../api/OrganizationApi.d';
import { errorMessages } from '../../utils/errorhandling.utils';

interface AccountOwnerAccountSummaryCardProps {
  account: AccountOwnerAccount;
  selectedAccountId: string;
  setSelectedAccount: Function;
}

function AccountOwnerAccountSummaryCard(
  props: AccountOwnerAccountSummaryCardProps,
) {
  let isMounted = true;
  const {
    account = {} as AccountOwnerAccount,
    selectedAccountId = {},
    setSelectedAccount = () => {},
  } = props;
  const theme = useTheme();
  const { getAppConstant } = useGlobalContext();
  const {
    accountId = '',
    accountType = '',
    accountNumber = '',
    accountStatus = '',
    planNumber = '',
  } = account;
  const isSelected = accountId === selectedAccountId;
  const [notify, setnotify] = useState(false);
  const [open, setOpen] = useState(false);
  const [accountComment, setAccountComment] = useState([
    {},
  ] as Array<AccountComment>);
  const [anchorIndividualEl, setAnchorIndividualEl] =
    useState<null | HTMLElement>(null);
  const [selectedAccountComment, setSelectedAccountComment] = useState(
    {} as AccountComment,
  );
  const [newComment, setNewComment] = useState(false as boolean);
  const [comment, setComment] = useState('' as string);
  const openIndividualMenu = Boolean(anchorIndividualEl);
  const { user } = useUser();
  const [userInfo, setUserInfo] = useState<Array<User>>([]);

  const { addGlobalMessage, organization } = useGlobalContext();
  const { accountNumberValue } = organization;
  const [sendingComment, setSendingComment] = useState(false as boolean);
  const headerAccountName =
    accountNumberValue === AccountMemberValue.accountNumber
      ? 'Acct #'
      : 'Mmbr #';
  const classes = {
    accountSummaryCard: {
      cursor: 'pointer',
      border: `1px solid transparent`,
      borderTop: `${theme.spacing(3)} solid ${theme.palette.primary.main}`,
      padding: theme.spacing(2),
    },
    accountSummaryCardActive: {
      border: `1px solid ${theme.palette.secondary.main}`,
      borderTop: `${theme.spacing(3)} solid ${theme.palette.secondary.main}`,
    },
  };

  const buttonContentStyle = {
    height: '100%',
    display: 'flex',
    justifyContent: 'right',
    paddingTop: 2,
  };

  const styles = {
    commentsBox: {
      width: '100%',
      overflowX: 'auto',
      minheight: '100%',
      marginTop: '15px',
      [theme.breakpoints.up('md')]: {
        overflowX: 'auto',
        width: '100%',
        position: 'relative',
        minheight: '100%',
        marginTop: '15px',
      },
    },
  };

  const setRepInfo = async (): Promise<void> => {
    await getUsers(user.organizationId, user.token, {
      includeMultiOrgUsers: true,
    })
      .then((res) => {
        setUserInfo(res.data);
      })
      .catch(() => {
        if (isMounted) {
          addGlobalMessage('Error fetching organization users');
        }
      });
  };

  async function getAccountComments(): Promise<void> {
    await retrieveAccountComment(accountId, user.organizationId, user.token)
      .then((responseValue) => {
        setAccountComment(responseValue.data);
        setnotify(responseValue.data.length > 0);
      })
      .catch((err) => {
        if (isMounted) {
          addGlobalMessage(errorMessages(err) || 'Could not fetch comments');
        }
      });
  }

  async function deleteAccountComments(
    accountCommentId: number,
  ): Promise<void> {
    await deleteAccountComment(
      accountId,
      accountCommentId,
      user.organizationId,
      user.token,
    )
      .then(() => {
        getAccountComments();
      })
      .catch((err) => {
        if (isMounted) {
          addGlobalMessage(errorMessages(err) || 'Could not fetch comments');
        }
      });
  }

  async function addorUpdateAccountComments(
    accountCommentId: number,
  ): Promise<void> {
    const commentDate = new Date().toISOString();

    if (accountCommentId === 0) {
      const buildData = {
        accountCommentId,
        financialOrganizationId: Number(user.organizationId),
        accountId: Number(accountId),
        comment,
        recordCreated: commentDate,
        recordUpdated: '',
      };
      await createAccountComment(
        accountId,
        buildData,
        user.organizationId,
        user.token,
      )
        .then(() => {
          setSendingComment(false);
          getAccountComments();
          setNewComment(false);
        })
        .catch((err) => {
          setSendingComment(false);

          if (isMounted) {
            addGlobalMessage(errorMessages(err) || 'Could not fetch comments');
          }
        });
    } else {
      const buildData = {
        accountCommentId,
        financialOrganizationId: selectedAccountComment.financialOrganizationId,
        accountId: selectedAccountComment.accountId,
        comment,
        superiorUserId: selectedAccountComment.superiorUserId,
        recordCreated: selectedAccountComment.recordCreated,
        recordUpdated: commentDate,
      };
      await updateAccountComment(
        accountId,
        selectedAccountComment.accountCommentId,
        buildData,
        user.organizationId,
        user.token,
      )
        .then(() => {
          setSendingComment(false);
          setOpen(false);
          setNewComment(false);
          getAccountComments();
        })
        .catch((err) => {
          setSendingComment(false);
          if (isMounted) {
            addGlobalMessage(errorMessages(err) || 'Could not update comments');
          }
        });
    }
  }

  const getCommentedUserName = (superiorUserId) => {
    const name = userInfo.find((data) => {
      return data.userId === superiorUserId;
    });
    const fullname = name ? `${name.firstName} ${name.lastName}` : '';
    return fullname;
  };

  useEffect(() => {
    isMounted = true;
    if (user.roles.includes(UserRole.multi)) {
      getAccountComments();
      setRepInfo();
    }
    return () => {
      isMounted = false;
    };
  }, [user.organizationId, user.token]);

  const handleIndividualNotificationActionClick = (
    event: MouseEvent<HTMLElement>,
    comments: AccountComment,
  ) => {
    event.stopPropagation();
    setAnchorIndividualEl(event.currentTarget);
    setSelectedAccountComment(comments);
  };

  const selectAccountOwnerAccount = () => {
    setSelectedAccount(account);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorIndividualEl(null);
  };

  const handleIndividualClose = () => {
    setAnchorIndividualEl(null);
  };

  return (
    <>
      <Box onClick={selectAccountOwnerAccount}>
        <Paper
          elevation={isSelected ? 5 : 3}
          sx={{
            ...classes.accountSummaryCard,
            ...(isSelected && classes.accountSummaryCardActive),
          }}
        >
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="h6" gutterBottom>
                {getAppConstant(ConstantsMappingKey.accountType, accountType)}
              </Typography>
            </Grid>{' '}
            {user.roles.includes(UserRole.multi) && (
              <Grid item xs={2}>
                <IconButton
                  sx={{
                    padding: 0,
                  }}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <Badge color="secondary" variant="dot" invisible={!notify}>
                    <CommentIcon color="primary" />
                  </Badge>
                </IconButton>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="body2">
                {headerAccountName}: {accountNumber}
              </Typography>
            </Grid>
            {organization.planNumberEnabled && (
              <Grid item xs={12}>
                <Typography>Plan Number: {planNumber}</Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="body2">
                Status: {accountStatusDescriptions[accountStatus]}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      {/* notify user when a comment is available on intial load up! */}
      <SiraModal
        title={`${getAppConstant(
          ConstantsMappingKey.accountType,
          accountType,
        )} comments`}
        open={open}
        handleClose={handleClose}
      >
        <>
          <Box sx={styles.commentsBox}>
            {accountComment.map((comments) => {
              return (
                <Card
                  sx={{
                    margin: 1,
                  }}
                  key={comments.accountCommentId}
                >
                  <CardHeader
                    title={
                      <Typography variant="body1">
                        {getCommentedUserName(comments.superiorUserId)}
                      </Typography>
                    }
                    action={
                      <Grid container wrap="nowrap">
                        <Grid item>
                          <Typography
                            pt={1}
                            variant="body2"
                            style={{
                              fontWeight: 'normal',
                            }}
                          >
                            {comments.recordCreated &&
                              formatDistance(
                                parseISO(comments.recordCreated),
                                new Date(),
                                {
                                  addSuffix: true,
                                },
                              )}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                  {/* add ability to edit individual comments and delete */}

                  <CardContent>
                    <Grid
                      container
                      spacing={1}
                      wrap="nowrap"
                      alignItems="center"
                    >
                      <Grid item xs={12}>
                        <Typography variant="body2">
                          {comments.comment}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          aria-label={`menu-item-${comments.accountCommentId}`}
                          id="individual-menu-button"
                          aria-controls={
                            openIndividualMenu ? 'individual-menu' : undefined
                          }
                          aria-expanded={
                            openIndividualMenu ? 'true' : undefined
                          }
                          aria-haspopup="true"
                          onClick={(event) => {
                            handleIndividualNotificationActionClick(
                              event,
                              comments,
                            );
                          }}
                          onMouseDown={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <MoreHoriz />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              );
            })}
          </Box>
          {newComment && (
            <Card
              sx={{
                margin: 1,
              }}
            >
              <CardContent>
                <Grid
                  container
                  sx={{ marginTop: 1 }}
                  wrap="nowrap"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      maxRows={4}
                      fullWidth
                      label={
                        selectedAccountComment.accountCommentId
                          ? `Update Comment `
                          : 'New Comment'
                      }
                      defaultValue={
                        selectedAccountComment.comment
                          ? selectedAccountComment.comment
                          : ''
                      }
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                    />
                    <Box sx={buttonContentStyle}>
                      <Box pr={2}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            const id = selectedAccountComment.accountCommentId
                              ? selectedAccountComment.accountCommentId
                              : 0;
                            addorUpdateAccountComments(id);
                            setAnchorIndividualEl(null);
                            setSendingComment(true);
                          }}
                          endIcon={<SendIcon />}
                          disabled={sendingComment}
                        >
                          Save
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setAnchorIndividualEl(null);
                            setNewComment(false);
                          }}
                          disabled={sendingComment}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                  {sendingComment ? (
                    <Grid item xs={12} pt={2}>
                      <LinearProgress color="secondary" />
                    </Grid>
                  ) : null}
                </Grid>
              </CardContent>
            </Card>
          )}
          {!newComment && (
            <Box sx={buttonContentStyle}>
              <Box pr={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setNewComment(true);
                    setSelectedAccountComment({} as AccountComment);
                  }}
                >
                  Add Comment
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setOpen(false);
                    setNewComment(false);
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          )}
        </>

        {/* Add an entry window for new comments within a modal */}
        {/* Add scrolling to comment modal */}
        {/* Add a new comment and cancel button */}
      </SiraModal>
      <Menu
        id="basic-menu"
        anchorEl={anchorIndividualEl}
        open={openIndividualMenu}
        onClose={handleIndividualClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          key={1}
          onClick={() => {
            setNewComment(true);
            setAnchorIndividualEl(null);
          }}
        >
          Update{' '}
        </MenuItem>
        <MenuItem
          key={2}
          onClick={() => {
            deleteAccountComments(selectedAccountComment.accountCommentId);
            setAnchorIndividualEl(null);
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
}

export default AccountOwnerAccountSummaryCard;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { getYear, subYears } from 'date-fns';
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import CheckIcon from '@mui/icons-material/CheckBox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import NumericFormat from 'react-number-format';
import { globalPaginationOptions } from '../../../app.constants';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../../auth/useGlobalContext';
import { useUser } from '../../../auth/useUser';
import SiraNoRowsOverlay from '../../SiraNoRowsOverlay';
import DataGridExportToolbar from '../../DataGridExportToolbar';

import Layout from '../../Layout';
import FormPaper from '../../FormPaper';
import { TaxForm, Totals1099Page } from '../../../api/TaxFormApi.d';
import { getTax1099totalspage, getTaxForm } from '../../../api/TaxFormApi';
import ObscuredTaxId, { TaxIdFormat } from '../../ObscuredTaxId';
import { AccountDetailAccordionType } from '../../accountOwnerProfile/AccountOwnerAccountDetails';
import { ConstantsMappingKey } from '../../../api/SharedTextApi.d';
import {
  currencyValueFormatter,
  dateValueFormatter,
} from '../../../utils/DataGrid.utils';
import PopoverChart from './PopoverChart';
import { AccountMemberValue } from '../../../api/OrganizationApi.d';
import { errorMessages } from '../../../utils/errorhandling.utils';

interface SummaryReturnData {
  taxFormSummaries: Array<Totals1099Page>;
}

function Total1099Page() {
  const { user } = useUser();
  const navigate = useNavigate();

  const { getAppConstant, addGlobalMessage, organization } = useGlobalContext();
  const { accountNumberValue } = organization;
  const {  setGlobalPageSize } = usePaginationContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [summary, setSummary] = useState({} as SummaryReturnData);

  // usestate for 1099 data grouping
  const [rows1099SA, setrows1099SA] = useState([] as Array<TaxForm>);
  const [rows1099R, setrows1099R] = useState([] as Array<TaxForm>);
  const [rows1099Q, setrows1099Q] = useState([] as Array<TaxForm>);

  const gotoRecordAccount = (row: TaxForm): void => {
    navigate(
      `/accountOwner/${row.accountOwnerId}/account/${row.accountId}/?expanded=${AccountDetailAccordionType.taxForm}`
    );
  };

  const headerAccountName = accountNumberValue === AccountMemberValue.accountNumber ? 'Account Number': 'Member Number';

  const columns = [
    {
      field: 'legalName',
      headerName: 'Owner',
      pinnable: true,
      width: 185,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            onClick={() => {
              gotoRecordAccount(row);
            }}
            sx={{
              justifyContent: 'flex-start' as any,
              width: '100%',
              textDecoration: 'none' as any,
              textTransform: 'none' as any,
            }}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'taxId',
      headerName: 'SSN/ID',
      width: 125,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;

        return (
          <ObscuredTaxId taxId={value.toString()} format={TaxIdFormat.ssn} />
        );
      },
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        return getAppConstant(ConstantsMappingKey.accountType, value);
      },
    },
    {
      field: 'uniqueId',
      headerName: headerAccountName,
      width: 156,
    },
    {
      field: 'distributionReason',
      headerName: 'Distribution Reason',
      width: 156,
      valueFormatter: (params: GridCellParams) => {
        const { value = '' } = params;
        return getAppConstant(ConstantsMappingKey.distributionReason, value);
      },
    },
    {
      hideable: false,
      field: 'distributionAmount',
      headerName: 'Distribution Amount',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      hideable: false,
      field: 'federalTaxWithheld',
      headerName: 'Federal Tax Withheld',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      hideable: false,
      field: 'stateIncomeTaxWithheld',
      headerName: 'State Tax Withheld',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      hideable: false,
      field: 'withholdingState',
      headerName: 'Withholding State',
      width: 156,
    },
    {
      field: 'taxableAmount',
      headerName: 'Taxable Amount',
      width: 156,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'taxableAmountNotDetermined',
      headerName: 'Taxable Amount Not Determined',
      width: 156,
      valueFormatter: (params: GridCellParams) => {
        const { value = false } = params;
        if (value) {
          return 'X';
        }
        return ' ';
      },
      renderCell: (params: GridCellParams) => {
        const { value = false } = params;
        return (
          <>
            <Grid container justifyContent="center">
              {value ? <CheckIcon /> : ' - '}
            </Grid>
          </>
        );
      },
    },
    {
      field: 'totalDistributionIndicator',
      headerName: 'Total Distribution Indicator',
      width: 156,
      valueFormatter: (params: GridCellParams) => {
        const { value = false } = params;
        if (value) {
          return 'X';
        }
        return ' ';
      },
      renderCell: (params: GridCellParams) => {
        const { value = false } = params;
        return (
          <>
            <Grid container justifyContent="center">
              {value ? <CheckIcon /> : ' - '}
            </Grid>
          </>
        );
      },
    },
    {
      field: 'trusteeToTrusteeTransfer',
      headerName: 'Trustee to Trustee Transfer',
      width: 156,
      valueFormatter: (params: GridCellParams) => {
        const { value = false } = params;
        if (value) {
          return 'X';
        }
        return ' ';
      },
      renderCell: (params: GridCellParams) => {
        const { value = false } = params;
        return (
          <>
            <Grid container justifyContent="center">
              {value ? <CheckIcon /> : ' - '}
            </Grid>
          </>
        );
      },
    },
    {
      field: 'designatedBeneficiary',
      headerName: 'Recipient is not the Designated Beneficiary',
      width: 156,
      valueFormatter: (params: GridCellParams) => {
        const { value = false } = params;
        if (value) {
          return 'X';
        }
        return ' ';
      },
      renderCell: (params: GridCellParams) => {
        const { value = false } = params;
        return (
          <>
            <Grid container justifyContent="center">
              {value ? <CheckIcon /> : ' - '}
            </Grid>
          </>
        );
      },
    },
    {
      field: 'corrected',
      headerName: 'Corrected Indicator',
      width: 124,
      valueFormatter: (params: GridCellParams) => {
        const { value = false } = params;
        if (value) {
          return 'X';
        }
        return ' ';
      },
      renderCell: (params: GridCellParams) => {
        const { value = false } = params;
        return (
          <>
            <Grid container justifyContent="center">
              {value ? <CheckIcon /> : ' - '}
            </Grid>
          </>
        );
      },
    },
    {
      field: 'dateSentToOwner',
      headerName: 'Date Sent to Owner',
      width: 155,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'dateFiledWithIrs',
      headerName: 'Date Filed with IRS',
      width: 155,
      valueFormatter: dateValueFormatter,
    }
  ] as GridColDef[];

  const filterDatabyType = (data: Array<TaxForm>) => {
    const filteredTypeR = data.filter((item) => item.formType === 'R');
    const filteredTypeSA = data.filter((item) => item.formType === 'SA');
    const filteredTypeQ = data.filter((item) => item.formType === 'Q');

    // setting the data for the table
    setrows1099R(filteredTypeR);
    setrows1099SA(filteredTypeSA);
    setrows1099Q(filteredTypeQ);

    setIsLoading(false);
  };

  const fetchSndSetTaxForm = async (values) => {
    setIsLoading(true);
    await getTax1099totalspage(
      String(getYear(values.taxYear)),
      user.organizationId,
      user.token
    )
      .then(({ data }) => {
        setSummary(data);
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) || 'Error fetching multi-org beneficiary claims'
        );
      });
  };

  const fetchndSetTaxForm = async (values) => {
    setIsLoading(true);
    await getTaxForm(
      String(getYear(values.taxYear)),
      user.organizationId,
      user.token,
      user
    )
      .then(({ data }) => {
        filterDatabyType(data);
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) || 'Error fetching multi-org beneficiary claims'
        );
      });
  };

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography color="secondary" variant="h1">
            1099 Totals Page
          </Typography>

          <Formik
            // Datepicker is only going to accept date in taxYear but we translate it to an integer on submit
            initialValues={{ taxYear: subYears(new Date(), 1), hasFmv: true }}
            onSubmit={(values) => {
              fetchSndSetTaxForm(values);
              fetchndSetTaxForm(values);
            }}
            validationSchema={yup.object({
              taxYear: yup
                .date()
                .min(new Date('1900-01-01'), 'Choose a later year')
                .max(new Date(), 'Cannot be later than current year')
                .nullable()
                .required()
                .label('Tax Year'),
            })}
          >
            {({ values, setFieldValue, errors }) => {
              return (
                <Form>
                  <Grid container spacing={2} alignItems="center" pt={1} pb={2}>
                    <Grid item xs={2} sm={4}>
                      {/* Year-only date viewer */}
                      <DatePicker
                        views={['year']}
                        label="Tax Year"
                        value={values.taxYear}
                        openTo='year'
                        onChange={(newValue) => {
                          setFieldValue('taxYear', newValue);
                        }}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: Boolean(errors.taxYear),
                            helperText: errors.taxYear ? String(errors.taxYear) : '',
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs={1} sm={3}>
                      <Button
                        disabled={isLoading}
                        variant="contained"
                        color="secondary"
                        startIcon={<SearchOutlinedIcon />}
                        type="submit"
                      >
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>

          {/* 1099R tax form summary */}

          {summary.taxFormSummaries && (
            <Box
              sx={{
                filter: isLoading ? 'blur(5px)' : '',
                transition: 'filter 0.33s ease-in-out',
              }}
            >
              {summary.taxFormSummaries.map((item) => {
                let stateTaxesWithheld = [];
                let rowData = [] as Array<TaxForm>;
                if (item.taxFormType === 'R') {
                  stateTaxesWithheld = item.stateTaxesWithheld.map((obj) => ({
                    ...obj,
                    distributionTotal: item.distributionTotal,
                  }));
                  rowData = rows1099R;
                } else if (item.taxFormType === 'SA') {
                  rowData = rows1099SA;
                } else {
                  rowData = rows1099Q;
                }

                return (
                  <Accordion
                    elevation={2}
                    disabled={isLoading}
                    sx={{ marginBottom: 2 }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid container>
                        <Grid item xs={2}>
                          <Typography variant="body2" fontWeight="bold">
                            1099-{item.taxFormType}
                          </Typography>
                        </Grid>{' '}
                        <Grid item xs={3}>
                          {item.taxFormType === 'R' && (
                            <Typography variant="body2" fontWeight="bold">
                              State Tax Withheld
                            </Typography>
                          )}
                        </Grid>{' '}
                        <Grid item xs={3}>
                          {item.taxFormType === 'R' && (
                            <Typography variant="body2" fontWeight="bold">
                              Federal Tax Withheld
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body2" fontWeight="bold">
                            Distribution Total
                          </Typography>
                        </Grid>{' '}
                        <Grid item xs={1} sx={{ paddingTop: 2 }}>
                          <Typography variant="body2" fontWeight="bold">
                            View All
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2">
                            {item.payeeCount || 0} Payee
                          </Typography>
                        </Grid>{' '}
                        <Grid item xs={3}>
                          {item.taxFormType === 'R' && (
                            <PopoverChart
                              summary={stateTaxesWithheld}
                              stateTaxes={item.stateTaxWithheld}
                            />
                          )}
                        </Grid>{' '}
                        <Grid item xs={3}>
                          {item.taxFormType === 'R' && (
                            <Typography variant="body2">
                              <NumericFormat
                                value={item.federalTaxWithheld || 0}
                                prefix="$"
                                displayType="text"
                                thousandSeparator
                                isNumericString
                                fixedDecimalScale
                                decimalScale={2}
                              />
                            </Typography>
                          )}
                        </Grid>{' '}
                        <Grid item xs={4}>
                          <Typography variant="body2">
                            <NumericFormat
                              value={item.distributionTotal || 0}
                              prefix="$"
                              displayType="text"
                              thousandSeparator
                              isNumericString
                              fixedDecimalScale
                              decimalScale={2}
                            />
                          </Typography>
                        </Grid>{' '}
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <DataGrid
                        components={{
                          NoRowsOverlay: SiraNoRowsOverlay,
                          Toolbar: DataGridExportToolbar,
                        }}
                        disableColumnMenu
                        initialState={{
                          pagination: { paginationModel: { pageSize: 10 } },
                          columns: {
                            columnVisibilityModel: {
                              accountType: false,
                              uniqueId: false,
                              distributionReason: false,
                              taxableAmount: false,
                              taxableAmountNotDetermined: false,
                              totalDistributionIndicator: false,
                              trusteeToTrusteeTransfer: false,
                              corrected: false,
                              dateSentToOwner: false,
                              dateFiledWithIrs: false,

                            },
                          }
                        }}
                        pageSizeOptions={globalPaginationOptions}
                        onPaginationModelChange={setGlobalPageSize}
                        autoHeight
                        columns={columns}
                        rows={rowData.map((row, id) => ({
                          ...row,
                          id,
                        }))}
                      />
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          )}
        </>
      </FormPaper>
    </Layout>
  );
}

export default Total1099Page;

import React, { useState } from 'react';
import {
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Paper,
  Typography,
  Grid,
  LinearProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useGlobalContext } from '../auth/useGlobalContext';
import Layout from '../components/Layout';
import PaperlessPage from '../components/PaperlessPage';
import ObscuredTaxId, { TaxIdFormat } from '../components/ObscuredTaxId';
import OrganizationTaxReportingSettings from '../components/organizationProfile/OrganizationTaxReportSettings';

export interface OrganizationPageParams {
  orgId: string;
}

function TaxReportingSettings() {
  const theme = useTheme();
  const [isLoading] = useState(false as boolean);
  const { organization } = useGlobalContext();
  const {
    branches = [],
    federalTaxId = '',
    financialOrganizationId = '',
    legalName = '',
    name = '',
    solution = [],
  } = organization || {};

  const primaryBranchInfo = branches.find(
    ({ primaryBranch = false }) => primaryBranch
  );
  const classes = {
    profileCard: {
      color: theme.palette.grey[300],
      marginTop: theme.spacing(2),
      padding: theme.spacing(4),
      background: theme.palette.primary.main,
    },
    moreInformation: {
      color: theme.palette.grey[300],
      paddingTop: theme.spacing(1),
      background: theme.palette.primary.main,
    },
  };

  return (
    <Layout>
      <PaperlessPage>
        <>
          <Typography variant="overline" gutterBottom>
            Tax Reporting Settings
          </Typography>

          <Box mt={4}>
                        {/* Tax Reporting Setting */}
              <Accordion 
              defaultExpanded={true}
              slotProps={{
                transition:{
                  mountOnEnter: true,
                  unmountOnExit: true
                }
              }}>
                <AccordionSummary
                  disabled={isLoading}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography variant="h5" color="secondary">
                  Reporting Method Preferences 
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <OrganizationTaxReportingSettings />
                </AccordionDetails>
              </Accordion>
          </Box>
        </>
      </PaperlessPage>
    </Layout>
  );
}

export default TaxReportingSettings;
